import { path } from "ramda";
import { useTable, Column } from "react-table";
import { SkeletonText, Box, Table, Thead, Tbody, Th, Td, Tr, Flex, Tfoot } from "@chakra-ui/react";

import "./Styles/datatable.css";
import './Styles/styleguide.css';
import './Styles/controls.css';
import './DataTable.scss';

function getNavigationButtonClass(canPreviousPage) {
    return canPreviousPage ? 'bt-pagination-navigation' : 'bt-pagination-navigation disabled';
}

export type DataTableSkeletonProps<T extends object> = {
    children?: {
        rowFooter?: any;
    };
    columns: Column<T>[];
}

export function DataTableSkeleton<T extends object>({
    columns,
    children: { rowFooter } = {},
}: DataTableSkeletonProps<T>) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
    } = useTable(
        {
            columns,
            data: [] as T[],
        },
    );

    return (
        <>
            <Box className="datatable-wrapper">
                <Table className="datatable" {...getTableProps()}>
                    <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps()}>
                                    <Flex className="sorting-wrapper">
                                        <Box>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <img src="/img/icon-arrow-down.png" className="order-icon" alt="Order" />
                                                    : <img src="/img/icon-arrow-up.png" className="order-icon" alt="Order" />
                                                : <img src="/img/icon-arrow-up.png" className="order-icon" alt="Order" /> }
                                        </Box>
                                        <Box>{column.render("Header")}</Box>
                                    </Flex>
                                </Th>
                            ))}
                            {
                                rowFooter && (<Th>ACTIONS</Th>)
                            }
                        </Tr>
                    ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                    <Tr>
                        <Td colSpan={path([0, 'headers', 'length'], headerGroups) + 1}>
                            <SkeletonText noOfLines={6} spacing={4} skeletonHeight={2} />
                        </Td>
                    </Tr>         
                    </Tbody>
                    <Tfoot>
                        <Tr>
                          <Td colSpan={columns.length}>
                            <Flex className="table-bottom-wrapper">
                              <Box className="filter-length-wrapper">
                              </Box>
                              <Flex className="pagination-wrapper">
                                <Flex className="go-to-page">
                                </Flex>
                                <Box
                                  className={getNavigationButtonClass(canPreviousPage)}
                                  onClick={() => previousPage()}>Prev</Box>
                                <Box className="navbar">
                                    <Box className="navbar-link-bt-pagination-current">
                                        {1} of {(pageOptions || []).length}
                                    </Box>
                                </Box>
                                <Box className={getNavigationButtonClass(canNextPage)}
                                      onClick={() => nextPage()}>
                                    Next
                                </Box>
                              </Flex>
                            </Flex>
                          </Td>
                        </Tr>
                    </Tfoot>
                </Table>
            </Box>
        </>
    );
};
