import { Box, Center, Flex, Image } from '@chakra-ui/react';
import UserListDataTable from '../UserListDataTable/UserListDataTable';
import logo from '../../../logo-teal.png';
import UserForm2 from 'modules/user2/UserForm2';
import { useEffect, useState } from 'react';
import { useMojoEffect } from 'api/useMojoEffect';
import { connect } from 'react-redux';

 function UserList(props) {
  const [user, setUser] = useState();
  //const [key, setKey] = useState(1);
  const [showForm, setShow] = useState(false);
  const [fetchError, setError] = useState(null as any);
  const [isLoading, setLoading] = useState(false);
  const [users, setData] = useState<any[]>([]);
  //const {data: users, refetch, isLoading, error} = useMojoFetch('/api/v1/Users', 'GET');
  const { runWithId: getUsers } = useMojoEffect(`/api/v1/users?`, 'GET');
  
    useEffect(() => {
      const fetch = async () => {
        setLoading(true);
        const [data, error] = await getUsers(`groupId=${props.groupId}`);
        if (error === null) {
          setData(data);
        } else {
          setError(error);
        }
        setLoading(false);
      };
      fetch();
    }, [props.groupId]);
  
  const editUser = (id) => {    
    const user = users.find((u) => u.UserId === id);
    setUser(user);
    setShow(true);
  }

  const reset = async () => {
    setShow(false);
    setLoading(true);
    const [data, error] = await getUsers(`groupId=${props.groupId}`);
    if (error === null) {
      setData(data);
    } else {
      setError(error);
    }
    setLoading(false);;
  }

  const clear = () => {
    setShow(false);
  }
  
  if (isLoading) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }
  if (fetchError !== null) {
    return <Center>{fetchError.message}</Center>;
  }

  return (
    <>
    {showForm && (<UserForm2      
        user={user}        
        reset = { reset }
        clear = { clear }
      />)}
    <div className='client-list'>
      <UserListDataTable data={users} editUser = { editUser }/>
    </div>
    </>
  );
}

const select = (state) => {
  return { groupId: state.app.selectedGroupId };
};
export default connect(select)(UserList);
