import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

import { Input } from "./input";
import { Select } from "./select";
import { Button } from "./button";
import { Menu } from "./menu";
import { Form } from "./form";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

export const theme = extendTheme({
  config,
  components: {
    Input,
    Select,
    Button,
    Menu,
    Form,
  },
  fonts: {
    heading: `'Gotham Bold', sans-serif`,
    body: `'Gotham Book', sans-serif`,
    gothamMedium: `'Gotham Medium', sans-serif`,
    poppins: `'Poppins', sans-serif`,
    poppinsSemiBold: `'Poppins SemiBold', sans-serif`,
  },
  fontSizes: {
    xs8: "0.5rem", // 8px
    xs9: "0.5625rem", // 9px
    sm11: "0.6875rem", // 11px
    sm12: "0.75rem", // 12px
    sm13: "0.8125rem", // 13px
    sm14: "0.875rem", // 14px
    sm15: "0.9375rem", // 15px
    baseFontSize: "1rem", // 16px
    md18: "1.125rem", // 18px
    lg20: "1.25rem", // 20px
    lg22: "1.375rem", // 22px
    lg24: "1.5rem", // 24px
    lg25: "1.5625rem", // 25px
    lg30: "1.875rem", // 30px
    xl80: "5rem", // 80px
  },
  colors: {
    mojo: {
      50: "#EAF0F4",
      100: "#DCE9EB",
      200: "#73CEE2",
      300: "#3aadad",
      400: "#04B4DB",
      500: "#1D97B2",
    },
    green: {
      500: "#0EE87B",
      600: "#38CB89",
    },
    yellow: {
      500: "#F09450",
    },
    orange: {
      500: "#F5900C",
    },
    red: {
      500: "#F20850",
    },
    white: {
      100: "#FFFFFF",
      200: "#F8F8F8",
    },
    gray: {
      100: "#E2E2E2",
      200: "#E6E2DF",
      500: "#D5D5D5",
      600: "#676767",
      800: "#5C5C5C",
    },
    purple: {
      500: "#43425D",
      600: "#393335",
    },
    brown: {
      500: "#77686C",
    },
    black: {
      100: "#343A40",
      200: "#2B3035",
      300: "#23272B",
      400: "#1A1D20",
      500: "#393939",
      600: "#231F20",
      700: "#000000",
    },
  },
  styles: {
    global: (props: any) => ({
      "html, body": {
        bg: props.colorMode === "dark" ? "#121212" : "white.100",
        color: props.colorMode === "dark" ? "white.100" : "black",
      },
    }),
  },
});
