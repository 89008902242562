import { Menu, MenuButton, MenuList, MenuOptionGroup, MenuItemOption, Button, Flex } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import './ColumnFilters.scss';

interface ColumnFiltersProps {
    options: { value: string, label: string }[],
    value: string[],
    onChange: (selectedValues: string[]) => void
}

function ColumnFilters({ options, value, onChange }: ColumnFiltersProps) {
    
    const handleChange = (selectedValues: string[]) => {
        onChange(selectedValues);
    };

    return (
        <Flex className='column-filter--wrapper'>
            <Menu closeOnSelect={false}>
                {({ isOpen }) => (
                    <>
                    <MenuButton className="filter-button" as={Button} rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />} >
                        Show/Hide Columns
                    </MenuButton>
                    <MenuList className="filter-list">                    
                        <MenuOptionGroup className="filter-group" 
                            title='Options' 
                            type='checkbox' 
                            value={value} 
                            onChange={handleChange as (value: string | string[]) => void}
                        >
                            {options.map((option) => (
                                <MenuItemOption className="filter-item"
                                    key={option.value}
                                    value={option.value}
                                    isChecked={value.includes(option.value)}
                                >
                                    {option.label}
                                </MenuItemOption>
                            ))}                        
                        </MenuOptionGroup>
                    </MenuList>
                    </>
                )}
            </Menu>
        </Flex>
    );
}

export default ColumnFilters;