import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormControl,
  IconButton,
  useToast,
  Select,
  FormLabel,
  VStack,
  Spacer,
} from '@chakra-ui/react';
import { useMojoEffect } from 'api/useMojoEffect';
import { useForm } from 'react-hook-form';
import './AddChannelsForm.scss';

const defaultValues = {
  channelId: null,
  mediumId: null,
  channelTypeId: null,
  objectiveId: null,
  audienceId: null,
};
export default function AddChannelForm({vendorId, channels, media, channelTypes, objectives, audiences, createChannel}) {
  //const bg = useColorModeValue('white.100', 'black.100');
  //const color = useColorModeValue('black.700', 'white.100');


  const { handleSubmit, reset, register } = useForm({});

  const toast = useToast();
  
  const submit = async (data) => {
    reset(defaultValues);
    await createChannel(data);
    
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className='add-edit-services-form form-upper-wrapper'>
        <div className='form-upper form'>
          <div className='form-upper-title'>ADD CHANNEL</div>
        </div>
        <div className='form services-form'>
          <VStack align='left'>
            <Flex gap={'30px'}>
              <FormControl id='channels' w='20em' isRequired variant='floating'>
                <Select {...register('channelId')} placeholder='Select...'>
                  {channels.map((c) => (
                    <option value={c.id} key={c.id}>
                      {c.name}
                    </option>
                  ))}
                </Select>
                <FormLabel style={{ paddingRight: '8px' }} bg='white'>
                  Channel
                </FormLabel>
              </FormControl>
              <FormControl
                w='20em'
                isRequired
                id='channelTypes'
                variant='floating'
              >
                <Select {...register('channelTypeId')} placeholder='Select...'>
                  {channelTypes.map((t) => (
                    <option value={t.id} key={t.id}>
                      {t.name}
                    </option>
                  ))}
                </Select>
                <FormLabel style={{ paddingRight: '8px' }} bg='white'>
                  Type
                </FormLabel>
              </FormControl>
              <FormControl w='20em' isRequired id='media' variant='floating'>
                <Select {...register('mediumId')} placeholder='Select...'>
                  {media.map((m) => (
                    <option value={m.id} key={m.id}>
                      {m.name}
                    </option>
                  ))}
                </Select>
                <FormLabel style={{ paddingRight: '8px' }} bg='white'>
                  Medium
                </FormLabel>
              </FormControl>
            </Flex>
            <Flex gap={'30px'}>
              <FormControl
                w='20em'
                isRequired
                id='objectives'
                variant='floating'
              >
                <Select {...register('objectiveId')} placeholder='Select...'>
                  {objectives.map((o) => (
                    <option value={o.id} key={o.id}>
                      {o.name}
                    </option>
                  ))}
                </Select>
                <FormLabel style={{ paddingRight: '8px' }} bg='white'>
                  Objective
                </FormLabel>
              </FormControl>
              <FormControl
                w='20em'
                isRequired
                id='audiences'
                variant='floating'
              >
                <Select {...register('audienceId')} placeholder='Select...'>
                  {audiences.map((a) => (
                    <option value={a.id} key={a.id}>
                      {a.name}
                    </option>
                  ))}
                </Select>
                <FormLabel style={{ paddingRight: '8px' }} bg='white'>
                  Audience
                </FormLabel>
              </FormControl>
              <Spacer />
              <IconButton
                variant='mojoPrimary'
                icon={<AddIcon />}
                aria-label='submit'
                type='submit'
              />
              <IconButton
                variant='mojoPrimary'
                icon={<CloseIcon />}
                aria-label='reset'
                onClick={() => reset(defaultValues)}
              />
            </Flex>
          </VStack>
        </div>
      </div>
    </form>
  );
}
