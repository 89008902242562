import { Outlet, useLocation } from 'react-router-dom';
import ClientAddEditForm from '../AddEditClientForm';
import { EditorWithDetails } from 'layout/EditorWithDetails';
import { TabItemEnum } from "../ClientTabs/TabItemEnum";
import Tabs from "../ClientTabs/Tabs";
import { Button, Heading, Box } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useNavigate } from 'hooks/useNavigate';

import './ClientDetail.scss';
import { useState } from 'react';

function toTabItem(value: string): TabItemEnum | null {
  const enumValues = Object.values(TabItemEnum);
  if (enumValues.includes(value as TabItemEnum)) {
    return value as TabItemEnum;
  }
  return null;
}

export function ClientDetail() {
  const [hideForm, setHideForm] = useState(false)
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const lastPart = pathname.split('/').at(-1);
  const currentTab = (lastPart && toTabItem(lastPart)) || TabItemEnum.Contacts;
  return (
    <Box>
      <EditorWithDetails style={{ paddingBottom: 0 }} >
        <div className="client-form-header">
          <Button
            leftIcon={<ChevronLeftIcon height={6} width="auto" color="cyan" />}
            variant="mojoDefault"
            onClick={() => navigate('/clients')}
          >CLIENT LIST</Button>
          {!hideForm && <Heading size={"md"} style={{ marginRight: 'auto' }}>UPDATE CLIENT</Heading>}
          <div className='collapse-icon' role='button' onClick={() => setHideForm(!hideForm)}>
            <img src={`/pages/clients/list/${hideForm ? "open" : "close"}-arrows.svg`} />
          </div>
          {hideForm && <Tabs currentTab={currentTab} />}
        </div>
        {hideForm ? <></> : <Tabs currentTab={currentTab} />}
      </EditorWithDetails>

      <EditorWithDetails hideLeftPanel={hideForm}>
        <ClientAddEditForm />
        <Outlet />
      </EditorWithDetails>
    </Box>
  );
}