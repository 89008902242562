import { Flex, Text, Icon, Tr, Td } from "@chakra-ui/react";
import { Column } from "react-table";
import IconHome from "snippets/icon-archive";

export type EmptyDataTableProps<T extends object> = {
  columns: Column<T>[];
}

export function EmptyDataTable<T extends object>({ columns }: EmptyDataTableProps<T>) {
  return (
    <Tr>
      <Td colSpan={columns.length + 1}>
        <Flex direction="column" align="center" justify="center" color="gray.200">
          <Flex direction="column" align="center" justify="center" gap={5} p={2} maxHeight="128px">
            <Icon as={IconHome}/>
            <Text fontSize="xl" color="gray.500">
              Nothing found
            </Text>
          </Flex>
        </Flex>
      </Td>
    </Tr>
  );
}