import { useMojoFetch } from 'api/useMojoFetch';
import Budgets from './Budgets/Budgets';
import { connect } from 'react-redux';
import { Box, Center, Flex, Image } from '@chakra-ui/react';
import logo from '../../logo-teal.png';
import { useMojoEffect } from 'api/useMojoEffect';
import { useEffect, useState } from 'react';

function BudgetsIntro(props) {
  const { data: vendors, isLoading: vLoading } = useMojoFetch(
    '/api/v1/Vendors/all',
    'get'
  );
  const { data: channels, isLoading: chLoading } = useMojoFetch(
    '/api/v1/channels',
    'get'
  );
  const { data: channelTypes, isLoading: ctLoading } = useMojoFetch(
    '/api/v1/channeltypes',
    'get'
  );
  const { data: media, isLoading: mLoading } = useMojoFetch(
    '/api/v1/media',
    'get'
  );
  const { data: objectives, isLoading: oLoading } = useMojoFetch(
    '/api/v1/objectives',
    'get'
  );
  const { data: audiences, isLoading: aLoading } = useMojoFetch(
    '/api/v1/audiences',
    'get'
  );
  const { data: departments, isLoading: dLoading } = useMojoFetch(
    '/api/v1/departments',
    'get'
  );

  const { runWithId: getClients } = useMojoEffect(
    `/api/v1/clients?groupId=`,
    'get'
  );
  const [clients, setClients] = useState([]);
  const [cnLoading, setCnLoading] = useState(false);
  const [isUploading, setUploading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setCnLoading(true);
      const [clients, client_error] = await getClients(props.groupId);
      if (client_error === null) {
        setClients(clients);
        const tenantIds = clients
          .filter((c) => c.TenantId)
          .map((x) => x.TenantId);
        if (!tenantIds.includes(localStorage.getItem('location'))) {
          setUploading(false);
        }
      }
      setCnLoading(false);
    };
    fetch();
  }, [props.groupId]);

  if (
    dLoading ||
    vLoading ||
    chLoading ||
    ctLoading ||
    mLoading ||
    oLoading ||
    aLoading ||
    cnLoading
  ) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }
  return (
    <Budgets
      clients={clients}
      departments={departments.map((dept) => {
        return { id: dept.DepartmentId, name: dept.name };
      })}
      vendors={vendors}
      channels={channels}
      channelTypes={channelTypes}
      media={media}
      objectives={objectives}
      audiences={audiences}
      loc={localStorage.getItem('location') || ''}
      loc_name={localStorage.getItem('location_name') || ''}
      isUploading={isUploading}
      setUploading={setUploading}
    />
  );
}

const select = (state) => {
  return { groupId: state.app.selectedGroupId };
};
export default connect(select)(BudgetsIntro);
