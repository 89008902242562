import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode } from "react";
import LoginRedirect from "pages/LoginRedirect";

interface ProtectedRouteProps {
    children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
    const { isAuthenticated } = useAuth0();

    if (!isAuthenticated) {
        return <LoginRedirect />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;