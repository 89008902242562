import {
  Box,
  Center,
  Flex,
  VStack,
  Image,
  Text,
  useToast,
  Progress,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMojoEffect } from 'api/useMojoEffect';
import './Uploader.scss';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const baseStyle = {
  flex: 1,
  display: 'flex',
  height: '135px',
  align: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 5,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: '100%',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

type BFile = {
  file: any;
  entityId: string;
};
export function SimpleUploader({ entityId, maxFiles, file_type, refetch }) {
  //const { data: vendors, error: vendorFetchError } = useMojoFetch('api/v1/vendors/all', 'get');
  const { runWithId: getPresignedUrl } = useMojoEffect(
    `/api/v1/app/signedUrl/presentation/`,
    'get'
  );
  const [isDropping, setIsDropping] = useState(false);
  const toast = useToast();
  const [files, setFiles] = useState<BFile[]>([]);
  const [progress, setProgress] = useState(0);

  // Pad a number to 2 digits
  const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');

  async function uploadEverything() {
    setProgress(1); //just to trigger the UPLOADING label
    const totalFileSize = files.reduce(
      (partialSum, a) => partialSum + a.file.size,
      0
    );
    let uploadedFileSize = 0;
    for (const f of files) {
      const [resultURL, errors] = await getPresignedUrl(
        f.file.name + `?vendorId=${entityId}`
      );
      if (errors !== null) {
        toast({
          title: `Could not upload ${f.file.name}`,
          status: 'error',
        });
      } else {
        try {
          //upload file
          uploadFile(resultURL.url, f.file);
        } catch (err) {
          console.error(err);
          toast({
            title: `Could not upload ${f.file.name}`,
            status: 'error',
          });
        }
      }
      uploadedFileSize += f.file.size;
      setProgress((100 * (uploadedFileSize + 0.0)) / totalFileSize);
    }
    setProgress(100);
    await delay(1000);
    setProgress(0);
    setFiles([]);
    await refetch();
  }

  function uploadFile(url, file) {
    const fileData = new FormData();
    fileData.append('file', file);
    var client = new XMLHttpRequest();
    client.open('PUT', url, false);
    client.send(file);
  }

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      //accept: { 'text/csv': [] },
      maxFiles: maxFiles,
      multiple: false,
      validator: myValidator,
      onDropAccepted: (e) => {
        setIsDropping(false);
      },
      onDropRejected: (e) => {
        setIsDropping(false);
      },
      onError: (e) => {
        setIsDropping(false);
      },

      onDrop: (acceptedFiles) => {
        setIsDropping(true);
        const new_list = [...files];
        acceptedFiles.forEach((file) => {
          new_list.push({ file: file, entityId: entityId });
          setFiles(new_list);
        });
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  function myValidator(myFile) {
    //1. check that we are under the max. file limit
    if (myFile.kind && files.length === maxFiles) {
      toast({
        title: `You cannot add more than ${maxFiles} files`,
        status: 'error',
      });
      return { message: '', code: '' };
    }
    if (files.length === maxFiles) {
      return { message: '', code: '' };
    }
    //2. Check if file already exists
    if (!myFile.kind && files.some((f) => f.file.name === myFile.name)) {
      toast({
        title: `Duplicate file`,
        status: 'error',
      });
      return { message: '', code: '' };
    }

    return null;
  }

  return (
    <>
      <Box className='uploader--container'>
        {isDropping && (
          <Center>
            <Spinner />
          </Center>
        )}

        <div {...getRootProps({ style })}>
          <VStack>
            <Flex>
              {files.map((f, i) => (
                <VStack mr='30px' key={i}>
                  <Center>
                    <Image
                      boxSize='50px'
                      src='https://upload.wikimedia.org/wikipedia/commons/7/70/Edge-gtk-new.svg'
                      alt='csv icon'
                    />
                  </Center>
                  <Center>
                    <Text style={{ fontSize: 'var(--chakra-fontSizes-2xs)' }}>
                      {f.file.name}
                    </Text>
                  </Center>
                </VStack>
              ))}
            </Flex>
            <Box>
              <Center>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </Center>
            </Box>
          </VStack>
        </div>
      </Box>
      {files.length > 0 && (
        <Flex className='upload-progress--container'>
          <Text className='progress-text'>Progress</Text>
          <Progress
            className='progress-bar'
            value={progress}
            width='100%'
            colorScheme='cyan'
          />
        </Flex>
      )}

      {files.length > 0 && (
        <Box className='uploader-preview--container'>
          <Flex className='uploader-preview--footer'>
            <Button
              className='reset-button'
              size='sm'
              colorScheme='cyan'
              color='white'
              onClick={() => {
                setFiles([]);
              }}
            >
              RESET
            </Button>
            <Button
              className='complete-upload-button'
              size='sm'
              colorScheme='cyan'
              color='white'
              onClick={async () => await uploadEverything()}
            >
              COMPLETE UPLOAD
            </Button>
          </Flex>
        </Box>
      )}
    </>
  );
}
