import { useToast, VStack } from '@chakra-ui/react';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
} from '@chakra-ui/icons';

import {
  Box,
  Button,
  Center,
  Flex,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
} from '@chakra-ui/react';

import UserForm from '../UserForm/UserForm';
import { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
//import UserPermissionsTab from '../UserPermissionsTab/UserPermissionsTab';
import UserClientTab from '../UserClientTab/UserClientTab';
import { useForm, SubmitHandler } from 'react-hook-form';
import logo from '../../../logo-teal.png';
import { useMojoEffect } from 'api/useMojoEffect';
import { useMojoFetch } from 'api/useMojoFetch';

type Tenant = {
  TenantId: string;
  name: string;
  TenantGroupId: string;
  checked: boolean;
  Group: any;
};

export type FormData = {
  firstName: string;
  lastName: string;
  phone: string;
  blockFlag: boolean;
  activeFlag: boolean;
  email: string;
  userRole: string;
  userRoleOption: string;
  password: string;
  passwordCopy: string;
};

type UserDto = {
  UserId: string;
  firstName: string;
  lastName: string;
  phone: string;
  blockFlag: boolean;
  activeFlag: boolean;
  email: string;
  //userRoleOption: string;
  //userRole: String;
  roles: any;
  password: string;
  //tenantIds: String[];
  //permissionIds: String[];
};

export default function UserCreate(props) {
  const [isLoading, setLoading] = useState(false);
  const { userId } = useParams();
  const [myUserId, setUserId] = useState(userId);

  const toast = useToast();

  const [activeFlag, setActiveFlag] = useState(true);
  const [blockFlag, setBlockFlag] = useState(false);
  const [defaultRole, setRole] = useState('');
  const [roleOptions, setRoleOptions] = useState([] as any);

  const [values, setData] = useState<FormData>({
    firstName: '',
    lastName: '',
    phone: '',
    blockFlag: blockFlag,
    activeFlag: activeFlag,
    email: '',
    userRoleOption: '',
    userRole: '',
    password: '',
    passwordCopy: '',
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ values });

  const navigate = useNavigate();

  const { data: roles, error: roleError } = useMojoFetch(
    '/api/v1/roles/',
    'get'
  );
  const { data: groups, error: groupError } = useMojoFetch(
    '/api/v1/groups/',
    'get'
  );
  const { data: originalTenants, error: tenantError } = useMojoFetch(
    '/api/v1/Clients/',
    'get'
  );

  const { data: user, isLoading: formLoading } = useMojoFetch(
    `/api/v1/users/${userId}`,
    'get'
  );
  const [selectedGroups, setSelectedGroups] = useState([]);
  const { run: createUser } = useMojoEffect(`/api/v1/users/`, 'post');
  const { run: updateUser } = useMojoEffect(`/api/v1/users/${userId}`, 'put');
  const [tenants, setAllTenants] = useState<Tenant[]>([]);
  const [defaultRoleValue, setDefaultRoleValue] = useState({
    value: '',
    label: '',
  });
  const [defaultRoleOption, setDefaultRoleOption] = useState({
    value: '',
    label: '',
  });

  useEffect(() => {
    const myTenants = originalTenants.map((d) => ({ ...d, checked: false }));
    //Get the full user
    if (userId !== undefined && user.UserId !== undefined) {
      setData(user);
      setActiveFlag(user.activeFlag);
      setBlockFlag(user.blockFlag);

      if (
        user.roles.Organizations !== undefined &&
        user.roles.Organizations.length > 0
      ) {
        setDefaultRoleValue({
          value: user.roles.Organizations[0].role_id,
          label: user.roles.Organizations[0].name,
        });

        setDefaultRoleOption({
          value: user.roles.Organizations[0].entity_id,
          label: user.roles.Organizations[0].entity,
        });
      } else if (
        user.roles.Agencies !== undefined &&
        user.roles.Agencies.length > 0
      ) {
        setDefaultRoleValue({
          value: user.roles.Agencies[0].role_id,
          label: user.roles.Agencies[0].name,
        });

        setDefaultRoleOption({
          value: user.roles.Agencies[0].entity_id,
          label: user.roles.Agencies[0].entity,
        });
      } else if (
        user.roles.Groups !== undefined &&
        user.roles.Groups.length > 0
      ) {
        setDefaultRoleValue({
          value: user.roles.Groups[0].role_id,
          label: user.roles.Groups[0].name,
        });

        setDefaultRoleOption({
          value: user.roles.Groups[0].entity_id,
          label: user.roles.Groups[0].entity,
        });
      } else if (
        user.roles.Clients !== undefined &&
        user.roles.Clients.length > 0
      ) {
        setRole(user.roles.Clients[0].name);
        setDefaultRoleValue({
          value: user.roles.Clients[0].role_id,
          label: user.roles.Clients[0].name,
        });
        user.roles.Clients.forEach((t) => {
          const tenant = myTenants.find((x) => x.TenantId === t.entity_id);
          if (tenant) {
            tenant.checked = true;
          }
        });
        
        const groupIds = new Set<string>(myTenants.filter((t) => t.checked).map((t) => t.Group.Id));
        setSelectedGroups(groups
          .filter((g) => Array.from(groupIds).includes(g.id))
          .map((g) => {
            return { value: g.id, label: g.name };
          }));        
      }
    }
    setAllTenants([...myTenants]);
  }, [user, originalTenants]);

  if (formLoading) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setLoading(true);
    const { userRole, userRoleOption, ...cleanUser } = data;
    var userDto: UserDto;

    const role = roles.find((x) => x.Id === userRole);
    const roleOption = roleOptions.find((x) => x.id === userRoleOption);

    const myRoles = {
      Organizations: [] as any,
      Agencies: [] as any,
      Groups: [] as any,
      Clients: [] as any,
    };

    if (role.name.includes('Organization')) {
      myRoles.Organizations.push({
        role_id: role.Id,
        name: role.name,
        entity_id: roleOption.id,
        entity: roleOption.name,
      });
    } else if (role.name.includes('Agency')) {
      myRoles.Agencies.push({
        role_id: role.Id,
        name: role.name,
        entity_id: roleOption.id,
        entity: roleOption.name,
      });
    } else if (role.name.includes('Group')) {
      myRoles.Groups.push({
        role_id: role.Id,
        name: role.name,
        entity_id: roleOption.id,
        entity: roleOption.name,
      });
    } else if (role.name.includes('Client')) {
      tenants
        .filter((t) => t.checked === true)
        .forEach((t) => {
          myRoles.Clients.push({
            role_id: role.Id,
            name: role.name,
            entity_id: t.TenantId,
            entity: t.name,
          });
        });
    }

    userDto = {
      ...cleanUser,
      UserId: '',
      //permissionIds: [],
      roles: myRoles,
      /*
      tenantIds: tenants
        .filter((t) => t.checked === true)
        .map((t) => t.TenantId),
        */
    };

    if (userDto.password !== data.passwordCopy) {
      setLoading(false);
      toast({
        title: 'Passwords do not match',
        status: 'error',
      });
      return;
    }

    if (userId !== undefined) {
      userDto.UserId = userId ?? '';
      const [body, error] = await updateUser(userDto);
      if (error === null) {
        toast({
          title: 'User updated',
          status: 'success',
        });
      } else {
        toast({
          title: 'User update failed',
          status: 'error',
        });
      }
    } else {
      const [body, error] = await createUser(userDto);
      if (error === null) {
        toast({
          title: 'User created',
          status: 'success',
        });
        setActiveFlag(data.activeFlag);
        setBlockFlag(data.blockFlag);
        setUserId(body.UserId);
      } else {
        toast({
          title: 'User creation failed',
          status: 'error',
        });
      }
    }
    setLoading(false);
  };

  const handleTenant = (tenantId, groupId) => {
    var tenant = tenants.find((t) => t.TenantId === tenantId);

    if (tenant != null) {
      tenant.checked = !tenant?.checked;
    }
    setAllTenants([...tenants]);
  };

  const handleToggle = (e, groupId) => {
    var group = groups.find((g) => g.id === groupId);

    var someTenants = tenants.filter((t) => t.TenantGroupId === groupId);

    if (e.target.checked) {
      if (group != null) {
        group.checked = true;
        someTenants.map((st) => (st.checked = true));
      }
    } else {
      if (group != null) {
        group.checked = false;
        someTenants.map((st) => (st.checked = false));
      }
    }

    setAllTenants([...tenants]); //useState() hook so that userClient part of form re-renders
  };

  const resetForm = () => {
    reset({
      firstName: '',
      lastName: '',
      phone: '',
      blockFlag: false,
      activeFlag: true,
      email: '',
      userRoleOption: '',
      userRole: '',
      password: '',
      passwordCopy: '',
    });

    setActiveFlag(true);
    setBlockFlag(false);

    setRoleOptions([]);
    setDefaultRoleValue({ value: '', label: '' });
    setDefaultRoleOption({ value: '', label: '' });

    tenants.map((st) => (st.checked = false));
    setAllTenants([...tenants]);
    setUserId('');
  };

  return (
    <VStack align='left'>
      <Flex ml='2em' top='7em' width={'500px'} position='fixed'>
        <Button
          leftIcon={
            <ChevronLeftIcon height={6} width={'auto'} color={'cyan'} />
          }
          variant={'mojoDefault'}
          onClick={() => navigate('/users')}
        >
          <Text>USERS</Text>
        </Button>

        <Center w='150px'>
          <Text>
            <b>
              {myUserId === '' || myUserId === undefined
                ? 'ADD NEW USER'
                : 'UPDATE USER'}
            </b>
          </Text>
        </Center>
      </Flex>
      <Flex>
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserForm
            isLoading={isLoading}
            register={register}
            userId={myUserId}
            roles={roles}
            activeFlag={activeFlag}
            blockFlag={blockFlag}
            resetForm={resetForm}
            setRole={setRole}
            setRoleOptions={setRoleOptions}
            defaultRoleValue={defaultRoleValue}
            defaultRoleOption={defaultRoleOption}
          />
        </form>
        <Box width='5%' />        
        <Tabs width='50%' display={defaultRole.includes('Client') ? 'block' : 'none'}>
            <TabList>
              <Tab>CLIENTS</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <UserClientTab
                groups={groups}
                tenants={tenants}
                handleTenant={handleTenant}
                handleToggle={handleToggle}
                selectedGroups={selectedGroups}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>      
      </Flex>
    </VStack>
  );
}
