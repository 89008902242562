import { useAuth0 } from "@auth0/auth0-react";
import { Center, ChakraProvider, ColorModeScript, Flex, Box, Image } from "@chakra-ui/react";
import { VendorsList } from "modules/vendors";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./modules/dashboard/index";
import {
    ClientContacts,
    TenantIntegrations,
    ClientNewForm,
    ClientUsers,
    TenantVendors,
    ClientsList,
} from "./modules/client";
import LoginRedirect from "./pages/LoginRedirect";
import { Home } from "./pages/Home";
import { Reports } from "./pages/Reports";
import { Settings } from "./pages/Settings";
import { GoogleOAuthCallback } from "./pages/GoogleOauth";
import { theme } from "./styles/theme";
import { ClientDetail } from "modules/client/ClientDetail";
import { Sidebar } from "layout/Sidebar";
import { Empty } from "screens/Empty";
import { Maintainance } from "screens/Maintainance";
import { VendorDetail } from "modules/vendors/VendorDetail";
import { VendorContacts } from "modules/vendors";
import { VendorServices } from "modules/vendors";
import { VendorPresentations } from "modules/vendors";
import { VendorNotes } from "modules/vendors";
import { VendorLinks } from "modules/vendors";
import logo from "./logo-teal.png";
import { UserCreate, UserList } from "modules/user"
import ProvidersList from "modules/provider/ProvidersList";
import AgenciesList from "modules/agency/AgenciesList/AgenciesList";
import GroupsList from "modules/group/GroupsList/GroupsList";
import UserList2 from "modules/user2/UserList2";
import { UserProfile } from "pages/UserProfile";
import { ReactNode } from "react";
import Footer from "layout/Footer/Footer";
import AdminPage from "modules/admin/AdminPage";
import BudgetsIntro from "modules/budget/BudgetsIntro";
import GoalsIntro from "modules/goal/GoalsIntro";
import TopBar from "layout/TopBar/TopBar";
import { useMojoFetch } from "api/useMojoFetch";
import { useDispatch } from "react-redux";
import {
  setGroups
} from 'store/reducers/appSlice';
import ProtectedRoute from "components/ProtectedRoute";

function App({ children }: { children: ReactNode }) {
    const {
        isLoading,
        isAuthenticated,
        error
    } = useAuth0();

    if (isLoading) {
        return (
            <Center bg="white" h={"100%"} w={"100%"} position={'fixed'}>
                <Flex justify="center" direction="column">
                    <Image src={logo} alt="Mojo Platform" />
                    <Box className="loader">Loading...</Box>
                    <Center>Redirecting...</Center>
                </Flex>
            </Center>
        );
    }
    if (error) {
        return <Center>Oops... {error.message}</Center>;
    }

    return (
        <BrowserRouter>
            <ChakraProvider theme={theme}>
                <ColorModeScript initialColorMode={theme.config.initialColorMode}/>
                <Flex className="app-wrapper">
                    <TopBar />
                    <Sidebar />
                    <Flex className="app-content--container">
                        {isAuthenticated ? children : <LoginRedirect />}
                    </Flex>
                    <Footer />
                </Flex>
            </ChakraProvider>
        </BrowserRouter>
    );
}
export default App;

export function MojoRouter() {
    return (
        <Routes>
            <Route path='/login' element={<LoginRedirect />} />
            <Route path='/oauth/google/callback' element={<GoogleOAuthCallback />} />
            <Route path='/' index element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path='/home' element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path='/reports' element={<ProtectedRoute><Reports /></ProtectedRoute>} />
            <Route path='/admin' element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
            <Route path='/organizations' element={<ProtectedRoute><ProvidersList /></ProtectedRoute>} />
            <Route path='/agencies' element={<ProtectedRoute><AgenciesList /></ProtectedRoute>} />
            <Route path='/groups' element={<ProtectedRoute><GroupsList /></ProtectedRoute>} />
            <Route path='/users/provider/:name/:id' element={<ProtectedRoute><UserList2 label='Organization' url='Organizations' /></ProtectedRoute>} />
            <Route path='/users/agency/:name/:id' element={<ProtectedRoute><UserList2 label='Agency' url='Agencies' /></ProtectedRoute>} />
            <Route path='/users/group/:name/:id' element={<ProtectedRoute><UserList2 label='Group' url='Groups' /></ProtectedRoute>} />
            <Route path='/users/tenant/:name/:id' element={<ProtectedRoute><UserList2 label='Client' url='Clients' /></ProtectedRoute>} />
            <Route path='/clients' element={<ProtectedRoute><ClientsList /></ProtectedRoute>} />
            <Route path='/clients/new' element={<ProtectedRoute><ClientNewForm /></ProtectedRoute>} />
            <Route path='/clients/:tenantId' element={<ProtectedRoute><ClientDetail /></ProtectedRoute>}>
                <Route path='' element={<ProtectedRoute><ClientContacts /></ProtectedRoute>} />
                <Route path='contacts' element={<ProtectedRoute><ClientContacts /></ProtectedRoute>} />
                <Route path='users' element={<ProtectedRoute><ClientUsers /></ProtectedRoute>} />
                <Route path='integrations' element={<ProtectedRoute><TenantIntegrations /></ProtectedRoute>} />
                <Route path='vendors' element={<ProtectedRoute><TenantVendors /></ProtectedRoute>} />
                <Route path='info' element={<ProtectedRoute><Maintainance /></ProtectedRoute>} />
                <Route path='*' element={<ProtectedRoute><Empty /></ProtectedRoute>} />
            </Route>
            <Route path='/budgets' element={<ProtectedRoute><BudgetsIntro /></ProtectedRoute>} />
            <Route path='/partners' element={<ProtectedRoute><VendorsList /></ProtectedRoute>} />
            <Route path='/partners/new' element={<ProtectedRoute><VendorDetail /></ProtectedRoute>} />
            <Route path='/partners/:partnerId' element={<ProtectedRoute><VendorDetail /></ProtectedRoute>}>
                <Route path='notes' element={<ProtectedRoute><VendorNotes /></ProtectedRoute>} />
                <Route path='presentations' element={<ProtectedRoute><VendorPresentations /></ProtectedRoute>} />
                <Route path='services' element={<ProtectedRoute><VendorServices /></ProtectedRoute>} />
                <Route path='links' element={<ProtectedRoute><VendorLinks /></ProtectedRoute>} />
                <Route path='contacts' element={<ProtectedRoute><VendorContacts /></ProtectedRoute>} />
                <Route path='*' element={<ProtectedRoute><Empty /></ProtectedRoute>} />
            </Route>
            <Route path='/goals' element={<ProtectedRoute><GoalsIntro /></ProtectedRoute>} />
            <Route path='/settings' element={<ProtectedRoute><Settings /></ProtectedRoute>} />
            <Route path='/users' element={<ProtectedRoute><UserList /></ProtectedRoute>} />
            <Route path='/users/new' element={<ProtectedRoute><UserCreate /></ProtectedRoute>}></Route>
            <Route path='/users/modify/:userId' element={<ProtectedRoute><UserCreate /></ProtectedRoute>}></Route>
            <Route path='/userprofile/:userId' element={<ProtectedRoute><UserProfile /></ProtectedRoute>}></Route>
            <Route path='*' element={<Empty />} />
        </Routes>
    );
}
