import { AddIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    FormControl,
    useToast,
    Editable,
    EditableTextarea,
    EditablePreview,
    Card,
    useColorModeValue,
    Flex,
    VStack,
    Textarea,
    FormLabel,
} from "@chakra-ui/react";
import { useMojoEffect } from "api/useMojoEffect";
import { useMojoFetch } from "api/useMojoFetch";
import { FloatingLabelInput } from "components/FloatingLabelInput";
import { Select, useSelect } from "components/Select";
import { Toggle } from "components/Toggle";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./AddEditNotesForm.scss";

export type AddEditNotesFormProps = {
    vendorId: string;
    refetch: () => void;
};

export function AddEditNotesForm({ vendorId, refetch }: AddEditNotesFormProps) {
    const { run: runCreate } = useMojoEffect("/api/v1/VendorNotes/create", "post");

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm();

    // const values = {} as Parameters<typeof runCreate>[0];
    const toast = useToast();

    const bg = useColorModeValue("white.100", "black.100");
    const color = useColorModeValue("black.700", "white.100");

    return (
        <Card p={18} bg={bg} width="100%">
        <form
            onSubmit={handleSubmit(async (data) => {
                const [createdData, error] = await runCreate({
                    ...data,
                    VendorId: vendorId,
                    subject: 'test subject',
                    UserId: "5e81606e-3125-4f44-83ad-fb7f6df563ce",
                });
                if (error === null) {
                    await refetch();
                    reset();
                    toast({
                        title: "Note created",
                        status: "success",
                    });
                } else {
                    toast({
                        title: "Note creation failed",
                        status: "error",
                    });
                }
                return createdData;
            })}
        >
            <Flex flexDirection="column"  minWidth="400px" width="100%" gap="5px">
                <FormControl isInvalid={!!errors.content}>
                    <FormControl className="form-upper form">
                        <FormLabel>ADD NEW NOTE</FormLabel>
                    </FormControl>
                    
                        {/* <Flex flexDirection="column" width="100%" gap="5px">
                            <Editable startWithEditView={true}>
                                <Flex width={"100%"} gap={"10px"} justifyContent={"space-between"}>
                                    <FloatingLabelInput
                                        placeholder="Notes"
                                        isRequired={false}
                                        {...register('content')}
                                        type='text'
                                        resize="vertical"
                                        style={{ width: '100%', height: '100%', minWidth:"100px", minHeight:"100px", maxHeight:"300px" }}
                                    />
                                </Flex>
                            </Editable>
                        </Flex> */}

                        <Box width="100%" maxWidth="700px">
                            <Textarea
                                placeholder="Notes"
                                isRequired={false}
                                {...register('content')}
                                resize="vertical"
                                width="100%"
                                minHeight="100px"  
                                maxHeight="300px" 
                            />
                        </Box>
                        
                        <Box mb="20px" />
        
                            <FormControl className="bt-add">
                                <Flex justify="flex-end">
                                <Button
                                    //isLoading={isRunningCreate}
                                    variant="mojoPrimary"
                                    type="submit"
                                >
                                    <AddIcon />
                                </Button>
                                </Flex>
                            </FormControl>
                        
                    
                
            </FormControl>
            </Flex>
        </form>
        </Card>
    );
}
