import { InlineEditableDataTable } from "components/DataTable/InlineEditableDataTable";
import { components } from "api/typesgen";
import { Column } from "react-table";
import { path } from "ramda";
import { DataTableSkeleton } from "components/DataTable/Skeleton";
import { useMojoFetch } from "api/useMojoFetch";
import { useCallback } from "react";
import { Select } from "components/Select";
import { Tag } from "@chakra-ui/react";
import "./datatables.css";
import { EditableSwitchCell } from "components/EditableSwitchCell";

const columns: (Column<components['schemas']['Contact']> & { label?: (contact: components['schemas']['Contact']) => string })[] = [
    {
        Header: "FIRST NAME",
        accessor: "firstName",
        // Filter: InputTextFilter,
        // filter: "rankedMatchSorter",
        // filterPlaceholder: 'Search by Name',
    },
    {
        Header: "LAST NAME",
        accessor: "lastName",
        // Filter: InputTextFilter,
        // filter: "rankedMatchSorter",
        // filterPlaceholder: 'Search by Name',
    },
    {
        Header: "E-MAIL",
        accessor: "email",
        // Filter: InputTextFilter,
        // filter: "rankedMatchSorter",
        // filterPlaceholder: 'E-mail',
    },
    {
        Header: "PHONE",
        accessor: "phone",
        // Filter: InputTextFilter,
        // filter: "rankedMatchSorter",
        // filterPlaceholder: 'phone',
    },
    {
        Header: "JOB TYPE",
        accessor: 'JobTypeId',
        label: contact => path(['JobType', 'name'], contact) || '<empty>',
        // width: '20ch',
        // Filter: SelectFilter,
        // filter: "rankedMatchSorter",
        // filterPlaceholder: 'Role',
    },
    {
        Header: 'IS PRIMARY',
        accessor: 'primaryFlag',
        // label: ({ primaryFlag }) => primaryFlag ? 'yes' : 'no',
        Cell: ({ value }) => value ? <Tag variant='solid' colorScheme='green' textTransform='uppercase'>primary</Tag> : <></>,
        // Filter: SelectFilter,
        // filter: "rankedMatchSorter",
        // filterPlaceholder: 'Public Key',
    },
];

const tableHeader = (
    <></>
);

const EditableSelectCell = ({
    value,
    row: { index: rowIdx },
    column: { id: cellKey },
    updateRowData,
  }) => {
    const { data: jobTypesData } = useMojoFetch('/api/v1/JobTypes/all', 'get');

    const jobTypeOptions = jobTypesData ?
        jobTypesData.map(({ JobTypeId, name }) => ({ label: name, value: JobTypeId })) :
        [];

    const onChange = useCallback((selectedValue: unknown) => {
        if (updateRowData) {
            updateRowData(rowIdx, cellKey, selectedValue);
        }
    }, [rowIdx, cellKey, updateRowData]);

    return (
        <Select className="min-width-20" value={value} onChange={onChange} options={jobTypeOptions} />
    );
}

export type ContactsDataTableProps<T = components['schemas']['Contact']> = {
    error: any;
    deleteContact: (contact: T) => Promise<void>;
    updateContact: (idx: number, contact: Partial<T>) => Promise<void>; 
    data?: T[];
}

export function ContactsDataTable({ data, error, deleteContact, updateContact }: ContactsDataTableProps) {
    const tableMarkup = data ? (
        <InlineEditableDataTable
            data={data}
            columns={columns}
            updateRow={updateContact}
            deleteRow={deleteContact}
            cells={{
                JobTypeId: EditableSelectCell,
                primaryFlag: EditableSwitchCell,
            }}
        >
            {{ tableHeader }}
        </InlineEditableDataTable>
    ) : <DataTableSkeleton columns={columns}>
            {{ rowFooter: () => <></> }}
        </DataTableSkeleton>;

    return (
        <div className="datatable datatable-contact-list">
            <div className="datatable-controls screen">
                {tableMarkup}
            </div>
        </div>
    );
} 
