import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { BellIcon } from "@chakra-ui/icons";
import { notifications } from "../hardcoded_data";


function NotificationsButton() {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<BellIcon boxSize={22} strokeWidth={"2px"} />}
        variant="ghost"
        size="md"
        className="notification-badge-icon"
        color={"white.100"}
        stroke={"black.600"}
      />
      <MenuList>
        {notifications.map(({ text }) => (
          <MenuItem key={text}>{text}</MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export default NotificationsButton;
