import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IntegrationsState {
  integrationId: string;
  googleProperty: string;
  googleIntegrationTenant: string;
  googleAdsCustomerId: string;
  googleAdsLinkedCustomerId: string;
  type: string;
}

export const initialIntegrationsState: IntegrationsState = {
  integrationId: "",
  googleProperty: "",
  googleIntegrationTenant: "",
  googleAdsCustomerId: "",
  googleAdsLinkedCustomerId: "",
  type: "",
};

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState: initialIntegrationsState,
  reducers: {
    setTenantGoogleProperty: (state, action: PayloadAction<string>) => {
      state.googleProperty = action.payload;
    },
    setTenantGoogleAdsCustomerId: (state, action: PayloadAction<string>) => {
      state.googleAdsCustomerId = action.payload;
    },
    setTenantGoogleAdsLinkedCustomerId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.googleAdsLinkedCustomerId = action.payload;
    },
    setGoogleIntegrationTenant: (state, action: PayloadAction<string>) => {
      state.googleIntegrationTenant = action.payload;
    },
    setGoogleIntegrationType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setTenantGoogleIntegrationId: (state, action: PayloadAction<string>) => {
      state.integrationId = action.payload;
    },
  },
});

export const {
  setTenantGoogleProperty,
  setGoogleIntegrationTenant,
  setTenantGoogleAdsLinkedCustomerId,
  setTenantGoogleAdsCustomerId,
  setGoogleIntegrationType,
  setTenantGoogleIntegrationId,
} = integrationsSlice.actions;

export default integrationsSlice;
