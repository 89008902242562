import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    bg: "white",
    bgColor: "white",
    border: "1px solid",
    borderColor: "#d5d5d5",
    borderWidth: "2px",
    borderRadius: "var(--chakra-radii-md)",

    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "var(--chakra-fontSizes-xs)",
    height: "var(--chakra-sizes-9)",
    paddingInlineStart: "var(--chakra-space-3)",
    paddingInlineEnd: "var(--chakra-space-3)",
    paddingTop: "var(--chakra-space-3)",
    paddingBottom: "var(--chakra-space-3)",
    backgroundColor: "var(--chakra-colors-white)",
    transitionProperty: "var(--chakra-transition-property-common)",
    transitionDuration: "var(--chakra-transition-duration-normal)",
    appearance: "none",
    position: "relative",
    outline: "transparent solid 2px",
    outlineOffset: "2px",
    minWidth: "0px",
  },
});

const sm = defineStyle({
  fontSize: "xs",
  px: 3,
  py: 3,
});

const md = defineStyle({
  fontSize: "13px",
  px: 5,
  py: 5,
});

const sizes = {
  md: definePartsStyle({ field: md, addon: md }),
  sm: definePartsStyle({ field: sm, addon: sm }),
};

export const Input = defineMultiStyleConfig({ baseStyle, sizes });
