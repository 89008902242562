import { useAuth0 } from '@auth0/auth0-react';

export function useMojoEffect(url, method) {

  const { getAccessTokenSilently } = useAuth0();

  //Can be used only once
  //Since it always appends to the url

  async function runWithId(id: string, object: any = null): Promise<[any[] | any, { message: string }]> {   
    const my_url = process.env.REACT_APP_API_URL + url + id;
    return await runWithUrl(my_url, object);
  }
  
  async function run(object: any = null): Promise<[any[] | any, { message: string }]> {

    const my_url = process.env.REACT_APP_API_URL + url;
    return runWithUrl(my_url, object);
  }

  async function runWithUrl(my_url:string, object: any = null): Promise<[any[] | any, { message: string }]> {    
    const token = await getAccessTokenSilently();
    const options: RequestInit = {
      headers: {
        "Content-Type": "application/json", Authorization: 'Bearer ' + token
      },
      method: method,
    }

    if (object !== null) {
      options.body = JSON.stringify(object);
    }

    let body = [];
    let error: any | null = null;
    try {
      const response = await fetch(my_url, options);
      if (response.status === 404) { //dirty hack to account for aws dirty behaviour
        error = { message: 'not found' };
      } else if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        error = { message: response.statusText };
      }
      else {
        try {
          body = await response.json();
        }
        catch { } //shallow exception in cases where controller returns empty response body
      }
    }
    catch (e) {
      error = { message: 'An error occured' };
    }
    return [body, error];
  }

  return { run, runWithId };
}

