import ClientAddEditForm from "../AddEditClientForm";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Button, Heading } from "@chakra-ui/react";
import { useNavigate } from "hooks/useNavigate";
import './ClientNewForm.scss';

export function ClientNewForm() {
  const navigate = useNavigate();
  return (
    <>
      <div className="client">
        <div className="client-form-header">
          <Button
            leftIcon={<ChevronLeftIcon height={6} width="auto" color="cyan" />}
            variant="mojoDefault"
            onClick={() => navigate('/clients')}
          >CLIENT LIST</Button>
          <Heading size={"md"}>CREATE NEW CLIENT</Heading>
        </div>
        <div className="client-modal">
          <ClientAddEditForm onlyRequired />
        </div>
      </div>
    </>
  );
}
