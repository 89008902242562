import {
  Box,
  FormControl,
  useToast,
  HStack,
  Textarea,
  Tooltip,
  Select,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

import { useMojoEffect } from "api/useMojoEffect";
import { FloatingLabelInput } from "components/FloatingLabelInput";
import { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FacebookOauth } from "./FacebookOauthButton";
import {
  GoogleServiceAccountButton,
  updateGoogleAnalyticsProps,
  UpdateGooglePropsButton,
  updateVautoProps,
} from "./GoogleOauthButton";
import { allTenantIntegrations } from "data/allIntegrations";
import Button from "components/Button";

export type AddEditIntegrationFormProps = {
  tenantId: string;
  refetch: () => void;
};

export function AddEditIntegrationForm({
  tenantId,
  refetch,
}: AddEditIntegrationFormProps) {
  const [defaultValues, setDefaultValues] = useState();
  const [integrationType, setIntegrationType] = useState("");
  const [integrationId, setIntegrationId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  const { run: runCreate } = useMojoEffect(
    "/api/v1/TenantIntegrations/create",
    "post"
  );

  const { control, register, handleSubmit, formState, reset, watch, setValue } =
    useForm<NonNullable<any>>({
      defaultValues: useMemo(() => {
        return defaultValues;
      }, [defaultValues]),
    });

  const useFormBuilder = ({
    register,
    isLoading,
    formState,
    formValues,
    integrationId,
    refetch,
  }) => {
    const formFields = {
      [allTenantIntegrations.facebook.id]: [
        {
          type: "button",
          name: "Facebook Oauth",
          component: (
            <Box>
              <FacebookOauth tenantId={tenantId} />
            </Box>
          ),
        },
      ],
      [allTenantIntegrations.googleAds.id]: [
        {
          type: "input",
          name: "Customer ID",
          component: (
            <Box>
              <Tooltip label="The Google Ads Customer ID">
                <FloatingLabelInput
                  isRequired
                  placeholder={"Customer ID"}
                  {...register("customerId", { required: true })}
                />
              </Tooltip>
            </Box>
          ),
        },
        {
          type: "input",
          name: "Linked Customer ID",
          component: (
            <Box>
              <Tooltip label="The Google Ads Customer ID">
                <FloatingLabelInput
                  isRequired
                  placeholder={"Linked Customer ID"}
                  {...register("linkedCustomerId", { required: false })}
                />
              </Tooltip>
            </Box>
          ),
        },
        {
          type: "button",
          name: "Update Google Ads Props",
          component: (
            <Box>
              <UpdateGooglePropsButton
                type={"ads"}
                integrationId={integrationId}
                isLoading={isLoading}
                formValues={formValues}
                refetch={refetch}
              />
            </Box>
          ),
        },
      ],
      [allTenantIntegrations.googleService.id]: [
        {
          type: "input",
          name: "Service Account Credentials",
          component: (
            <Box>
              <Textarea
                isRequired
                size="sm"
                h={20}
                placeholder="Credentials"
                {...register("gCredentials", { required: true })}
              />
            </Box>
          ),
        },
        {
          type: "button",
          name: "Connect Service Account",
          component: (
            <Box marginTop={"-40px !important"}>
              <GoogleServiceAccountButton
                integrationId={integrationId}
                isLoading={isLoading}
                formValues={formValues}
                onSubmit={refetch}
              />
            </Box>
          ),
        },
      ],
      [allTenantIntegrations.vautoInventory.id]: [
        {
          type: "input",
          name: "Customer ID",
          component: (
            <Box>
              <Tooltip label="The VAuto Dealer ID">
                <FloatingLabelInput
                  isRequired
                  placeholder={"vAuto Dealer ID"}
                  {...register("dealerId", { required: true })}
                />
              </Tooltip>
            </Box>
          ),
        },
        {
          type: "button",
          name: "Submit Button",
          component: (
            <div className="bt-add">
              <IconButton
                aria-label=""
                isLoading={isLoading}
                variant="mojoPrimary"
                type="submit"
                onClick={async (e) => {
                  const { dealerId } = formValues;
                  await updateVautoProps(toast, { dealerId });
                  refetch();
                  console.log("ee");
                  console.log({ formValues });
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          ),
        },
      ],
      [allTenantIntegrations.googleAnalytics.id]: [
        {
          type: "input",
          name: "Customer ID",
          component: (
            <Box>
              <Tooltip label="The Property ID">
                <FloatingLabelInput
                  isRequired
                  placeholder={"Property ID"}
                  {...register("propertyId", { required: true })}
                />
              </Tooltip>
            </Box>
          ),
        },
        {
          type: "button",
          name: "Submit Button",
          component: (
            <div className="bt-add">
              <IconButton
                aria-label=""
                isLoading={isLoading}
                variant="mojoPrimary"
                type="submit"
                onClick={async (e) => {
                  const { propertyId } = formValues;
                  await updateGoogleAnalyticsProps(toast, { propertyId });
                  refetch();
                  reset();
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          ),
        },
      ],
      default: [
        // {
        //   type: "input",
        //   name: "Secret Key",
        //   component: (
        //     <FloatingLabelInput
        //       placeholder="Public Key"
        //       {...register("publicKey")}
        //     />
        //   ),
        // },
        // {
        //   type: "input",
        //   name: "Secret Key",
        //   component: (
        //     <FloatingLabelInput
        //       placeholder="Secret Key"
        //       {...register("secretKey")}
        //     />
        //   ),
        // },
        // {
        //   type: "button",
        //   name: "Submit Button",
        //   component: (
        //     <div className="bt-add">
        //       <Button isLoading={isLoading} variant="mojoPrimary" type="submit">
        //         <AddIcon />
        //       </Button>
        //     </div>
        //   ),
        // },
      ],
    };

    return { formFields };
  };

  // useEffect(() => {
  //   let get = async () => {
  //     try {
  //       let googleProps = await getGoogleProps(integrationType);
  //       reset();
  //       reset({
  //         property: googleProps.property,
  //         customerId: googleProps.customerId,
  //         linkedCustomerId: googleProps.linkedCustomerId,
  //       });
  //     } catch (error) {
  //       console.error("Error fetching Google props:", error);
  //     }
  //   };
  //   get();
  // }, [integrationType]);

  const { errors } = formState;
  const formValues = watch();

  const { formFields } = useFormBuilder({
    register,
    formState,
    formValues,
    integrationId,
    refetch,
    isLoading: isLoading,
  });

  const integrationsData = Object.values(allTenantIntegrations).map(
    ({ id: IntegrationId, name }) => ({
      name,
      IntegrationId,
    })
  );

  const integrationOptions = integrationsData
    ? integrationsData.map(({ IntegrationId, name }) => ({
        label: name,
        value: IntegrationId,
      }))
    : [];

  useEffect(() => {
    register("IntegrationId");
  }, [register]);

  useEffect(() => {
    const { IntegrationId } = formValues;

    integrationsData?.forEach((elem) => {
      if (IntegrationId === elem.IntegrationId) {
        setIntegrationType(elem.IntegrationId);
        setIntegrationId(IntegrationId);
      }
    });
  }, [formValues]);

  const buildForm = (integrationType) => {
    if (!formFields[integrationType]) {
      integrationType = "default";
    }
    const components = formFields[integrationType]?.map((elem) => {
      return elem?.component;
    });

    return <>{components?.map((c) => c)}</>;
  };

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        const [createData, error] = await runCreate({
          ...data,
          TenantId: tenantId,
          IntegrationId: data.IntegrationId,
        });
        if (error === null) {
          await refetch();
          reset();
          toast({
            title: "Integration created",
            status: "success",
          });
        } else {
          toast({
            title: "Integration creation failed",
            status: "error",
          });
        }
        return createData;
      })}
    >
      <FormControl isInvalid={!!errors.username}>
        <div className="form-upper-wrapper">
          <div className="form-upper form">
            <div className="form-upper-title">ADD NEW INTEGRATION</div>
          </div>
          <div className="form">
            <div className="input-group min-width-20">
              <Select
                className="min-width-20"
                placeholder="Type"
                {...register("IntegrationId", { required: true })}
              >
                {integrationOptions.map((j) => (
                  <option value={j.value}>{j.label}</option>
                ))}
              </Select>
            </div>
            <HStack spacing="10px">{buildForm(integrationType)}</HStack>
          </div>
        </div>
      </FormControl>
    </form>
  );
}
