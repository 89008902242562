import { useAuth0 } from '@auth0/auth0-react';
import { Button, useToast } from '@chakra-ui/react';
import { useMojoEffect } from 'api/useMojoEffect';
import IconFacebook from 'snippets/icon-facebook';
//const API_VERSION = 'v18.0';
//const METRICS = `page_fans,page_views_total,page_impressions`;
//const PERIOD = 'day';

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: {
      init: (params: any) => void;
      login: (param1: any, param2: any) => any;
    };
  }
}

/*
  const getUserFbPageToken = async(userAccessToken) => {
    const url = `https://graph.facebook.com/${API_VERSION}/me/accounts?access_token=${userAccessToken}`
    return await fetch(url)
  }
  
  const getFbPageData = async ({ access_token, id }) => {
  */
/* 
      - Needs to be moved to a chron job
      - Should get credentials from s3 bucket
    */
/*    
const url = `https://graph.facebook.com/${API_VERSION}/${id}/insights?metric=${METRICS}&period=${PERIOD}&access_token=${access_token}`
    return await fetch(url)
  }
*/

export const FacebookOauth = (props) => {

const getLonglivedToken = async (exchangeToken, token) => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/oauth/facebook`;
  const requestBody = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      exchangeToken,
      tenantId: props.tenantId,
    }),
  };

  return await fetch(url, requestBody);
};



  const { getAccessTokenSilently } = useAuth0();
  const { run: update } = useMojoEffect(
  `/api/v1/TenantIntegrations/facebook/${props.tenantId}`,
  'post'
  );
  const toast = useToast();
  const facebookLogin = () => {
    return new Promise((resolve, reject) => {
      window?.FB?.login(
        (response) => {
          if (response.status === 'connected') {
            const exchangeToken = response.authResponse.accessToken;
            resolve(exchangeToken);
          } else {
            reject('Login failed or was canceled.');
          }
        },
        { scope: 'pages_show_list, read_insights, ads_read' }
      );
    });
  };

  const handleFacebookLogin = async () => {
    try {
      const accessToken = await facebookLogin();
      const token = await getAccessTokenSilently();
      const res = await getLonglivedToken(accessToken, token); //calls api and saves / updates the token
      let json = await res.json();      
      await uploadFBCreds(json.userId, json.access_token);
      //res = await getUserFbPageToken(longLivedAccessToken)
      //json = await res.json()
      //let pageDetails = json.data[0] // Not sure if this will always be in position 0
      //let data = await getFbPageData(pageDetails)
      //let jsonData = await data.json()
      //console.info(jsonData)
    } catch (error) {
      console.error('Facebook login error:', error);
    }
  };

  const uploadFBCreds = async (userId: string, longLivedAccessToken: string) => {    
    let body = { id: userId, access_token: longLivedAccessToken };
    const [, error] = await update(body);
    if (error === null) {
          toast({
            title: 'Facebook credentials updated',
            status: 'success',
          });
    }
    else {

          toast({
            title: 'Facebook credentials update failed!',
            status: 'error',
          });
    }
  };

  return (
    <Button
      leftIcon={<IconFacebook />}
      onClick={handleFacebookLogin}
      variant='mojoPrimary'
    >
      Connect
    </Button>
  );
};
