import { useMojoFetch } from 'api/useMojoFetch';
import './AgenciesList.scss';
import { useNavigate } from 'hooks/useNavigate';
import {  useState } from 'react';
import AddEditAgencyForm from '../AddEditAgencyForm/AddEditAgencyForm';
import AgenciesTable from '../AgenciesTable/AgenciesTable';
import { Box, Center, Flex, Image, Text, Heading } from '@chakra-ui/react';
import logo from '../../../logo-teal.png';

function AgenciesList() {
  const { error, data, refetch, isLoading } = useMojoFetch(`/api/v1/agencies`, 'get');
  const [agency, setAgency] = useState({
    id: '',
    name: '',
    providers: [{ id: '', name: '' }],
  });
  const [key, setKey] = useState(1);
  const navigate = useNavigate();

  const editAgency = (id: string) => {
    const agency = data.find((d) => d.id === id);
    setAgency(agency);
    setKey(key + 1);
  };

  const addEditUsers = (id: string) => {
    const agency = data.find((d) => d.id === id);
    navigate(`/users/agency/${agency.name}/${id}`);
  };

  const myRefetch = async () => {
    setAgency({ id: '', name: '', providers: [] });
    await refetch();   
  };

  const reset = () => {
    setAgency({ id: '', name: '', providers: [] });
  };

  if (isLoading) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }
  
  if (error.message !== '') {         
    return <Center>{error.message}</Center>;
  }

  return (
    <>
        <Flex className="agencies-page--header">
          <Flex className="agencies-header--left">
            <Heading className="agencies-heading">AGENCIES LIST</Heading>
          </Flex>
        </Flex>

      <div className='datatable-header'>
        <AddEditAgencyForm
          key={key}
          agency={agency}
          refetch={myRefetch}
          reset={reset}
          defaultValues={
            agency.id !== ''
              ? agency.providers.map((o) => {
                  return { label: o.name, value: o.id };
                })
              : null
          }
        />
      </div>
      <div className='agencies-list'>
        <AgenciesTable
          data={data}
          editAgency={editAgency}
          addEditUsers={addEditUsers}
        />
      </div>
    </>
  );
}

export default AgenciesList;
