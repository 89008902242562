import { InlineEditableDataTable } from "components/DataTable/InlineEditableDataTable";
import { components } from "api/typesgen";
import { Column } from "react-table";
import { DataTableSkeleton } from "components/DataTable/Skeleton";
import { Tag } from "@chakra-ui/react";
import "./datatables.css";
import { EditableSwitchCell } from "components/EditableSwitchCell";

const columns: (Column<components['schemas']['VendorContact']> & {
  label?: (contact: components['schemas']['VendorContact']) => string;
})[] = [
  {
    Header: 'FIRST NAME',
    accessor: 'firstName',
  },
  {
    Header: 'LAST NAME',
    accessor: 'lastName',
  },
  {
    Header: 'E-MAIL',
    accessor: 'email',
  },
  {
    Header: 'PHONE',
    accessor: 'phone',
  },
  {
    Header: 'TITLE',
    accessor: 'title',
  },
  /*{
    Header: 'STATUS',
    accessor: 'status',
    Cell: ({ value }) =>
      value  ? (
        <Tag variant='solid' colorScheme='green' textTransform='uppercase'>
          active
        </Tag>
      ) : (
        <Tag variant='solid' colorScheme='orange' textTransform='uppercase'>
          inactive
        </Tag>
      ),
  },*/
];

const tableHeader = <></>;

export type ContactsDataTableProps<T = components["schemas"]["VendorContact"]> = {
    error: any;
    deleteContact: (contact: T) => Promise<void>;
    updateContact: (idx: number, contact: Partial<T>) => Promise<void>;
    data?: T[];
};

export function ContactsDataTable({
    data,
    error,
    deleteContact,
    updateContact,
}: ContactsDataTableProps) {
    const tableMarkup = data ? (
      <InlineEditableDataTable
        data={data}
        columns={columns}
        updateRow={updateContact}
        deleteRow={deleteContact}        
      >
        {{ tableHeader }}
      </InlineEditableDataTable>
    ) : (
      <DataTableSkeleton columns={columns}>
        {{ rowFooter: () => <></> }}
      </DataTableSkeleton>
    );

    return (
        <div className="datatable datatable-contact-list">
            <div className="datatable-controls screen">{tableMarkup}</div>
        </div>
    );
}
