import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Button, Flex, Input } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { DataTable, DataTableProps } from "./DataTable";
import { ButtonDanger } from "components/ButtonDanger/ButtonDanger";
import { repeat } from "ramda";

export type InlineEditableDataTableProps<T extends object> =
  DataTableProps<T> & {
    updateRow?: (idx: number, row: Partial<T>) => void;
    deleteRow?: (row: T) => void;
    cellTypes?: { [accessor: string]: string };
    onlyDelete?: Boolean;
  };

const LabelCell = ({ row: { original }, column: { label } }) => {
  return <span className="min-width-20">{label?.(original)}</span>;
};

const MaskedCell = ({ value }) => {
  if (!value) return;
  const masked =
    value?.length < 3
      ? repeat("*", value?.length)
      : [value.at(0), ...repeat("*", value?.length - 2), value.at(-1)].join("");
  return <span className="min-width-20">{masked}</span>;
};

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateRowData,
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback(
    (e) => {
      const value = e.target.value;
      setValue(value);
      if (updateRowData) {
        updateRowData(index, id, value);
      }
    },
    [index, id, updateRowData]
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <Input value={value} onChange={onChange} sx={{ fontSize: "13px" }} />;
};

export function InlineEditableDataTable<T extends object>({
  data,
  columns,
  children,
  cells,
  cellTypes = {},
  updateRow,
  deleteRow,
  onlyDelete = false,
}: InlineEditableDataTableProps<T>) {
  const [currentEditedRowIdx, setCurrentEditedRowIdx] = useState<number>();
  const [currentEditedRowData, setCurrentEditedRowData] = useState<Partial<T>>(
    {}
  );

  const toggleRowEditing = useCallback(
    (rowIdx: number) => {
      if (currentEditedRowIdx === rowIdx) {
        updateRow && updateRow(currentEditedRowIdx, currentEditedRowData);
        setCurrentEditedRowIdx(undefined);
      } else {
        setCurrentEditedRowIdx(rowIdx);
      }
    },
    [currentEditedRowIdx, updateRow, currentEditedRowData]
  );

  const cancelEditing = () => {
    setCurrentEditedRowIdx(undefined);
    setCurrentEditedRowData({});
  };

  const updateRowData = useCallback(
    (rowIdx: number, columnId: keyof T, value: string) => {
      if (rowIdx !== currentEditedRowIdx) {
        setCurrentEditedRowIdx(rowIdx);
        setCurrentEditedRowData({});
      }
      setCurrentEditedRowData({ ...currentEditedRowData, [columnId]: value });
    },
    [currentEditedRowIdx, currentEditedRowData]
  );

  const cellType = (rowIdx: number, cellIdx: number) => {
    const hasLabel = columns[cellIdx].label;
    const isMasked = columns[cellIdx].masked;
    const isCurrentlyEdited = rowIdx === currentEditedRowIdx;
    const accessor = columns[cellIdx].accessor;
    const isId = accessor?.toString().endsWith("Id");
    const isFlag = accessor?.toString().endsWith("Flag");
    const isDisabled = columns[cellIdx].disabled;
    const customCellType =
      cellTypes[columns[cellIdx].accessor?.toString() ?? ""];

    return isCurrentlyEdited && !isDisabled
      ? isId || isFlag
        ? accessor?.toString() || customCellType || "EditableCell"
        : customCellType || "EditableCell"
      : hasLabel
      ? "LabelCell"
      : isMasked
      ? "MaskedCell"
      : "Cell";
  };

  return (
    <DataTable
      data={data}
      columns={columns}
      defaultColumn={{ EditableCell, MaskedCell, LabelCell }}
      customFunctions={{ updateRowData }}
      cellType={cellType}
      cells={cells}
    >
      {{
        ...children,
        rowFooter: (rowData: any, rowIdx: number) =>
          onlyDelete ? (
            <>
              <Button
                color="Red"
                onClick={() => {
                  deleteRow && deleteRow(rowData);
                }}
              >
                <DeleteIcon />
              </Button>
            </>
          ) : (
            <Flex gap={1}>
              {currentEditedRowIdx === rowIdx ? (
                <>
                  <Button
                    variant="ghost"
                    colorScheme="green"
                    onClick={() => {
                      toggleRowEditing(rowIdx);
                    }}
                  >
                    <CheckIcon />
                  </Button>
                  <Button
                    variant="ghost"
                    colorScheme="gray"
                    onClick={cancelEditing}
                  >
                    <CloseIcon />
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="mojoPrimaryGhost"
                    onClick={() => toggleRowEditing(rowIdx)}
                  >
                    <EditIcon />
                  </Button>
                  <ButtonDanger
                    onClick={() => deleteRow && data && deleteRow(rowData)}
                  >
                    <DeleteIcon />
                  </ButtonDanger>
                </>
              )}
            </Flex>
          ),
      }}
    </DataTable>
  );
}
