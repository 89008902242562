import { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  HStack,
  Spinner,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import '../../../components/DataTable/MojoTableStyles.scss';
import './BudgetTable.scss';
import { Form, Input, InputNumber, Table, Modal, Tooltip } from 'antd';
import { useMojoEffect } from 'api/useMojoEffect';
import { BsPencilSquare } from 'react-icons/bs';
import { DeleteIcon } from '@chakra-ui/icons';
import PartnerSelect from './Selects/PartnerSelect';
import ColumnFilters from 'components/DataTable/Filters/ColumnFilter/ColumnFilters';
import DepartmentSelect from './Selects/DepartmentSelect';
import GenericSelect from './Selects/GenericSelect';

const header = [
  'location',
  'objective',
  'type',
  'audience',
  'department',
  'channel',
  'medium',
  'partner',
  'notes',
  'coop_eligible',
  'coop_percentage',
  'notes',
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'avg',
  'total',
  'actions',
];

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode = <Input />;
  console.log(children);
  if (editing) {
    inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    if (dataIndex === 'partner_name') {
      inputNode = <PartnerSelect />;
    } else if (dataIndex === 'department') {
      inputNode = <DepartmentSelect />;
    } else if (dataIndex === 'channel') {
      inputNode = <GenericSelect url={'/api/v1/channels/'} />;
    } else if (dataIndex === 'medium') {
      inputNode = <GenericSelect url={'/api/v1/media/'} />;
    } else if (dataIndex === 'type') {
      inputNode = <GenericSelect url={'/api/v1/channeltypes/'} />;
    } else if (dataIndex === 'objective') {
      inputNode = <GenericSelect url={'/api/v1/objectives/'} />;
    } else if (dataIndex === 'audience') {
      inputNode = <GenericSelect url={'/api/v1/audiences/'} />;
    }
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          initialValue={null}
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              // required: false,
              required: [
                'location',
                'objective',
                'type',
                'audience',
                'department',
                'channel',
                'medium',
                'partner_name',
              ].includes(dataIndex),
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function BudgetsTable({
  data,
  vendors,
  departments,
  channels,
  channelTypes,
  media,
  objectives,
  audiences,
  year,
  location,
  location_name,
  columnVisibility,
  setColumnVisibility,
}) {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState(data);
  const [editingKey, setEditingKey] = useState('');
  const [loadingRow, setLoadingRow] = useState({});

  const [usersColumnVisibility, setUsersColumnVisibility] =
    useState(columnVisibility);

  const columns = [
    {
      title: 'Location',
      dataIndex: header[0],
      key: 'location',
      fixed: 'left',
      width: 150,
      // defaultSortOrder: 'ascend',
      editable: false,
      sorter: (a, b) => {
        if (a.location === null || b.location === null) return 0;
        return (a.location ?? '').localeCompare(b.location ?? '');
      },
    },
    {
      title: 'Dept',
      dataIndex: header[4],
      key: 'department',
      // fixed: 'left',
      width: 120,
      // defaultSortOrder: 'descend',
      editable: true,
      sorter: (a, b) => {
        if (a.department === null || b.department === null) return 0;
        return (a.department ?? '').localeCompare(b.department ?? '');
      },
    },
    {
      title: 'Partner',
      dataIndex: 'partner_name',
      key: 'partner',
      // fixed: 'left',
      width: 150,
      // defaultSortOrder: 'descend',
      editable: true,
      sorter: (a, b) => {
        if (a.partner_name === null || b.partner_name === null) return 0;
        return (a.partner_name ?? '').localeCompare(b.partner_name ?? '');
      },
    },
    {
      title: 'Channel',
      dataIndex: header[5],
      key: 'channel',
      // fixed: 'left',
      width: 130,
      // defaultSortOrder: 'descend',
      editable: true,
      sorter: (a, b) => {
        if (a.channel === null || b.channel === null) return 0;
        return (a.channel ?? '').localeCompare(b.channel ?? '');
      },
    },
    {
      title: 'Type',
      dataIndex: header[2],
      key: 'type',
      fixed: 'left',
      width: 100,
      // defaultSortOrder: 'descend',
      editable: true,
      sorter: (a, b) => {
        if (a.type === null || b.type === null) return 0;
        return (a.type ?? '').localeCompare(b.type ?? '');
      },
    },
    {
      title: 'Medium',
      dataIndex: header[6],
      key: 'medium',
      // fixed: 'left',
      width: 150,
      // defaultSortOrder: 'descend',
      editable: true,
      sorter: (a, b) => {
        if (a.medium === null || b.medium === null) return 0;
        return (a.medium ?? '').localeCompare(b.medium ?? '');
      },
    },
    {
      title: 'Objective',
      dataIndex: header[1],
      key: 'objective',
      fixed: 'left',
      width: 140,
      // defaultSortOrder: 'ascend',
      editable: true,
      sorter: (a, b) => {
        if (a.objective === null || b.objective === null) return 0;
        return (a.objective ?? '').localeCompare(b.objective ?? '');
      },
    },
    {
      title: 'Audience',
      dataIndex: header[3],
      key: 'audience',
      // fixed: 'left',
      width: 130,
      // defaultSortOrder: 'descend',
      editable: true,
      sorter: (a, b) => {
        if (a.audience === null || b.audience === null) return 0;
        return (a.audience ?? '').localeCompare(b.audience ?? '');
      },
    },
    {
      title: 'Notes',
      dataIndex: header[8],
      key: 'notes',
      width: 300,
      editable: true,
      render: (text) => (
        <Tooltip title={text}>
          <span className='notes-column'>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: 'COOP Eligible',
      dataIndex: header[9],
      key: 'coop_eligible',
      // fixed: 'left',
      width: 125,
      // defaultSortOrder: 'descend',
      editable: true,
      sorter: (a, b) => {
        if (a.coop_eligible === null || b.coop_eligible === null) return 0;
        return (a.coop_eligible ?? '').localeCompare(b.coop_eligible ?? '');
      },
    },
    {
      title: 'COOP Percentage',
      dataIndex: header[10],
      key: 'coop_percentage',
      // fixed: 'left',
      width: 150,
      // defaultSortOrder: 'descend',
      editable: true,
      sorter: (a, b) => {
        if (a.coop_percentage === null || b.coop_percentage === null) return 0;
        return (a.coop_percentage ?? 0) - (b.coop_percentage ?? 0);
      },
    },
    {
      title: 'Jan',
      dataIndex: header[12],
      key: 'january',
      width: 110,
      editable: true,
    },
    {
      title: 'Feb',
      dataIndex: header[13],
      key: 'february',
      width: 110,
      editable: true,
    },
    {
      title: 'Mar',
      dataIndex: header[14],
      key: 'march',
      width: 110,
      editable: true,
    },
    {
      title: 'Apr',
      dataIndex: header[15],
      key: 'april',
      width: 110,
      editable: true,
    },
    {
      title: 'May',
      dataIndex: header[16],
      key: 'may',
      width: 110,
      editable: true,
    },
    {
      title: 'June',
      dataIndex: header[17],
      key: 'june',
      width: 110,
      editable: true,
    },
    {
      title: 'July',
      dataIndex: header[18],
      key: 'july',
      width: 110,
      editable: true,
    },
    {
      title: 'Aug',
      dataIndex: header[19],
      key: 'august',
      width: 110,
      editable: true,
    },
    {
      title: 'Sept',
      dataIndex: header[20],
      key: 'september',
      width: 110,
      editable: true,
    },
    {
      title: 'Oct',
      dataIndex: header[21],
      key: 'october',
      width: 110,
      editable: true,
    },
    {
      title: 'Nov',
      dataIndex: header[22],
      key: 'november',
      width: 110,
      editable: true,
    },
    {
      title: 'Dec',
      dataIndex: header[23],
      key: 'december',
      width: 110,
      editable: true,
    },
    {
      title: 'Average',
      dataIndex: header[24],
      key: 'avg',
      width: 110,
      editable: true,
    },
    {
      title: 'Total',
      dataIndex: header[25],
      key: 'total',
      width: 110,
      editable: true,
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      key: 'actions',
      fixed: 'right',
      width: 150,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <HStack className='actions-editing-btn-group'>
            {isRowLoading(record.budgetId) ? (
              <Spinner size='sm' color={'mojo.200'} />
            ) : (
              <>
                <Button
                  className='action-btn--save'
                  onClick={() => {
                    save(record.budgetId);
                  }}
                  disabled={isRowLoading(record.budgetId)}
                >
                  Save
                </Button>
                <Button
                  className='action-btn--cancel'
                  onClick={() => handleCancel(record)}
                >
                  Cancel
                </Button>
              </>
            )}
          </HStack>
        ) : (
          <HStack className='actions-btn-group'>
            {isRowLoading(record.budgetId) ? (
              <Spinner size='sm' color={'mojo.200'} />
            ) : (
              <Button
                className='action-btn--edit'
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
              >
                <BsPencilSquare />
              </Button>
            )}
            <Button
              className='action-btn--delete'
              onClick={() => deleteBudgetItem(record.budgetId)}
            >
              <DeleteIcon />
            </Button>
          </HStack>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: [
          'coop_percentage',
          'january',
          'february',
          'march',
          'april',
          'may',
          'july',
          'august',
          'september',
          'october',
          'november',
          'december',
          'avg',
          'total',
        ].includes(col.dataIndex)
          ? 'number'
          : col.dataIndex === 'notes'
          ? 'text'
          : 'text',
        // inputType: ['coop_percentage', 'january', 'february', 'march', 'april', 'may', 'july', 'august', 'september', 'october', 'november', 'december'].includes(col.dataIndex)
        //     ? 'number'
        //     : col.dataIndex === 'coop_eligible'
        //         ? 'boolean'
        //         : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const visibleColumnsOptions = columns.map(({ key, title }) => ({
    label: typeof title === 'string' ? title : (key as string),
    value: key as string,
  }));

  const { run: createBudget } = useMojoEffect(`/api/v1/Budgets/`, 'POST');
  const { runWithId: updateBudget } = useMojoEffect(`/api/v1/Budgets/`, 'PUT');
  const { runWithId: deleteBudget } = useMojoEffect(
    '/api/v1/Budgets/',
    'DELETE'
  );

  const isEditing = (record) => record.budgetId === editingKey;
  const edit = (record) => {
    setUsersColumnVisibility(columnVisibility);
    setColumnVisibility(header);
    form.setFieldsValue({
      location: location,
      objective: '',
      type: '',
      audience: '',
      department: '',
      channel: '',
      medium: '',
      partner_name: '',
      partner: '',
      notes: '',
      coop_eligible: '',
      coop_percentage: '',
      january: '',
      february: '',
      march: '',
      april: '',
      may: '',
      june: '',
      july: '',
      august: '',
      september: '',
      october: '',
      november: '',
      december: '',
      avg: '',
      total: '',
      year: year,

      ...record,
    });
    setEditingKey(record.budgetId);
  };

  const cancel = () => {
    setDataSource(dataSource.filter((d) => d.budgetId !== undefined));
    setEditingKey('');
    setColumnVisibility(usersColumnVisibility);
  };

  const toast = useToast();

  const save = async (budgetId: string) => {
    setLoadingRow((prev) => ({ ...prev, [budgetId]: true }));
    let row;
    try {
      row = await form.validateFields();
    } catch {
      setLoadingRow((prev) => ({ ...prev, [budgetId]: false }));
      return;
    }
    try {
      let v = vendors.find((v) => v.name === row.partner_name);
      if (v === undefined) {
        v = vendors.find((v) => v.VendorId === row.partner_name);
      }
      row.partner = v.unique_name;

      let c = channels.find((c) => c.name === row.channel);
      if (c === undefined) {
        c = channels.find((c) => c.id === row.channel);
        row.channel = c.name;
      }

      let t = channelTypes.find((tp) => tp.name === row.type);
      if (t === undefined) {
        t = channelTypes.find((tp) => tp.id === row.type);
        row.type = t.name;
      }

      let a = audiences.find((au) => au.name === row.audience);
      if (a === undefined) {
        a = audiences.find((au) => au.id === row.audience);
        row.audience = a.name;
      }

      let o = objectives.find((o) => o.name === row.objective);
      if (o === undefined) {
        o = objectives.find((o) => o.id === row.objective);
        row.objective = o.name;
      }

      let m = media.find((m) => m.name === row.medium);
      if (m === undefined) {
        m = media.find((m) => m.id === row.medium);
        row.medium = m.name;
      }

      let dept = departments.find((d) => d.name === row.department);
      if (dept === undefined) {
        dept = departments.find((d) => d.id === row.department);
        row.department = dept.name;
      }

      row.location = location_name;
      row.coop_percentage = row.coop_percentage
        ? row.coop_percentage.toString()
        : '';
      row.january = row.january ? row.january.toString() : '';
      row.february = row.february ? row.february.toString() : '';
      row.march = row.march ? row.march.toString() : '';
      row.april = row.april ? row.april.toString() : '';
      row.may = row.may ? row.may.toString() : '';
      row.june = row.june ? row.june.toString() : '';
      row.july = row.july ? row.july.toString() : '';
      row.august = row.august ? row.august.toString() : '';
      row.september = row.september ? row.september.toString() : '';
      row.october = row.october ? row.october.toString() : '';
      row.november = row.november ? row.november.toString() : '';
      row.december = row.december ? row.december.toString() : '';
      row.avg = row.avg ? row.avg.toString() : '';
      row.total = row.total ? row.total.toString() : '';
      row.year = year.toString();

      const newData = [...dataSource];
      const isNewRow = budgetId === undefined || budgetId === '';

      const { partner_name, ...rowToSave } = row;
      if (isNewRow) {
        const { createdItem, error } = await handleCreateBudget(rowToSave);

        if (!error) {
          const v = vendors.find((v) => v.unique_name === createdItem.partner);
          createdItem.partner_name = v.name;
          newData[0] = { ...newData[0], ...createdItem };
          setDataSource([...newData]);
          setEditingKey('');
          setLoadingRow((prev) => ({ ...prev, [budgetId]: false }));
          Modal.success({
            content: 'Budget created successfully!',
          });
        } else {
          throw new Error('Failed to create new budget item');
        }
        setColumnVisibility(usersColumnVisibility);
        return;
      }

      // existing budget
      const index = newData.findIndex((item) => budgetId === item.budgetId);
      const { updatedItem, error } = await handleUpdateBudget(
        budgetId,
        rowToSave
      );

      if (!error) {
        const v = vendors.find((v) => v.unique_name === updatedItem.partner);
        updatedItem.partner_name = v.name;
        newData.splice(index, 1, { ...newData[index], ...updatedItem });
        setDataSource([...newData]);
        setEditingKey('');
        setLoadingRow((prev) => ({ ...prev, [budgetId]: false }));
        Modal.success({
          content: 'Budget updated successfully!',
        });
      } else {
        throw new Error('Failed to update budget');
      }
    } catch (errInfo) {
      setLoadingRow((prev) => ({ ...prev, [budgetId]: false }));
      console.log('Validate Failed:', errInfo);
      Modal.error({
        content: 'Failed to save budget',
      });
    }
    setColumnVisibility(usersColumnVisibility);
  };

  const handleCreateBudget = async (budgetItem: any) => {
    const [createdItem, error] = await createBudget(budgetItem);
    return { createdItem, error };
  };

  const handleUpdateBudget = async (budgetId: string, row: any) => {
    const [updatedItem, error] = await updateBudget(budgetId, row);
    return { updatedItem, error };
  };

  const deleteBudgetItem = async (budgetId: string) => {
    const [, error] = await deleteBudget(budgetId);
    if (error === null) {
      const index = dataSource.findIndex((item) => item.budgetId === budgetId);
      dataSource.splice(index, 1);
      setDataSource([...dataSource]);
      Modal.success({
        content: 'Budget deleted',
      });
    } else {
      Modal.error({
        content: 'Error deleting budget, please try again. ',
      });
    }
  };

  const handleCancel = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to cancel?',
      onOk: () => cancel(),
    });
  };

  const handleAddRow = () => {
    setUsersColumnVisibility(columnVisibility);
    setColumnVisibility(header);
    const newRow = {
      location: location_name,
      objective: '',
      type: '',
      audience: '',
      department: '',
      channel: '',
      medium: '',
      partner: '',
      partner_name: '',
      notes: '',
      coop_eligible: '',
      coop_percentage: '',
      january: '',
      february: '',
      march: '',
      april: '',
      may: '',
      june: '',
      july: '',
      august: '',
      september: '',
      october: '',
      november: '',
      december: '',
      avg: '',
      total: '',
      year: year,
    };
    setDataSource([newRow, ...dataSource]);
    setEditingKey('');
    edit(newRow);
  };

  const isRowLoading = (budgetId) => loadingRow[budgetId];

  const bg = useColorModeValue('white.100', '#121212');
  const hideColumnsBg = useColorModeValue('white', '#282828');
  const fontColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', '#3f3f3f');
  const tableBg = useColorModeValue('white', '#282828');

  return (
    <Box className='budgets-table' bg={bg} color={fontColor}>
      <Flex className='table-header'>
        {/* Will be used at a later date 
                <Flex className="data-toggle--wrapper">
                    <Switch 
                        id="actualBudgetDataToggle"
                        checkedChildren="Actual"
                        unCheckedChildren="Budget"
                        onChange={() => setUseActualData(!useActualData)} 
                    />
                </Flex> */}
        <Button
          isDisabled={location === ''}
          className='add-row--btn primary-btn'
          onClick={handleAddRow}
        >
          Add Item
        </Button>
        <ColumnFilters
          options={visibleColumnsOptions}
          value={columnVisibility}
          onChange={setColumnVisibility}
        />
      </Flex>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={dataSource.map((item) => ({
            ...item,
            loading: isRowLoading(item.budgetId),
          }))}
          columns={mergedColumns
            .filter((column) => columnVisibility.includes(column.key as string))
            .map((column) => ({
              ...column,
              fixed:
                column.fixed === 'left'
                  ? 'left'
                  : column.fixed === 'right'
                  ? 'right'
                  : undefined,
            }))}
          scroll={{ x: 1000, y: 500 }}
          pagination={false}
          style={{
            background: bg,
            border: `1px solid ${borderColor}`,
            borderCollapse: 'collapse',
          }}
        />
      </Form>
    </Box>
  );
}

export default BudgetsTable;
