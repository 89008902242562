import { InlineEditableDataTable } from "components/DataTable/InlineEditableDataTable";
import { components } from "api/typesgen";
import { Column } from "react-table";
import "./datatables.css";
import { DataTableSkeleton } from "components/DataTable/Skeleton";
import { useCallback } from "react";
import { Select } from "components/Select";
import { Tag } from "@chakra-ui/react";
import { EditableSwitchCell } from "components/EditableSwitchCell";



const tableHeader = <></>;



export type UsersDataTableProps<T = components["schemas"]["CreateUserDto"]> = {
    error: any;
    deleteUser: (contact: T) => Promise<void>;
    updateUser: (idx: number, contact: Partial<T>) => Promise<void>;
    data?: T[];
    rolesData: any[];
};

export function UsersDataTable({
    data,
    error,
    deleteUser,
    updateUser,
    rolesData
}: UsersDataTableProps) {

   

    const TenantRoleCell = ({tenantRoleId}:{tenantRoleId: string}) =>{       
        return <>{rolesData.find(role => role.Id === tenantRoleId)?.name ?? ''}</>
    }

    const columns: (Column<components["schemas"]["CreateUserDto"]> & {
        disabled?: boolean;
        label?: (contact: components["schemas"]["CreateUserDto"]) => string;
    })[] = [
        {
            Header: "FIRST NAME",
            accessor: "firstName",
        },
        {
            Header: "LAST NAME",
            accessor: "lastName",
        },
        {
            Header: "E-MAIL",
            accessor: "email",
        },
        {
            Header: "PHONE",
            accessor: "phone",
        },
        {
            Header: "ROLE",
            accessor: "TenantRoleId",
            Cell: ({value}) => <TenantRoleCell tenantRoleId={value} />
        },
        {
            Header: "STATUS",
            accessor: "activeFlag",
            Cell: ({ value }) =>
                value ? (
                    <Tag
                        variant="solid"
                        colorScheme="green"
                        textTransform="uppercase"
                    >
                        active
                    </Tag>
                ) : (
                    <></>
                ),
        },
    ];
    
    const EditableSelectCell = ({
        value,
        row: { index: rowIdx },
        column: { id: cellKey, accessor },
        updateRowData,
    }) => {
        //const {Roles: rolesData} = useAppSelector((state:RootState) => state.app)
        const rolesOptions = rolesData
            ? rolesData.map(({ Id, name }) => ({
                  label: name,
                  value: Id,
              }))
            : [];
    
        const onChange = useCallback(
            (selectedValue: unknown) => {
                if (updateRowData) {
                    updateRowData(rowIdx, cellKey, selectedValue);                    
                }
            },
            [rowIdx, cellKey, updateRowData]
        );

        return (
            <Select
                className="min-width-20"
                value={value}
                onChange={onChange}
                options={rolesOptions}
            />
        );
    };


    const tableMarkup = data ? (
        <InlineEditableDataTable
            data={data}
            columns={columns}
            updateRow={updateUser}
            deleteRow={deleteUser}
            cells={{
                TenantRoleId: EditableSelectCell,
                activeFlag: EditableSwitchCell,
            }}
        >
            {{ tableHeader }}
        </InlineEditableDataTable>
    ) : (
        <DataTableSkeleton columns={columns}>
            {{ rowFooter: () => <></> }}
        </DataTableSkeleton>
    );

    return (
        <div className="datatable datatable-user-list">
            <div className="datatable-controls screen">{tableMarkup}</div>
        </div>
    );
}
