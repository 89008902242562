import { useNavigate } from 'hooks/useNavigate';
import { Box, Button, Flex, IconButton, Tag, Text, Wrap, Heading } from '@chakra-ui/react';
import { components } from 'api/typesgen';
import { DataTable } from 'components/DataTable/DataTable';
import { path, pipe, prop, join, map } from 'ramda';
import { EditIcon, DeleteIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ButtonDanger } from 'components/ButtonDanger/ButtonDanger';
import { Column } from 'react-table';
import { DataTableSkeleton } from 'components/DataTable/Skeleton';
import InputTextFilter from 'components/DataTable/Filters/InputTextFilter';
import SelectFilter from 'components/DataTable/Filters/SelectFilter';
import './ClientListDataTable.scss';
import { HiMiniUsers } from 'react-icons/hi2';

const columns: Column<components['schemas']['Tenant']>[] = [
  {
    id: 'CLIENT NAME',
    Header: 'CLIENT NAME',
    accessor: 'name',
    Cell: ({ value }) => (
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value ? value : value}
      </div>
    ),
    Filter: (props) => (
      <InputTextFilter
        column={{ ...props.column, filterPlaceholder: 'Client Name' }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    id: 'CLIENT CODE',
    Header: 'CLIENT CODE',
    accessor: 'tenantCode',
    Cell: ({ value }) => (
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          maxWidth: '80pt',
          textOverflow: 'ellipsis',
        }}
      >
        {value ? value : value}
      </div>
    ),
    Filter: (props) => (
      <InputTextFilter
        column={{ ...props.column, filterPlaceholder: 'Client Code' }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    id: 'BRAND',
    Header: 'BRAND',
    accessor: pipe(
      prop('TenantBrand'),
      map(path(['Brand', 'name'])),
      join(', ')
    ),
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Brand',
          longerWidth: 200,
          className: 'fix-select-2x-larger',
        }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    id: 'TYPE',
    Header: 'TYPE',
    accessor: path(['TenantType', 'name']),
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Client Type',
          longerWidth: 200,
          className: 'fix-select-2x-larger',
        }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    id: 'GROUP',
    Header: 'GROUP',
    accessor: path(['Group', 'name']),
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Group',
          longerWidth: 200,
          className: 'fix-select-2x-larger',
        }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    id: 'PRIMARY CONTACT',
    Header: 'PRIMARY CONTACT',
    accessor: (c) => c.PrimaryContact,
    Cell: ({ value }) =>
      value ? (
        <Text>
          {value.firstName} {value.lastName}
        </Text>
      ) : (
        <Text as='i' color='gray.400'></Text>
      ),
  },
  {
    id: 'E-MAIL',
    Header: 'E-MAIL',
    accessor: (c) => c.PrimaryContact,
    Cell: ({ value }) => (
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          maxWidth: 'fit-content',
          textOverflow: 'ellipsis',
        }}
      >
        {value ? value.email : ''}
      </div>
    ),
  },
  {
    id: 'PHONE',
    Header: 'PHONE',
    accessor: 'phone',
  },
  {
    id: 'Address',
    Header: 'Address',
    accessor: 'address',
  },
  {
    id: 'CITY',
    Header: 'CITY',
    accessor: 'city',
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'City',
          longerWidth: 200,
          className: 'fix-select-2x-larger',
        }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    id: 'ZIP',
    Header: 'ZIP',
    accessor: 'zip',
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Zip',
          className: 'fix-select-2x-larger',
        }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    id: 'STATUS',
    Header: 'STATUS',
    accessor: 'activeFlag',
    Cell: ({ value }) => (
      <Tag
        style={{ fontSize: 'var(--chakra-fontSizes-2xs)' }}
        variant='solid'
        colorScheme={value ? 'green' : 'yellow'}
        
      >
        {value ? 'Active' : 'Inactive'}
      </Tag>
    ),
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Status',
          longerWidth: 200,
          className: 'fix-select-2x-larger',
        }}
        valueMap={{ true: 'Active', false: 'Inactive' }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
];

const defaultShownColumns = [
  'CLIENT NAME',
  'BRAND',
  'TYPE',
  'GROUP',
  'PRIMARY CONTACT',
  'E-MAIL',
  'CITY',
  'STATUS',
];

export function ClientListDataTable(props) {
  const navigate = useNavigate();

  if (props.error) {
    return (
      <div>
        <p>An error occurred</p>
      </div>
    );
  }
  const tableHeaderMarkup = (
    <>
    
      <Flex className="clients-page--header">
        <Flex className="clients-header--left">
            <Heading className="clients-heading">CLIENT LIST</Heading>
        </Flex>
        <Flex className="budgets-header--right">
          <Button className="add-client-btn" onClick={() => navigate('/clients/new')}>
            ADD CLIENT
          </Button>
        </Flex>
      </Flex>      
        
  </>
  );
  const tableMarkup = props.data ? (
    <DataTable
      data={props.data}
      columns={
        columns.map((column: any, index: number) => {
          if (column.id === 'CLIENT NAME') {
            return {
              ...column,
              Cell: ({ tableInfo, data, row, value }) => (
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onClick={(e) => {
                    navigate(`/clients/${row?.original?.TenantId}`);
                  }}
                  className='client-title-navigation-action-link'
                >
                  {value ? value : value}
                </div>
              ),
            };
          } else {
            return column;
          }
        }) as any[]
      }
      chooseRows
      showFilter
      defaultShownColumns={defaultShownColumns}
    >
      {{
        rowFooter: (row: any) => (
          <Flex gap={1}>
            <Button
              variant='mojoPrimaryGhost'
              onClick={() =>
                props.data && navigate(`/clients/${row?.TenantId}`)
              }
            >
              <EditIcon />
            </Button>
            <ButtonDanger
              onClick={() => props.data && props.deleteClient(row?.TenantId)}
            >
              <DeleteIcon />
            </ButtonDanger>
            {/*
            <IconButton
              mt='8px'
              colorScheme='blue'
              size='xs'
              aria-label='add users'
              icon={<HiMiniUsers />}
              onClick={() => props.addEditUsers(row.TenantId)}
            />*/}
          </Flex>
        ),
      }}
    </DataTable>
  ) : (
    <DataTableSkeleton columns={columns}>
      {{ rowFooter: () => <></> }}
    </DataTableSkeleton>
  );

  return (
    <>
      <div className='datatable-main datatable-controls datatable-client-list'>
        {tableHeaderMarkup}
        {tableMarkup}
      </div>
    </>
  );
}
