import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const mojoDefault = defineStyle({
  baseStyle: {
    light: {
      background: "gray.800",
      color: "white",
      _hover: {
        color: "cyan",
      },
    },
    dark: {
      background: "gray.100",
      color: "black",
      _hover: {
        color: "cyan.200",
      },
    },
  },
  fontFamily: "heading",
  fontWeight: "normal",
  letterSpacing: ".1em",
  fontSize: "var(--chakra-fontSizes-xs)",
});

const mojoPrimary = defineStyle({
  baseStyle: {
    light: {
      background: "#73CEE2",
      color: "white",
      _hover: {
        color: "gray.800",
      },
      _disabled: {
        background: "gray.400",
      },
    },
    dark: {
      background: "mojo.300",
      color: "white",
      _hover: {
        color: "gray.800",
      },
      _disabled: {
        background: "gray.400",
      },
    },
  },
  fontFamily: "heading",
  fontWeight: "bolder",
  letterSpacing: ".1em",
});

const mojoPrimaryGhost = defineStyle({
  baseStyle: {
    light: {
      color: "#73CEE2",
      _hover: {
        color: "gray.800",
      },
    },
    dark: {
      color: "#73CEE2",
      _hover: {
        color: "gray.800",
      },
    },
  },
  fontFamily: "heading",
  fontWeight: "bolder",
  letterSpacing: ".1em",
});

const mojoNavbar = defineStyle({
  baseStyle: {
    light: {
      color: "gray.100",
      _hover: {
        color: "cyan",
      },
      _active: {
        color: "cyan",
        _hover: {
          color: "cyan.400",
        },
      },
    },
    dark: {
      color: "gray.100",
      _hover: {
        color: "cyan.200",
      },
      _active: {
        color: "cyan.200",
        _hover: {
          color: "cyan.400",
        },
      },
    },
  },
  fontFamily: "heading",
  fontWeight: "bolder",
  letterSpacing: ".1em",
});

export const Button = defineStyleConfig({
  baseStyle: {
    light: mojoDefault,
    dark: mojoDefault,
  },
  variants: {
    mojoDefault,
    mojoPrimary,
    mojoPrimaryGhost,
    mojoNavbar,
  },
});
