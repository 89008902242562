import { MdGridView } from "react-icons/md";
import { AiOutlineDollar } from "react-icons/ai";
import { GoGraph, GoPeople, GoGoal } from "react-icons/go";
import { MdOutlineAdminPanelSettings } from "react-icons/md";


import Sidenav from "./SideNav";


const routes = [
  { to: '/', icon: MdGridView, label: 'Dashboard' },
  // { to: '/organizations', icon: SlOrganization, label: 'Organizations' },
  // { to: '/agencies', icon: TbClock, label: 'Agencies' },
  // { to: '/groups', icon: HiOutlineUserGroup, label: 'Groups' },
  { to: '/clients', icon: GoPeople, label: 'Clients' },
  { to: '/budgets', icon: AiOutlineDollar, label: 'Budgets' },
  // { to: '/partners', icon: LiaHandshake, label: 'Partners' },
  { to: '/goals', icon: GoGoal, label: 'Goals' },
  { to: '/reports', icon: GoGraph, label: 'Reports' },
  // { to: '/users', icon: BsPeople, label: 'Users' },
  { to: '/admin', icon: MdOutlineAdminPanelSettings, label: 'Admin' },
];

export function Sidebar() {
  return (
      <Sidenav navItems={routes}/>
  );
}

