import { useCallback } from "react";
import { Column } from "react-table";
import { path } from 'ramda';
import { components } from "api/typesgen";
import { useMojoFetch } from "api/useMojoFetch";
import { InlineEditableDataTable } from "components/DataTable/InlineEditableDataTable";
import { DataTableSkeleton } from "components/DataTable/Skeleton";
import { Select } from "components/Select";
import "./datatables.css";

const columns: (
    Column<components['schemas']['TenantVendor']> &
    { label?: (contact: components['schemas']['TenantVendor']) => string, disabled?: boolean, masked?: boolean }
)[] = [    
    {
        Header: "email",
        accessor: "email",
    },
    {
        Header: "Public Key",
        accessor: "publicKey",
    },
    {
        Header: "URL",
        accessor: "url",
    },
    {
        Header: "VENDOR",
        accessor: 'VendorId',
        label: contact => path(['Vendor', 'name'], contact) || '<empty>',
        disabled: true,
    },
];

const tableHeader = (
    <></>
);

const EditableSelectCell = ({
    value,
    row: { index: rowIdx },
    column: { id: cellKey, accessor },
    updateRowData,
  }) => {
    const { data: vendorsData, error: vendorsError } = useMojoFetch('/api/v1/Vendors/all', 'get');
    const vendorOptions = vendorsData ?
        vendorsData.map(({ VendorId, name }) => ({ label: name, value: VendorId })) :
        [];

    const onChange = useCallback((selectedValue: unknown) => {
        if (updateRowData) {
            updateRowData(rowIdx, cellKey, selectedValue);
        }
    }, [rowIdx, cellKey, updateRowData]);

    return (
        <Select className="min-width-20" value={value} onChange={onChange} options={vendorOptions} />
    );
}

export type VendorsDataTableProps<T = components['schemas']['TenantVendor']> = {
    error: any;
    deleteVendor: (vendor: T) => Promise<void>;
    updateVendor: (idx: number, vendor: Partial<T>) => Promise<void>; 
    data?: T[];
}

export function VendorsDataTable({ data, error, deleteVendor, updateVendor }: VendorsDataTableProps) {
    const tableMarkup = data ? (
        <InlineEditableDataTable
            data={data}
            columns={columns}
            updateRow={updateVendor}
            deleteRow={deleteVendor}
            cells={{ VendorId: EditableSelectCell }}
        >
            {{ tableHeader }}
        </InlineEditableDataTable>
    ) : <DataTableSkeleton columns={columns}>
            {{ rowFooter: () => <></> }}
        </DataTableSkeleton>;

    return (
        <div className="datatable datatable-vendor-list">
            <div className="datatable-controls screen">
                {tableMarkup}
            </div>
        </div>
    );
} 