import { useParams } from 'react-router-dom';
import './Services.scss';
import AddChannelsForm from '../AddChannelsForm/AddChannelsForm';
import ChannelsTable from '../ChannelsTable/ChannelsTable';
import { useMojoFetch } from 'api/useMojoFetch';
import { useMojoEffect } from 'api/useMojoEffect';
import { useToast } from '@chakra-ui/react';

function VendorServices() {
  const { partnerId } = useParams();
  const toast = useToast();

  const { data: channels, error: channelsError } = useMojoFetch(
    `/api/v1/channels/`,
    'get'
  );
  const { data: media, error: mediaError } = useMojoFetch(
    `/api/v1/media/`,
    'get'
  );
  const { data: channelTypes, error: channelTypesError } = useMojoFetch(
    `/api/v1/channeltypes/`,
    'get'
  );
  const { data: objectives, error: objectivesError } = useMojoFetch(
    `/api/v1/objectives/`,
    'get'
  );

  const { data: audiences, error: audiencesError } = useMojoFetch(
    `/api/v1/audiences/`,
    'get'
  );

  const { error, data, refetch } = useMojoFetch('/api/v1/Services/all', 'get');

  const {
    error: selectedChannelsError,
    data: selectedChannels,
    refetch: selectedChannelsRefetch,
  } = useMojoFetch(`/api/v1/partnerchannels/${partnerId}`, 'get');

  const { run: runCreate } = useMojoEffect('/api/v1/PartnerChannels', 'post');
  const { runWithId: runUpdate } = useMojoEffect(
    '/api/v1/partnerchannels/',
    'put'
  );
  const { runWithId: runDelete } = useMojoEffect(
    '/api/v1/partnerchannels/',
    'delete'
  );

  async function createChannel(data) {
    const [, error] = await runCreate({ partnerId: partnerId, ...data });
    if (error === null) {
      toast({
        title: 'Channel created',
        status: 'success',
      });
      selectedChannelsRefetch();
    } else {
      toast({
        title: 'Failed to create channel',
        status: 'error',
      });
    }
  }

  async function updateChannel(idx: number, pchannel) {
    if (!selectedChannels) {
      return;
    }

    /*
    channelNameId: EditableChannelCell,
        mediumNameId: EditableMediumCell,
        channelTypeNameId: EditableTypeCell,
        objectiveNameId: EditableObjectiveCell,
        audienceNameId: EditableAudienceCell,
    */
    const fullChannel = {
      partnerId,
      ...selectedChannels[idx],
    };

    if (pchannel.channelNameId !== undefined) {
      fullChannel.channelId = pchannel.channelNameId;
    }

    if (pchannel.mediumNameId !== undefined) {
      fullChannel.mediumId = pchannel.mediumNameId;
    }

    if (pchannel.channelTypeNameId !== undefined) {
      fullChannel.channelTypeId = pchannel.channelTypeNameId;
    }

    if (pchannel.objectiveNameId !== undefined) {
      fullChannel.objectiveId = pchannel.objectiveNameId;
    }

    if (pchannel.audienceNameId !== undefined) {
      fullChannel.audienceId = pchannel.audienceNameId;
    }

    const {
      channelNameId,
      mediumNameId,
      channelTypeNameId,
      objectiveNameId,
      audienceNameId, ...record
    } = fullChannel;
    const [, error] = await runUpdate(fullChannel.id, record);
    if (error === null) {
      toast({
        title: 'Channel updated',
        status: 'success',
      });
      selectedChannelsRefetch();
    } else {
      toast({
        title: 'Failed to update channel',
        status: 'error',
      });
    }
  }

  async function deleteChannel(channel) {
    const [, error] = await runDelete(channel.id);
    if (error === null) {
      toast({
        title: 'Channel deleted',
        status: 'success',
      });
      selectedChannelsRefetch();
    } else {
      toast({
        title: 'Failed to delete channel',
        status: 'error',
      });
    }
  }

  return (
    <>
      <AddChannelsForm
        channels={channels}
        media={media}
        channelTypes={channelTypes}
        objectives={objectives}
        audiences={audiences}
        vendorId={partnerId}
        createChannel={createChannel}
      />
      <h5>CHANNELS</h5>
      <ChannelsTable
        channels={channels}
        media={media}
        channelTypes={channelTypes}
        objectives={objectives}
        audiences={audiences}
        data={selectedChannels}
        updateChannel={updateChannel}
        deleteChannel={deleteChannel}
      />
    </>
  );
}

export default VendorServices;
