import { useEffect, useState } from "react";
import {
  Box,
  useColorModeValue,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Stack,
  StackDivider,
  Text,
  Button,
  Icon,
  Divider,
  Flex,
  Spinner,
  useColorMode
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "react-datepicker/dist/react-datepicker.css";
import "./charts.scss";

interface WebsiteTrafficChartProps {
  data: any;
  isLoading: boolean;
}

function WebsiteTrafficChart({data, isLoading} : WebsiteTrafficChartProps) {
    const [selectedKpi, setSelectedKpi] = useState("");

    const { colorMode } = useColorMode(); 
    const bg = useColorModeValue('white.100', '#282828');
    const cardBackground = useColorModeValue('white', '#282828');
    const fontColor = useColorModeValue('gray.800', 'white');
    const borderColor = useColorModeValue('gray.200', '#282828');
    const titleColor = useColorModeValue('black.500', 'white');
    const axisColor = useColorModeValue('#43425D', 'white')

    const [chartData, setChartData] = useState({
      series: [
        {
          name: 'NEW',
          data: data.dataSeries.map((x) =>
            x.newUsers === undefined ? 0 : x.newUsers
          ),
          type: 'column',
          color: '#73CEE2',
        },
        {
          name: 'TOTAL',
          data: data.dataSeries.map((x) =>
            x.totalUsers === undefined ? 0 : x.totalUsers
          ),
          type: 'column',
          color: '#2F8DA2',
        },
        {
          name: 'LEAD',
          data: data.dataSeries.map((x) =>
            x.leadCount === undefined ? 0 : x.leadCount
          ),
          type: 'column',
          color: '#2F8DA2',
        },
      ],
    });
    const [chartOption, setChartOption] = useState<ApexOptions>({
      title: {
        style: {
          color: titleColor,
        },
      },
      chart: {
        id: 'website-traffic--chart',
        toolbar: {
          show: false,
        },
        width: '100%',
        background: bg,
      },
      plotOptions: {
        bar: {
          columnWidth: '80%',
        },
      },
      stroke: {
        colors: ['transparent'],
        width: 5,
      },
      xaxis: {
        categories: data.categories,
        labels: {
          show: true,
          style: {
            colors: axisColor, //purple.500
            fontSize: '0.8125rem', //13px
            fontFamily: `'Poppins', sans-serif`, // poppins
          },
        },
      },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max: Math.max(
          ...data.dataSeries
            .map((x) => (x.newUsers === undefined ? 0 : x.newUsers))
            .concat(
              data.dataSeries
                .map((x) => (x.totalUsers === undefined ? 0 : x.totalUsers))
                .concat(
                  data.dataSeries.map((x) =>
                    x.leadCount === undefined ? 0 : x.leadCount
                  )
                )
            )
        )
          === 0 ? 10
          : Math.max(
              ...data.dataSeries
                .map((x) => (x.newUsers === undefined ? 0 : x.newUsers))
                .concat(
                  data.dataSeries
                    .map((x) => (x.totalUsers === undefined ? 0 : x.totalUsers))
                    .concat(
                      data.dataSeries.map((x) =>
                        x.leadCount === undefined ? 0 : x.leadCount
                      )
                    )
                )
            ),

        labels: {
          style: {
            colors: axisColor, //purple.500
            fontSize: '0.8125rem', //13px
            fontFamily: `'Poppins', sans-serif`, // poppins
          },
          formatter: (value) => `${value.toFixed(0)}`,
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetX: 0,
        offsetY: 15,
        fontSize: '14px',
        markers: {
          strokeWidth: 1,
        },
        itemMargin: {
          horizontal: 15,
        },
      },
    });
  
    useEffect(() => {
      setChartOption((prevOptions) => ({
        ...prevOptions,
        chart: {
          ...prevOptions.chart,
          background: bg, 
        },
        yaxis: Array.isArray(prevOptions.yaxis)
          ? prevOptions.yaxis.map(y => ({
              ...y,
              labels: {
                ...y.labels,
                style: {
                  ...y.labels?.style,
                  colors: axisColor,
                },
              },
            }))
          : {
              ...prevOptions.yaxis,
              labels: {
                ...prevOptions.yaxis?.labels,
                style: {
                  ...prevOptions.yaxis?.labels?.style,
                  colors: axisColor,
                },
              },
          },
        xaxis: {
          ...prevOptions.xaxis,
          labels: {
            ...prevOptions.xaxis?.labels,
            style: {
              ...prevOptions.xaxis?.labels?.style,
              colors: axisColor,
            },
          },
        },
      }));
    }, [colorMode, bg, axisColor]);
        
    return (
      <Box className='main-chart--container' bg={bg} borderColor={borderColor}>
        <Flex className='main-chart-heading--col' width='100%'>
          <Heading className='main-chart--heading' color={titleColor}>
            WEBSITE TRAFFIC
          </Heading>
        </Flex>
        <Flex className='main-chart--col'>
          <Box className='main-chart--wrapper'>
            <Flex className='main-chart--spinner'>
              {isLoading ? (
                <Spinner size='xl' />
              ) : (
                <Chart
                  className='main-chart'
                  options={chartOption}
                  series={chartData.series}
                  type='line'
                  height={500}
                />
              )}
            </Flex>
          </Box>
          <Flex className='main-chart-card--col'>
            <Card
              className='main-chart--card'
              bg={cardBackground}
              borderColor={borderColor}
            >
              <CardHeader className='chart-card--header'>
                <Heading className='chart-card--title' color={fontColor}>
                  Avg Leads Per Visitor
                </Heading>
                <Divider orientation='horizontal' />
              </CardHeader>

              <CardBody className='chart-card--body'>
                <Stack divider={<StackDivider />} spacing='4' align='center'>
                  <Flex className='chart-card-kpi positive'>
                    <Text className='chart-card-kpi--pos-value'>
                      {data.avg_leads_per_visitor !== null &&
                      data.avg_leads_per_visitor !== undefined
                        ? data.avg_leads_per_visitor
                        : 'n/a'}
                    </Text>
                  </Flex>
                  <Flex className='chart-card-kpi-data'>
                    <Box className='kpi-data-item'>
                      <Text
                        className='kpi-data-item--label'
                        textAlign='left'
                        color={fontColor}
                      >
                        Nation
                      </Text>
                      <Text
                        className='kpi-data-item--value'
                        textAlign='right'
                        color={fontColor}
                      >
                        $--
                      </Text>
                    </Box>
                    <Box className='kpi-data-item'>
                      <Text
                        className='kpi-data-item--label'
                        textAlign='left'
                        color={fontColor}
                      >
                        Brand
                      </Text>
                      <Text
                        className='kpi-data-item--value'
                        textAlign='right'
                        color={fontColor}
                      >
                        $--
                      </Text>
                    </Box>
                    <Box className='kpi-data-item'>
                      <Text
                        className='kpi-data-item--label'
                        textAlign='left'
                        color={fontColor}
                      >
                        Similar Stores
                      </Text>
                      <Text
                        className='kpi-data-item--value'
                        textAlign='right'
                        color={fontColor}
                      >
                        $--
                      </Text>
                    </Box>
                  </Flex>
                  <Flex className='chart-card-kpi-footer'>
                    <Button>VIEW FULL REPORT</Button>
                  </Flex>
                </Stack>
              </CardBody>
            </Card>
          </Flex>
        </Flex>
      </Box>
    );
}

export default WebsiteTrafficChart;