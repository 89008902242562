import { ReactNode } from 'react';
import './EditorWithDetails.scss';
import { Flex, Box } from '@chakra-ui/react'

export type EditorWithDetailsProps = {
    children: [ReactNode, ReactNode];
    hideLeftPanel?: boolean
    style?: React.CSSProperties
}
export function EditorWithDetails({ children, hideLeftPanel, style }: EditorWithDetailsProps) {
    return (
        <Box className="client-profile" style={style}>
            {!hideLeftPanel && <Box className="client-profile-left">
                {children[0]}
            </Box>}
            <Flex className="client-profile-right">
                {children[1]}
            </Flex>
        </Box>
    );
}