import {
    useToast,    
    CardBody,
    Card,
    Text,
    Button,
    Input,
} from "@chakra-ui/react";
import { AddEditNotesForm } from "../AddEditNotesForm";
import { useParams } from "react-router-dom";
import { useMojoFetch } from "api/useMojoFetch";
import { useMojoEffect } from "api/useMojoEffect";

import {
    CheckIcon,
    CloseIcon,
    CopyIcon,
    DeleteIcon,
    EditIcon,   
} from "@chakra-ui/icons";
import "./Notes.scss";
import { useState } from "react";

function VendorNotes() {
    const { partnerId } = useParams();    
    const [vendorNoteId, setVendorNoteId] = useState('');
    const { error, data, refetch } = useMojoFetch(
        `/api/v1/VendorNotes/all/${partnerId}`,
        "get"
    );
    const { run: runDelete  } = useMojoEffect(
        "/api/v1/VendorNotes/",
        "delete"
    );
    const { run: runUpdate } = useMojoEffect(
        `/api/v1/VendorNotes/${vendorNoteId}`,
        "patch"
    );
    const toast = useToast();
    
    const deleteVendorNote = async (vendorNote) => {
        setVendorNoteId(vendorNote.Id);
        const [body, error] = await runDelete();
        if (error === null) {
            toast({
                title: "Note deleted",
                status: "success",
            });
            await refetch();
        } else {
            toast({
                title: "Failed to delete note",
                status: "error",
            });
        }
    };

    const updateVendorNote = async (
        idx: number,
        note: any,
    ) => {
        if (!data) {
            return;
        }
        const fullVendorNote = {
            ...data[idx],
            ...note,
        };
        const [updatedData, error] = await runUpdate({
            ...fullVendorNote,
            VendorId: fullVendorNote.VendorId,
            id: fullVendorNote.VendorNoteId,
        });
        setIsEditing(-1);
        if (updatedData) {
            toast({
                title: "Note updated",
                status: "success",
            });
            await refetch();
        } else {
            toast({
                title: "Failed to update note",
                status: "error",
            });
        }
    };

    const [isEditing, setIsEditing] = useState(-1);
    const [editingVendorLinkUrl, setEditingVendorLinkUrl] = useState("");

    return (
        <>
            <AddEditNotesForm vendorId={partnerId || ""} refetch={refetch} />
            <h5>NOTES</h5>
            <div className="presentations-files-list">
                {data?.map((vendorNoteObject, index) => (
                    <Card
                        direction="row"
                        overflow="hidden"
                        variant="elevated"
                        size="sm"
                    >
                        <CardBody>
                            <div className="items-left">
                                <CopyIcon />
                                {isEditing === index ? (
                                    <Input
                                        name="url"
                                        defaultValue={vendorNoteObject.content}
                                        onChange={(e) => {
                                            setEditingVendorLinkUrl(
                                                e.target.value
                                            );
                                        }}
                                    />
                                ) : (
                                    <Text fontSize="xs" lineHeight={2}>
                                        {vendorNoteObject.content}
                                    </Text>
                                )}
                            </div>
                            <div className="icons-right">
                                {/* <ViewIcon /> */}
                                {isEditing === index ? (
                                    <>
                                        <Button
                                            variant="ghost"
                                            colorScheme="green"
                                            onClick={() => {
                                                updateVendorNote(index, {
                                                    VendorId: partnerId,
                                                    content: editingVendorLinkUrl,
                                                });
                                            }}
                                        >
                                            <CheckIcon />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            colorScheme="gray"
                                            onClick={() => setIsEditing(-1)}
                                        >
                                            <CloseIcon />
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <EditIcon
                                            onClick={() => {
                                                isEditing === -1
                                                    ? setIsEditing(index)
                                                    : setIsEditing(-1);
                                            }}
                                        />
                                        <DeleteIcon
                                            onClick={() =>
                                                deleteVendorNote(
                                                    vendorNoteObject
                                                )
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                ))}
            </div>
        </>
    );
}

export default VendorNotes;
