import {
  ChakraStylesConfig,
  Select as ChakraReactSelect,
} from "chakra-react-select";
import { useColorMode } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";

export type Option<T> = {
  value: T;
  label: string;
};

export type SelectProps<T> = {
  onChange: (val: T) => void;
  options: Option<T>[];
  value?: T;
  defaultValue?: T;
  fontSize?: string;
  dropdownButtonBackgroundColor?: string;
  color?: string;
  optionBackgroundColor?: string;
  optionSelectedBackgroundColor?: string;
  placeholder?: string;
  placeholderColor?: string;
};

export function Select<
  U,
  T extends Omit<
    Parameters<typeof ChakraReactSelect>[0],
    "options" | "onChange" | "value" | "defaultValue" | "fontSize" | "placeholder"
  > &
    SelectProps<U>
>(props: T): JSX.Element {
  const { isMulti, options, onChange, value, defaultValue, required, placeholder } = props;
  const [selectedValue, setSelectedValue] = useState(value as U);

  const chakraStyles: ChakraStylesConfig = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: "5px",
      paddingRight: "5px",
      borderLeft: 0,
      background: props.dropdownButtonBackgroundColor,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      height: 0,
    }),
    container: (provided) => {
      return provided;
    },
    option: (provided) => {
      return {
        ...provided,
        backgroundColor: props.optionBackgroundColor,
        _selected: { backgroundColor: props.optionSelectedBackgroundColor },
      };
    },
    singleValue: (provided) => {
      return { ...provided, color: props.color || "black", margin: "0" };
    },
    placeholder: (provided) => ({
      ...provided,
      color: props.placeholderColor || "black.600"
    })

  };

  const handleMultiChange = useCallback(
    (selectedOption) => {
      const sel: U = Array.isArray(selectedOption)
        ? selectedOption.map(({ value }) => value)
        : selectedOption.value;
      onChange(sel);
      setSelectedValue(sel);
    },
    [onChange]
  );

  useEffect(() => {
    setSelectedValue(value as U);
  }, [value]);

  const defaultOption = useMemo(
    () =>
      isMulti
        ? options.filter(({ value }) =>
            ((defaultValue || []) as U[]).includes(value)
          )
        : options.find(({ value }) => value === defaultValue),
    [isMulti, options, defaultValue]
  );

  const selectedOption = useMemo(
    () =>
      isMulti
        ? options.filter(({ value }) =>
            ((selectedValue || []) as U[]).includes(value)
          )
        : options.find(({ value }) => value === selectedValue),
    [isMulti, options, selectedValue]
  );
  const specialOrderofOptions = options.sort((a: Option<U>, b: Option<U>) => {
    if (a.label.toLowerCase() === "all") return -1; // "All" comes first
    if (b.label.toLowerCase() === "all") return 1; // "All" comes first
    if (a.label.toLowerCase() === "other") return 1; // "Other" comes last
    if (b.label.toLowerCase() === "other") return -1; // "Other" comes last
    return a.label.localeCompare(b.label);
  });
  const fontSize = props.fontSize;
  if (fontSize) {
    return (
      <ChakraReactSelect
        {...props}
        className={`select ${props.className}`}
        chakraStyles={chakraStyles}
        onChange={handleMultiChange}
        options={specialOrderofOptions}
        defaultValue={defaultOption}
        value={selectedOption || undefined}
        isMulti={isMulti}
        required={required}
        placeholder={placeholder}
        styles={{
          ...props.styles, // Preserve existing styles
          menuPortal: (base) => ({ ...base, zIndex: 1000 }), // Define menuPortal style if needed
        }}
        menuPortalTarget={document.body}
      />
    );
  }
  return (
    <ChakraReactSelect
      {...props}
      className={`select ${props.className}`}
      chakraStyles={chakraStyles}
      onChange={handleMultiChange}
      options={specialOrderofOptions}
      defaultValue={defaultOption}
      value={selectedOption || undefined}
      isMulti={isMulti}
      required={required}
      placeholder={placeholder}
      styles={{
        ...props.styles, // Preserve existing styles
        menuPortal: (base) => ({ ...base, zIndex: 1000 }), // Define menuPortal style if needed
      }}
      menuPortalTarget={document.body}
    />
  );
}

export function toNumber(anything: unknown): number {
  if (anything == null) {
    return NaN;
  }
  return +anything;
}
export function toNumbers(anything: unknown): number[] {
  if (Array.isArray(anything)) {
    return anything.map((c) => +c);
  }
  return [];
}

export type RegisterSelectOptions<T, O> = {
  transform: (x: unknown) => T;
  options: Option<O>[];
  isMulti: boolean;
  required?: boolean;
  disabled?: boolean;
};

export type UseSelectProps<V> = {
  values?: V;
  setValue: (key: keyof V, value: any) => void;
};

export function useSelect<V>({ values, setValue }: UseSelectProps<V>) {
  return {
    registerSelect<T, O>(
      name: keyof V,
      {
        options,
        transform,
        isMulti,
        required,
        disabled,
        defaultValue,
      }: RegisterSelectOptions<T, O> & { defaultValue?: T }
    ) {
      return {
        name,
        onChange: (val: unknown) => setValue(name, transform(val)),
        options,
        value:
          values && values[name] !== undefined
            ? values[name]
            : defaultValue
            ? defaultValue
            : "",
        isMulti,
        required,
        isDisabled: disabled,
      };
    },
  };
}
