import { useNavigate } from "hooks/useNavigate";
import { Button, Flex, Tag, Text, Heading } from "@chakra-ui/react";
import { components } from "api/typesgen";
import { path } from 'ramda';
import { DataTable } from "components/DataTable/DataTable";
import { EditIcon, DeleteIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { ButtonDanger } from "components/ButtonDanger/ButtonDanger";
import { Column } from "react-table";
import { DataTableSkeleton } from "components/DataTable/Skeleton";
import InputTextFilter from "components/DataTable/Filters/InputTextFilter";
import SelectFilter from "components/DataTable/Filters/SelectFilter";
import './VendorListDataTable.scss';

const CustomDateCell = ({ value }) => {
    const date = new Date(value); 
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.toLocaleDateString('default',{day:'numeric'});
    const displayText = `${day} ${month} ${year}`;
  
    return (
        <div style={{ textAlign: 'center' }}><span>
            {displayText}
        </span></div>        
    );
};

    const RecommendCell = ({ value }) => {
    if (value === '') {
      return <span></span>;
    }
  
    return <Tag>{value}</Tag>;
  };

  const columns: Column<components['schemas']['Vendor']>[] = [    
    {
        id: 'NAME',
        Header: "NAME",
        accessor: "name",
        Cell:({ value }) => <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{value ? value : value}</div>,
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Name'}} />,
        filter: "rankedMatchSorter",
    },
    {
        id: 'VendorType',
        Header: "Partner Type",
        accessor: (row) => row.VendorType.name,
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Partner Type'}} />,
        Cell:({ value }) =>  <div style={{ textAlign: 'center' }}>{value ? value : value}</div>,
        filter: "rankedMatchSorter",
    },
    {
        id: 'Reviewee',
        Header: "Reviewee",
        accessor: (row) => `${row.Reviewee?.firstName ?? ''} ${row.Reviewee?.lastName ?? ''}`,
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Reviewee'}} />,
        filter: "rankedMatchSorter",
    },
    {
        id: 'AMGRecommends',
        Header: "Mojo Recommended",
        accessor: (row) => `${row?.VendorRecommend?.description ?? ''}`,
        Cell:RecommendCell,
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Partner Recommended'}} />,
        filter: "rankedMatchSorter",
    },
    {
        id: 'Presenter',
        Header: "Presenter",
        accessor: "presenter",
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Presenter'}} />,
        filter: "rankedMatchSorter",
    },
    {
        id: 'ReviewDate',
        Header: "Review Date",
        accessor: "reviewDate",
        Cell:CustomDateCell,
        Filter: (props) => <InputTextFilter column={{...props.column, filterPlaceholder: 'Review Date'}} />,
        filter: "rankedMatchSorter",
    }
];


export type VendorListDatatableProps = {
    data?: components['schemas']['Vendor'][];
    error?: any;
    deleteVendor: (id: string) => void;   
}


export function VendorListDataTable({ data,error,deleteVendor}: VendorListDatatableProps){
    const navigate = useNavigate();

    const tableHeaderMarkup = (
        <Flex className="partners-page--header">
        <Flex className="partners-header--left">
            <Heading className="partners-heading">PARTNER LIST</Heading>
        </Flex>
        <Flex className="partners-header--right">
            <Button className="add-partner-btn" onClick={() => navigate('/partners/new')}>
            ADD PARTNER
            </Button>
        </Flex>
        </Flex>  

    );
    const tableMarkup = data ? (
        <DataTable
            data={data}
            columns={columns}
            chooseRows
            showFilter
        >
            {{
                rowFooter: (row: any) => (
                    <Flex gap={1}>
                        <Button variant="mojoPrimaryGhost" onClick={() => data && navigate(`/partners/${row.VendorId}`)}><EditIcon /></Button>
                        <ButtonDanger onClick={() => data && deleteVendor(row?.VendorId)}><DeleteIcon /></ButtonDanger>
                    </Flex>
                )
            }}
        </DataTable>
    ) : <DataTableSkeleton columns={columns}>
            {{ rowFooter: () => <></> }}
        </DataTableSkeleton>;


    return (
        <>
            <div className="datatable-main datatable-controls datatable-client-list">
                {tableHeaderMarkup}
                {tableMarkup}
            </div>
        </>
    );
}