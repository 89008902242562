import { Button, useToast } from "@chakra-ui/react";
import { useAppDispatch } from "store/hooks";
import {
  setTenantGoogleProperty,
  setGoogleIntegrationTenant,
  setTenantGoogleAdsCustomerId,
  setTenantGoogleAdsLinkedCustomerId,
  setGoogleIntegrationType,
  setTenantGoogleIntegrationId,
} from "store/reducers/integrationsSlice";
import IconGoogle from "snippets/icon-google";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const googleLogin = (type: string) => {
  let scopes;
  let client_id;
  if (type === "ads") {
    client_id = process.env.REACT_APP_G_ADS_CLIENT_ID;
    scopes = encodeURIComponent("https://www.googleapis.com/auth/adwords");
  } else if (type === "analytics") {
    client_id = process.env.REACT_APP_G_ANALYTICS_CLIENT_ID;
    scopes = encodeURIComponent(
      "https://www.googleapis.com/auth/analytics.readonly"
    );
  }

  const redirectUri = process.env.REACT_APP_G_REDIRECT_URI;
  const href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${client_id}&redirect_uri=${redirectUri}&scope=${scopes}&response_type=code&access_type=offline`;
  window.location.href = href;
};

const uploadGoogleCreds = async (creds, tenantId, toast) => {
  let res;
  let body = Object.assign({ data: JSON.parse(creds) });
  try {
    res = await fetch(
      process.env.REACT_APP_API_URL +
        `/api/v1/TenantIntegrations/googleservice/${tenantId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      }
    );
    if (res.ok) {
      toast({
        title: "Google Service Account Credentials Updated",
        status: "success",
      });
    }
  } catch (e) {
    console.error(e);
    toast({
      title: "Could Not Update Google Service Account Credentials",
      status: "error",
    });
  }
};

const updateGoogleProps = async (toast, props, type) => {
  const tenantId = window.location.pathname.split("/")[2];
  let body = Object.assign({ data: { ...props } });

  let res;
  try {
    res = await fetch(
      process.env.REACT_APP_API_URL +
        `/api/v1/TenantIntegrations/googleads/${tenantId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      }
    );
    if (res.ok) {
      toast({
        title: "Google Data Updated.",
        status: "success",
      });
    }
    return await res.json();
  } catch (e) {
    console.error(e);
    toast({
      title: "Could Not Update Google Data",
      status: "error",
    });
  }
};

export const updateVautoProps = async (toast, props) => {
  const tenantId = window.location.pathname.split("/")[2];
  let body = Object.assign({ data: { ...props } });

  let res;
  try {
    res = await fetch(
      process.env.REACT_APP_API_URL +
        `/api/v1/TenantIntegrations/vautoinventory/${tenantId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      }
    );
    if (res.ok) {
      toast({
        title: "VAuto Data Updated.",
        status: "success",
      });
    }
    return await res.json();
  } catch (e) {
    console.error(e);
    toast({
      title: "Could Not Update VAuto Data",
      status: "error",
    });
  }
};

export const updateGoogleAnalyticsProps = async (toast, props) => {
  const tenantId = window.location.pathname.split("/")[2];
  let body = Object.assign({ data: { ...props } });

  console.log({
    body,
  });

  let res;
  try {
    res = await fetch(
      process.env.REACT_APP_API_URL +
        `/api/v1/TenantIntegrations/googleanalytics/${tenantId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      }
    );
    if (res.ok) {
      toast({
        title: "VAuto Data Updated.",
        status: "success",
      });
    }
    return await res.json();
  } catch (e) {
    console.error(e);
    toast({
      title: "Could Not Update VAuto Data",
      status: "error",
    });
  }
};

export const getGoogleProps = async (type: string) => {
  let integrationType;
  if (!type) {
    return;
  }
  switch (type) {
    case "Google Analytics API":
      integrationType = "analytics";
      break;
    case "Google Ads API":
      integrationType = "ads";
      break;
    default:
      return;
  }

  const tenantId = window.location.pathname.split("/")[2];
  let res;
  try {
    res = await fetch(
      process.env.REACT_APP_API_URL +
        `/api/v1/TenantIntegrations/google/${tenantId}?type=${encodeURIComponent(
          integrationType
        )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    let json = await res.json();
    return json;
  } catch (e) {
    console.error(e);
  }
};

const removeOauth = async (toast, tenantId, integrationId, type) => {
  let res;
  try {
    res = await fetch(
      process.env.REACT_APP_API_URL + `/api/v1/TenantIntegrations/google`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          tenantId,
          type,
          integrationId,
        }),
      }
    );
    if (res.ok) {
      toast({
        title: "Google Data Updated.",
        status: "success",
      });
    }
    window.location.pathname = `/clients/${tenantId}/integrations`;
  } catch (e) {
    console.error(e);
  }
};

const checkGoogleConnection = async (tenantId, type) => {
  let res;
  try {
    res = await fetch(
      process.env.REACT_APP_API_URL + `/api/v1/oauth/google/checkConnection`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          tenantId,
          type,
        }),
      }
    );
    return await res.json();
  } catch (e) {
    console.error(e);
  }
};
var token = "";
export const GoogleOauth = ({ isLoading, formValues, type, integrationId }) => {
  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();
  const getToken = async () => {
    token = await getAccessTokenSilently();
  };
  getToken();
  const [isGoogleConnected, setIsGoogleConnected] = useState();
  const tenantId = window.location.pathname.split("/")[2];

  useEffect(() => {
    const checkConnection = async () => {
      let isConnected = await checkGoogleConnection(tenantId, type);
      setIsGoogleConnected(isConnected.connected);
    };
    checkConnection();
  }, [tenantId, type]);

  const dispatch = useAppDispatch();

  const { property, customerId, linkedCustomerId } = formValues;
  let isDisabled = !validateForm(formValues, type);

  console.log(isDisabled);
  if (isGoogleConnected) {
    return (
      <Button
        leftIcon={<IconGoogle />}
        onClick={() => removeOauth(toast, tenantId, integrationId, type)}
      >
        {" "}
        Remove
      </Button>
    );
  }

  return (
    <Button
      leftIcon={<IconGoogle />}
      isLoading={isLoading}
      variant="mojoPrimary"
      isDisabled={isDisabled}
      onClick={() => {
        dispatch(setTenantGoogleProperty(property));
        dispatch(setGoogleIntegrationTenant(tenantId));
        dispatch(setTenantGoogleAdsCustomerId(customerId));
        dispatch(setTenantGoogleAdsLinkedCustomerId(linkedCustomerId));
        dispatch(setTenantGoogleIntegrationId(integrationId));
        dispatch(setGoogleIntegrationType(type));
        googleLogin(type);
      }}
    >
      Connect
    </Button>
  );
};

export const GoogleServiceAccountButton = ({
  isLoading,
  formValues,
  integrationId,
  onSubmit,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const getToken = async () => {
    token = await getAccessTokenSilently();
  };

  getToken();
  const toast = useToast();
  const tenantId = window.location.pathname.split("/")[2];
  const { gCredentials } = formValues;
  let credsValid = validateServiceAccountCreds(gCredentials);

  const uploadCredentials = async () => {
    await uploadGoogleCreds(gCredentials, tenantId, toast);
    onSubmit();
  };

  return (
    <Button
      leftIcon={<IconGoogle />}
      isLoading={isLoading}
      variant="mojoPrimary"
      isDisabled={!credsValid}
      onClick={uploadCredentials}
    >
      Upload Credentials
    </Button>
  );
};

const validateForm = (formValues, type) => {
  switch (type) {
    case "analytics":
      const { property } = formValues;
      const isValidProperty = validateProperty(property);
      return isValidProperty;
    case "ads":
      const { customerId, linkedCustomerId } = formValues;
      const isValidCustomerId = validateCustomerId(customerId);
      console.log(linkedCustomerId);
      if (linkedCustomerId) {
        console.log(linkedCustomerId);
        const isValidlinkedCustomerId = validateCustomerId(linkedCustomerId);
        return isValidCustomerId && isValidlinkedCustomerId;
      }

      return isValidCustomerId;
  }
};

export const UpdateGooglePropsButton = ({
  isLoading,
  formValues,
  type,
  integrationId,
  refetch,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const getToken = async () => {
    token = await getAccessTokenSilently();
  };
  getToken();
  const toast = useToast();
  let props;
  const tenantId = window.location.pathname.split("/")[2];
  const dispatch = useAppDispatch();
  const { property, customerId, linkedCustomerId } = formValues;
  let isDisabled = true;
  if (type === "ads") {
    const isValidCustomerId = validateCustomerId(customerId);
    let isValidLinkedCustomerId = true;
    if (linkedCustomerId) {
      isValidLinkedCustomerId = validateCustomerId(linkedCustomerId);
    }

    if (isValidCustomerId && isValidLinkedCustomerId) {
      isDisabled = false;
    }
    props = { customerId, linkedCustomerId };
  } else if (type === "analytics") {
    const isValidProperty = validateProperty(property);
    if (isValidProperty) {
      isDisabled = false;
    }
    props = { property };
  }

  const onSubmit = async () => {
    dispatch(setTenantGoogleProperty(property));
    dispatch(setGoogleIntegrationTenant(tenantId));
    dispatch(setTenantGoogleAdsCustomerId(customerId));
    dispatch(setTenantGoogleIntegrationId(integrationId));
    await updateGoogleProps(toast, props, type);
    await refetch();
  };

  return (
    <Button
      leftIcon={<IconGoogle />}
      isLoading={isLoading}
      variant="mojoPrimary"
      isDisabled={isDisabled}
      onClick={onSubmit}
    >
      Update
    </Button>
  );
};

const validateProperty = (property) => {
  const CORRECT_LENGTH_OF_PROPERTY = 9;
  if (!property || isNaN(property)) {
    return false;
  }
  if (property.toString().length === CORRECT_LENGTH_OF_PROPERTY) {
    return true;
  }

  return false;
};

const validateCustomerId = (property) => {
  const CORRECT_LENGTH_OF_CLIENT_ID = 10;
  if (!property || isNaN(property)) {
    return false;
  }
  if (property.toString().length === CORRECT_LENGTH_OF_CLIENT_ID) {
    return true;
  }

  return false;
};

const validateServiceAccountCreds = (credJson) => {
  try {
    const json = JSON.parse(credJson);
    const isValid =
      json?.type &&
      json?.type !== "" &&
      json?.project_id &&
      json?.project_id !== "" &&
      json?.private_key_id &&
      json?.private_key_id !== "" &&
      json?.private_key &&
      json?.private_key !== "" &&
      json?.client_email &&
      json?.client_email !== "" &&
      json?.client_id &&
      json?.client_id !== "" &&
      json?.auth_uri &&
      json?.auth_uri !== "" &&
      json?.token_uri &&
      json?.token_uri !== "" &&
      json?.auth_provider_x509_cert_url &&
      json?.auth_provider_x509_cert_url !== "" &&
      json?.client_x509_cert_url &&
      json?.client_x509_cert_url !== "" &&
      json?.universe_domain &&
      json?.universe_domain !== "" &&
      json?.property &&
      json?.property !== "";
    if (isValid) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};
