import { useLocation } from 'react-router-dom';
import { Spinner, Center, Box, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { useAppSelector } from "../store/hooks";
import { useAuth0 } from '@auth0/auth0-react';
export const GoogleOAuthCallback = () => {
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState()
  const [error, setError] = useState('')

  const tenantId = useAppSelector(state => {
     return state.integrations.googleIntegrationTenant
  })
  
  const property = useAppSelector(state => {
    return state.integrations.googleProperty
  })
  
  const customerId = useAppSelector(state => {
    return state.integrations.googleAdsCustomerId
  })
  
  const linkedCustomerId = useAppSelector(state => {
    return state.integrations.googleAdsLinkedCustomerId
  })
  
  const type = useAppSelector(state => {
    return state.integrations.type
  })

  const integrationId = useAppSelector(state => {
    return state.integrations.integrationId
  })

  let timeout = 5000
  setTimeout(() => {
    if (error) {
      window.open('https://myaccount.google.com/u/2/connections', '_blank')
    }
    window.location.pathname = `/clients/${tenantId}/integrations`
  }, timeout);
 
    useEffect(() => {
      // Parse query parameters from the URL
      const params = new URLSearchParams(location.search);
      const code = params.get('code'); // Replace 'code' with the parameter name used by the authorization server
  
      // Handle the authorization code (or access token) here
      if (code && tenantId) {
        // Send the code to your backend for further authentication/token exchange
        // You can also perform any necessary cleanup or redirection here
        if (type === 'ads') {
          sendGoogleCode(code, tenantId, integrationId, type, {property, customerId, linkedCustomerId})
        } else if (type === 'analytics') {
          sendGoogleCode(code, tenantId, integrationId, type, {property})
       }
       
      } else {
        // Handle the case where the user denied permission
      }
    }, [location.search]);
   
  const sendGoogleCode = async (code: string, tenantId: string, integrationId: string, type: string, props: any) => { 
      const token = await getAccessTokenSilently();
      const url = `${process.env.REACT_APP_API_URL}/api/v1/oauth/google`
      const requestBody = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', Authorization: 'Bearer ' + token
        },
        body: JSON.stringify({code, tenantId, integrationId, type, props}),
      };
  
      try {
        let res = await fetch(url, requestBody)
        if (!res.ok) {
          setError(`Something went wrong. You may need to remove the integration from your Google Third-Party Apps and try again.`)
        }
      } catch (e) {
        console.error(e)
      }
  }
  
  return (
    <VStack>
      <Center>
      <Box>
        {error ? `${error}` : `Google Account Connected To Tenant ${tenantId}`}
      </Box>
      </Center>
      <Center>
        <Box>
        <Spinner/>
        </Box>
      </Center>
    </VStack>

    );
  }