import { useEffect, useState } from "react";
import {
  Heading,
  Avatar,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Spacer,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "hooks/useNavigate";



function UserMenu() {
  const {
    user,
    isAuthenticated,
    isLoading,
    getIdTokenClaims,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const [claims, setClaims] = useState()

  const navigate = useNavigate()

  const { isOpen, onOpen, onClose } = useDisclosure();

  const bg = useColorModeValue('white.100', '#282828');
  const fontColor = useColorModeValue('gray.800', 'white')

  useEffect(() => {
    const getClaims = async() => {
      const claims = await getIdTokenClaims();
    }
    getClaims()
  }, )

  if (isLoading) {
    return <Heading size="md">Loading ...</Heading>;
  }



  return (
    <>
      {isAuthenticated && user && (
        <Flex
          display={{ base: "none", md: "none", lg: "flex" }}
          align="right"
          flexDirection={"row"}
        >
            <Menu >
              <MenuButton
                as={Button}
                bg={bg}
                // rightIcon={<ChevronDownIcon />}
              >
                <Flex flexDirection={"row"} align="center">
                  <Avatar src={user.picture} boxSize="2.6875rem" mr="0.875rem" />
                  <Spacer />
                  <Text color={fontColor} fontSize={'sm15'} textTransform="capitalize" whiteSpace="nowrap">
                    {user.given_name}
                  </Text>
                </Flex>
              </MenuButton>
              <MenuList minW={300}fontSize={'md'} py={3} >
              <MenuItem onClick={() => navigate(`/userprofile/${user.email}?currentUser=true`)} > User Profile </MenuItem>
                <MenuItem onClick={() => logout()} > Logout </MenuItem>
                </MenuList>
            </Menu>

          <Flex px={1}></Flex>
        </Flex>
      )}
    </>
  );
}

export default UserMenu;
