import { useToast } from "@chakra-ui/react";
import { ContactsDataTable } from "../ContactsDataTable";
import AddEditContactForm from "../AddEditContactForm";
import { useMojoFetch } from "api/useMojoFetch";
import { useParams } from "react-router-dom";
import { useMojoEffect } from "api/useMojoEffect";

function ClientContacts() {
  const { tenantId } = useParams();
  const { error, data, refetch } = useMojoFetch(`/api/v1/contacts/all/${tenantId}`, 'get');
  const { runWithId: runDelete, } = useMojoEffect(`/api/v1/contacts/`, 'delete');
  const { runWithId: runUpdate } = useMojoEffect(`/api/v1/contacts/`, 'put');
  const toast = useToast();

  const deleteContact = async (contact) => {
    const [body, error] = await runDelete(contact.TenantContactId)
    if (error === null) {
      toast({
          title: 'Contact deleted',
          status: 'success',
      });
      await refetch();
    } else {
      toast({
          title: 'Failed to delete contact',
          status: 'error',
      })
    }
  }

  const updateContact = async (idx: number, contact) => {
    if (!data) {
      return;
    }
    const fullContact = {
      ...data[idx],
      ...contact,
    }
    const [body, error] = await runUpdate(fullContact.TenantContactId, fullContact);
    if (error === null) {
      toast({
          title: 'Contact updated',
          status: 'success',
      });
      await refetch();
    } else {
      toast({
          title: 'Failed to update contact',
          status: 'error',
      })
    }
  }

  return (
    <>
      <AddEditContactForm
        tenantId={tenantId || ''}
        refetch={refetch}
      />
      <ContactsDataTable
        data={data}
        error={error}
        deleteContact={deleteContact}
        updateContact={updateContact}
      />
    </>
  );
}

export default ClientContacts;
