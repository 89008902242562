import { Input, useColorModeValue } from "@chakra-ui/react";

export default function InputTextFilter({
        column: { filterValue, preFilteredRows, setFilter, filterPlaceholder },
}) {
    const color = useColorModeValue('black.700', 'white.100')
    const bg = useColorModeValue('white.100', 'black.100')
    return (
        <Input
            type="text"
            size="sm"
            bg={bg}
            color={color}
            borderRadius='md'
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            width={'200px'}
            maxWidth={"100%"}
            placeholder={filterPlaceholder || `Search...`}
        />
    );
}
