import {
  FormLabel,
  FormControl,
  Input,
  Box,
  Switch,
  Flex,
  Button,  
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { CloseIcon, EditIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useMojoEffect } from 'api/useMojoEffect';

export default function UserForm2(props) {
  let { name } = useParams();

  const [activeFlag, setActiveFlag] = useState(true);
  const [blockFlag, setBlockFlag] = useState(false);

  const { run: runUpdate } = useMojoEffect(
    `/api/v1/users/simple/${props.user.UserId}`,
    'PUT'
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ values: {
    firstName: props.user.firstName,
    lastName: props.user.lastName,
    phone: props.user.phone,
    email: props.user.email,
  }})

  useEffect(() => {
    setActiveFlag(props.user.activeFlag);
    setBlockFlag(props.user.blockFlag);
  },[props.user.activeFlag, props.user.blockFlag])

  async function submit(data) {
   
    const result = {
      ...data,
      activeFlag,
      blockFlag,
      entity: name,
      tenantIds: [],
      permissionIds: [],
    };
    await runUpdate(result);
    await props.reset();
      
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <FormControl display='flex' p={2}>
        <FormLabel w='10rem'>USER DETAILS</FormLabel>
        <FormLabel>Active?</FormLabel>
        <Switch
          id='active'
          size='md'
          isChecked={activeFlag}
          onChange={(e) => setActiveFlag(e.target.checked) }
        />
        <FormLabel ml='2rem'>Blocked?</FormLabel>
        <Switch
          id='block'
          size='md'
          isChecked={blockFlag}
          onChange={(e) => setBlockFlag(e.target.checked) }
        />
      </FormControl>
      <Box p={2}>
        <Flex>
          <FormControl variant='floating' isRequired mr='20px'>
            <Input
              id='firstname'
              type='text'
              w='21.5rem'
              size='xs'
              height='45px'
              borderRadius='5'
              {...register('firstName')}
            />
            <FormLabel style={{ paddingRight: '8px' }}>First Name</FormLabel>
          </FormControl>

          <FormControl variant='floating' isRequired mr='20px'>
            <Input
              id='lastname'
              type='text'
              w='21.5rem'
              size='xs'
              height='45px'
              borderRadius='5'
              {...register('lastName')}
            />
            <FormLabel style={{ paddingRight: '8px' }}>Last Name</FormLabel>
          </FormControl>

          <FormControl variant='floating' isRequired mr='20px'>
            <Input
              type='email'
              w='21.5rem'
              size='xs'
              height='45px'
              borderRadius='5'
              {...register('email')}
            />
            <FormLabel style={{ paddingRight: '8px' }}>Email</FormLabel>
          </FormControl>

          <FormControl variant='floating' isRequired>
            <Input
              type='text'
              w='21.5rem'
              size='xs'
              height='45px'
              borderRadius='5'
              {...register('phone')}
            />
            <FormLabel style={{ paddingRight: '8px' }}>Phone</FormLabel>
          </FormControl>
          <FormControl>
            <Button ml='30px' variant='mojoPrimary' onClick={(e) => props.clear()}>
              <CloseIcon />
            </Button>
          </FormControl>

          <FormControl>
            <Flex justify='left'>
              <Button
                leftIcon = {<EditIcon />}
                type='submit'
                isDisabled={false}
                variant='mojoPrimary'
                size='sm'
                loadingText='Submitting'
              >
                UPDATE USER
              </Button>
            </Flex>
          </FormControl>
        </Flex>
      </Box>
    </form>
  );
}
