import { useCallback, useState } from "react";
import { Switch } from "@chakra-ui/react";

export const EditableSwitchCell = ({
    value,
    row: { index: rowIdx },
    column: { id: cellKey, accessor, ...column },
    updateRowData,
  }) => {
    const [isChecked, setIsChecked] = useState(!!value);
    const onChange = useCallback((checked: boolean) => {
        setIsChecked(checked);
        if (updateRowData) {
            updateRowData(rowIdx, cellKey, checked);
        }
    }, [rowIdx, cellKey, updateRowData, setIsChecked]);
    return (
        <Switch isChecked={isChecked} onChange={e => onChange(e.target?.checked)} />
    );
}
