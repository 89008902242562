import { Box, Center, Flex, Image, Heading } from '@chakra-ui/react';
import { useMojoFetch } from 'api/useMojoFetch';
import './GroupsList.scss';
import AddEditGroupForm from '../AddEditGroupForm/AddEditGroupForm';
import GroupsTable from '../GroupsTable/GroupsTable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'hooks/useNavigate';
import logo from '../../../logo-teal.png';
import { connect } from 'react-redux';
import { useMojoEffect } from 'api/useMojoEffect';
import { ProgressTypes } from 'antd/es/progress/progress';
import { useAppDispatch } from 'store/hooks';

function GroupsList(props) {
  const dispatch = useAppDispatch();
  const [fetchError, setError] = useState(null as any);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const { runWithId:getGroups } = useMojoEffect(`/api/v1/groups/`, 'get');
  const navigate = useNavigate();

  const [group, setGroup] = useState({
    id: '',
    name: '',
    agency: { name: '', id: '', providers: [] },
  });
  const [key, setKey] = useState(1);

   useEffect(() => {
     const fetch = async () => {
       setLoading(true);
       const [groups, error] = await getGroups(props.groupId);
       if (error === null) {
         if (props.groupId === undefined || props.groupId === null || props.groupId === 'All' || props.groupId === '') {
           setData(groups);
         }
         else {
           setData([groups]);
         }
       } else {
         setError(error);
       }
       setLoading(false);
     };
     fetch();
   }, [props.groupId]);

  const editGroup = (groupId: string) => {
    const group = data.find((d) => d.id === groupId);    
    setGroup(group);
    setKey(key + 1);
  };

  const myRefetch = async () => {
    setGroup({ id: '', name: '', agency: { name: '', id: '', providers: [] } });
    setLoading(true);
    const [groups, error] = await getGroups(props.groupId);
    if (error === null) {
       if (
         props.groupId === undefined ||
         props.groupId === null ||
         props.groupId === 'All' ||
         props.groupId === ''
       ) {
         setData(groups);
       } else {
         setData([groups]);
       }
    } else {
      setError(error);
    }
    setLoading(false);
  };

  const reset = () => {
    setGroup({ id: '', name: '', agency: { name: '', id: '', providers: [] } });
  };

  const addEditUsers = (id: string) => {
    const group = data.find((d) => d.id === id);
    navigate(`/users/group/${group.name}/${id}`);
  };

  if (isLoading) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }

  if (fetchError !== null) {
    return <Center>{fetchError.message}</Center>;
  }

  return (
    <>
      <Flex className='groups-page--header'>
        <Flex className='groups-header--left'>
          <Heading className='groups-heading'>GROUPS LIST</Heading>
        </Flex>
      </Flex>
      <AddEditGroupForm
        key={key}
        group={group}
        refetch={myRefetch}
        reset={reset}
      />

      <Box className='group-list' w='150%'>
        <GroupsTable
          data={data}          
          editGroup={editGroup}
          addEditUsers={addEditUsers}
        />
      </Box>
    </>
  );
}

const select = (state) => {
  return { groupId: state.app.selectedGroupId };
};
export default connect(select)(GroupsList);
