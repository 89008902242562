import { useState } from "react";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel } from "@chakra-ui/react";
import { RiArrowDownDoubleLine, RiArrowRightDoubleLine } from "react-icons/ri";
import "./AccordionComponent.scss";

interface AccordionComponentProps {
    title: string;
    children: React.ReactNode;
}

function AccordionComponent({ title, children }: AccordionComponentProps) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    }

    return (
        <Accordion className="accordion-component" allowToggle>
            <AccordionItem className="accordion-item">
                <AccordionButton className="accordion-button" onClick={toggleAccordion}>
                    {title}
                    {isOpen ? <RiArrowRightDoubleLine className="accordion-button--icon" /> : <RiArrowDownDoubleLine className="accordion-button--icon" />}
                </AccordionButton>
                <AccordionPanel className="accordion-panel">
                    {children}
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
}

export default AccordionComponent;