import {
  DeleteIcon,
  EditIcon,
} from '@chakra-ui/icons';
import { Button, Flex, Tag, useToast, Heading } from '@chakra-ui/react';
import { components } from 'api/typesgen';
import { useMojoEffect } from 'api/useMojoEffect';
import { ButtonDanger } from 'components/ButtonDanger/ButtonDanger';
import { DataTable } from 'components/DataTable/DataTable';
import InputTextFilter from 'components/DataTable/Filters/InputTextFilter';
import SelectFilter from 'components/DataTable/Filters/SelectFilter';
import { DataTableSkeleton } from 'components/DataTable/Skeleton';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Column } from 'react-table';
import './UserListDataTable.scss';


const columns: (Column<
  components['schemas']['UserDto'] & {
    company: string;
    client: string;
    groupAccess: boolean;
    last_login: string;
  }
> & {
  disabled?: boolean;
  label?: (contact: components['schemas']['UserDto']) => string;
})[] = [
  {
    Header: 'FIRST NAME',
    accessor: 'firstName',
  },
  {
    Header: 'LAST NAME',
    accessor: 'lastName',
  },
  {
    Header: 'EMAIL',
    accessor: 'email',
    Filter: (props) => (
      <InputTextFilter
        column={{ ...props.column, filterPlaceholder: 'Search by email' }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    Header: 'PHONE',
    accessor: 'phone',
  },
  {
    Header: 'ROLE',
    accessor: 'userRole',
  },
  {
    Header: 'Type',
    accessor: 'userType',
  },
  {
    Header: 'COMPANY',
    accessor: 'company',
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Company/Tenant',
          className: 'fix-select-2x-larger',
        }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  /*
	{
		Header: "CLIENT",
		accessor: "client",
	},
	
	{
		Header: "GROUP ACCESS",
		accessor: "groupAccess",
		Cell: ({value}) => (
			<Flex justifyContent={"center"}>
				{value ? (
					<CheckIcon color={"green.400"} />
				) : (
					<CloseIcon color={"red.400"} />
				)}
			</Flex>
		),
		Filter: (props) => (
			<SelectFilter column={{...props.column, filterPlaceholder: "Group", className:"fix-select-2x-larger"}} />
		),
		filter: "rankedMatchSorter",
	},
	*/
  {
    Header: 'STATUS',
    accessor: 'activeFlag',
    Cell: ({ row, data }) => {
      if (data[row.index].blockFlag) {
        return (
          <Tag
            style={{ fontSize: 'var(--chakra-fontSizes-2xs)' }}
            variant={'solid'}
            colorScheme='red'
          >
            Blocked
          </Tag>
        );
      } else if (data[row.index].activeFlag) {
        return (
          <Tag
            style={{ fontSize: 'var(--chakra-fontSizes-2xs)' }}
            variant={'solid'}
            colorScheme='green'
          >
            Active
          </Tag>
        );
      } else {
        return (
          <Tag
            style={{ fontSize: 'var(--chakra-fontSizes-2xs)' }}
            variant={'solid'}
            colorScheme='yellow'
          >
            Inactive
          </Tag>
        );
      }
    },
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Status',
          longerWidth: 200,
          className: 'fix-select-2x-larger',
        }}
        valueMap={{ true: 'Active', false: 'Inactive' }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    Header: 'LAST LOGIN',
    accessor: 'last_login',
  },
];

export default function UserListDataTable(props) {
  const navigate = useNavigate();
  const toast = useToast();
  const [tableData, changeTable] = useState([...props.data]);
  const { runWithId: deleteTheUser } = useMojoEffect('/api/v1/users/', 'delete');

  useEffect(() => {
    changeTable([...props.data]);
  }, [props.data]);

  async function deleteUser(userId: string) {
    const [body, error] = await deleteTheUser(userId);
    if (error === null) {
      toast({
        title: 'User deleted',
        status: 'success',
      });
    } else {
      toast({
        title: error.message,
        status: 'error',
      });
    }
    if (error === null) {
      var tableData2: any = [];
      tableData.forEach((f) => {
        if (f.UserId !== userId) {
          tableData2.push(f);
        }
      });
      changeTable(tableData2);
    }
  }

  return (
    <div className='datatable-main datatable-controls'>
    <Flex className="users-page--header">
      <Flex className="users-header--left">
          <Heading className="users-heading">USER LIST</Heading>
      </Flex>
        {<Flex className="users-header--right">
        <Button className="add-user-with-clients-btn" onClick={() => navigate('/users/new')}>
          ADD USER
        </Button>
      </Flex>}
    </Flex>    

      {props.data ? (
        <DataTable data={tableData} columns={columns} showFilter chooseRows>
          {{
            rowFooter: (row: any) => (
              <Flex gap={1}>           
                <Button variant='mojoPrimaryGhost' onClick={() => navigate(`/users/modify/${row.UserId}`)}>
                  <EditIcon                    
                  />
                </Button>
                <ButtonDanger onClick={() => deleteUser(row.UserId)}>
                  <DeleteIcon />
                </ButtonDanger>    
                {/*<IconButton
                  mt='8px'
                  colorScheme='green'
                  size='xs'
                  aria-label='add users'
                  icon={<AttachmentIcon />}
                  onClick={() => navigate(`/users/modify/${row.UserId}`)}
                /> */}          
              </Flex>
            ),
          }}
        </DataTable>
      ) : (
        <DataTableSkeleton columns={columns}>
          {{ rowFooter: () => <></> }}
        </DataTableSkeleton>
      )}
    </div>
  );
}
