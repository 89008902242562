import { useNavigate as useReactRouterNavigate } from "react-router-dom";

export function useNavigate() {
  const navigate = useReactRouterNavigate();

  return (newRoute: string) => {
      // @ts-ignore
      if ('startViewTransition' in document) {
        // @ts-ignore
        return document.startViewTransition(() => {
          navigate(newRoute);
        });
      } else {
          return navigate(newRoute);
      }
    };
}