import { useAuth0 } from '@auth0/auth0-react';
import { AddIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  useToast,
  Input,
  Flex,
  FormLabel,
} from '@chakra-ui/react';
import { useMojoEffect } from 'api/useMojoEffect';
import { useMojoFetch } from 'api/useMojoFetch';
import { Select } from 'chakra-react-select';
//import Select from 'react-select';

import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setGroups } from 'store/reducers/appSlice';
import { store } from 'store/store';
import { resourceLimits } from 'worker_threads';

export default function AddEditGroupForm(props) {
  const labelProps = { transform: 'scale(0.85) translateY(-24px)' };
  const { getAccessTokenSilently } = useAuth0();
  const { register, handleSubmit, reset } = useForm();
  const [agencies, setAgencies] = useState([] as any);
  const [selAgency, setAgency] = useState({} as any);
  type agType = {
    value: string;
    label: string;
  };
  const [defaultAgencyValue, setAgencyDefault] = useState<agType | null>(null);
  const [defaultProviderValues, setProviderDefaults] = useState([] as any);
  const { data: organizations, error } = useMojoFetch(
    '/api/v1/organizations/',
    'get'
  );
  const { run: runCreate } = useMojoEffect('/api/v1/groups/', 'post');
  const { run: runUpdate } = useMojoEffect(
    `/api/v1/groups/${props.group.id}`,
    'put'
  );
  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProviders = async () => {
      if (error.message !== '') {
        toast({
          title: error.message,
          status: 'error',
        });
      } else {
        var defaults = [] as any;
        props.group?.agency?.providers.forEach((p) => {
          defaults.push({ label: p.name, value: p.Id });
        });
        setAgency(props.group?.agency);
        const myAgencies = [] as any;
        defaults.forEach((k) => {
          const provider = organizations.find((x) => x.id === k.value);
          provider?.agencies.forEach((a) => {
            if (!myAgencies.some((x) => x.value === a.id)) {
              myAgencies.push({ label: a.name, value: a.id });
            }
          });
        });
        setProviderDefaults(defaults);

        if (props.group.agency !== undefined || props.group.agency.id !== '') {
          setAgencyDefault({
            value: props.group.agency.id,
            label: props.group.agency?.name,
          });
        }

        setAgencies(myAgencies);
      }
    };
    fetchProviders();
  }, [error.message, organizations, props, toast]);

  const handleProviderChange = (e, myProviders) => {
    const myAgencies = [] as any;
    let agencyExists = false;
    e.forEach((k) => {
      const provider = myProviders.find((x) => x.id === k.value);
      provider?.agencies.forEach((a) => {
        if (!myAgencies.some((x) => x.value === a.id)) {
          myAgencies.push({ label: a.name, value: a.id });
          if (
            defaultAgencyValue !== null &&
            defaultAgencyValue.value === a.id
          ) {
            agencyExists = true;
          }
        }
      });
    });
    setProviderDefaults(e);
    setAgencies(myAgencies);
    if (!agencyExists) {
      setAgencyDefault(null);
    }
  };

  function handleAgencyChange(e) {
    setAgencyDefault(e);
    setAgency({ id: e.value, name: e.label });
  }

  const myReset = () => {
    reset({ name: '' });
    setProviderDefaults(null);
    setAgencyDefault(null);
    setAgency(props.group?.agency);
    props.reset();
  };

  async function submitForm(data) {
    if (selAgency.id === '') {
      toast({
        title: 'No Agency',
        status: 'error',
      });
      return;
    }
    var groupDto = {
      ...data,
      agency: selAgency,
    };

    if (props.group.id === '') {
      const [body, error] = await runCreate(groupDto);
      if (error === null) {
        myReset();
        await props.refetch();
        dispatch(setGroups(store.getState().app.Groups.concat({ id: body.Id, ...groupDto })));
        toast({
          title: 'Group created',
          status: 'success',
        });
      } else {
        toast({
          title: 'Failed to create group',
          status: 'error',
        });
      }
    } else {
      const [body, error] = await runUpdate(groupDto);
      if (error === null) {
        myReset();     
        dispatch(setGroups(store
          .getState()
          .app.Groups.filter((g) => g.id !== props.group.id)
          .concat({ id: props.group.id, ...groupDto })));
        await props.refetch();
        toast({
          title: 'Group updated',
          status: 'success',
        });
      } else {
        toast({
          title: 'Failed to update group',
          status: 'error',
        });
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Box className='form-upper-wrapper' mt='50px'>
        <Box className='form-upper form'>
          <Box className='form-upper-title'>ADD / EDIT GROUP</Box>
        </Box>

        <Flex w='90%'>
          <FormControl variant='floating' isRequired size='md'>
            <Input
              width='300pt'
              mb='30px'
              {...register('name')}
              defaultValue={props.group.name}
            />
            <FormLabel style={{ paddingRight: '8px' }}>Name</FormLabel>
          </FormControl>
          <FormControl ml='4em' size='lg' variant='floating' isRequired>
            <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
              Providers
            </FormLabel>
            <Select
              size='sm'
              //key={key}
              value={defaultProviderValues}
              options={organizations?.map((o) => {
                return { value: o.id, label: o.name };
              })}
              onChange={async (e) => {
                await handleProviderChange(e, organizations);
              }}
              isMulti
            />
          </FormControl>
          <FormControl ml='4em' variant='floating' isRequired>
            <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
              Agency
            </FormLabel>
            <Select
              value={defaultAgencyValue}
              size='sm'
              //key={key + 1}
              options={agencies}
              onChange={(e) => {
                handleAgencyChange(e);
              }}
            />
          </FormControl>
          <Button variant='mojoPrimary' type='submit'>
            {props.group.id === '' ? <AddIcon /> : <EditIcon />}
          </Button>
          <Button variant='mojoPrimary' onClick={(e) => myReset()}>
            <CloseIcon />
          </Button>
        </Flex>
      </Box>
    </form>
  );
}
