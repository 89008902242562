import { Select } from 'antd';
import { useMojoFetch } from 'api/useMojoFetch';

export default function GenericSelect(props) {
  const { data, isLoading: dLoading } = useMojoFetch(
    props.url,
    'get'
  );
  let v = data.find((v) => v.name === props.value);
  if (v === undefined) {
    v = data.find((v) => v.id === props.value);
  }
  const defaultValue = v !== undefined ? v.id : null;
  return (
    <Select
      placeholder='Select...'
      value={defaultValue}
      onChange={props.onChange}
    >
      {data.map((v) => (
        <option key={v.id} value={v.id}>
          {v.name}
        </option>
      ))}
    </Select>
  );
}

/*
console.log(v);
        inputNode = (
          <Select value={v.VendorId} onChange={handlePartnerChange}>
            {vendors.map((v) => (
              <option key={v.VendorId} value={v.VendorId}>
                {v.name}
              </option>
            ))}
          </Select>
        );
*/
