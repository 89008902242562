import {
  FormLabel,
  FormControl,
  Input,
  Box,
  Switch,
  FormErrorMessage,
  Flex,
  Button,
  useToast,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { AddIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { Select } from 'chakra-react-select';
import { useMojoEffect } from 'api/useMojoEffect';

export default function UserFormCommon(props) {
  type role_type = {
    value: string,
    label:string
  }
  const labelProps = { transform: 'scale(0.85) translateY(-24px)' };
  const [activeFlag, setActiveFlag] = useState(true);
  const [blockFlag, setBlockFlag] = useState(false);
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [defaultRoleValue, setRoleDefaults] = useState<role_type | null>(null);
  const [user, setUser] = useState({ UserId: '' } as any);

  const { run: runCreate } = useMojoEffect(`/api/v1/users`, 'POST');
  const { run: runUpdate } = useMojoEffect(
    `/api/v1/users/${user.UserId}`,
    'PUT'
  );
  const [isLoading, setLoading] = useState(false);
  const initialData = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    userRole: '',
    password: '',
    passwordCopy: '',
  };
  const [values, setData] = useState(initialData);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ values });

  useEffect(() => {
    if (props.user !== undefined) {
      var role;
      if (
        props.user.roles !== undefined &&
        props.user.roles[props.entity.type] !== undefined
      ) {
        role = props.user.roles[props.entity.type].find(
          (x) => x.entity === props.entity.name
        );
      }
      setActiveFlag(props.user.activeFlag);
      setBlockFlag(props.user.blockFlag);
      setData({
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        phone: props.user.phone,
        //blockFlag: props.user.blockFlag,
        //activeFlag: props.user.activeFlag,
        email: props.user.email,
        userRole: role === undefined ? '' : role.name,
        password: '',
        passwordCopy: '',
      });

      if (role !== undefined) {
        setRoleDefaults({
          value: role.role_id,
          label: role.name,
        });
      }

      setUser(props.user);
    }
  }, [props.entity.name, props.entity.type, props.user]);

  const handlePass1Change = (event) => {
    setPass1(event.target.value);
  };

  const handlePass2Change = (event) => {
    setPass2(event.target.value);
  };

  const handleRoleChange = (e) => {
    setRoleDefaults(e);
  };

  const myReset = () => {
    reset(initialData);
    setActiveFlag(true);
    setBlockFlag(false);
    setRoleDefaults({} as any);
    setPass1('');
    setPass2('');
    setUser({ UserId: '' });
  };

  const isError = pass1 !== pass2;
  const toast = useToast();

  async function submit(data) {
    setLoading(true);
    const { userRole, passwordCopy, ...finalData } = data;

    const result = {
      ...finalData,
      activeFlag,
      blockFlag,
      roles: { Organizations: [], Agencies: [], Groups: [], Clients: [] },
      tenantIds: [],
      permissionIds: [],
    };

    if (defaultRoleValue !== null) {
      result.roles[props.entity.type] = [
        {
          role_id: defaultRoleValue.value,
          name: defaultRoleValue.label,
          entity_id: props.entity.id,
          entity: props.entity.name,
        },
      ];
    }
    if (user.UserId === '') {
      const [body, error] = await runCreate(result);
      if (error=== null) {
        toast({
          title: 'User created',
          status: 'success',
        });
        myReset();
        await props.refetch();
      } else {
        toast({
          title: error.message,
          status: 'error',
        });
      }
    } else {
      const [body, error] = await runUpdate(result);
      if (error === null) {
        toast({
          title: 'User updated',
          status: 'success',
        });
        myReset();
        await props.refetch();
      } else {
        toast({
          title: error.message,
          status: 'error',
        });
      }
    }
    setLoading(false);
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <FormControl display='flex' p={2}>
        <FormLabel w='10rem'>USER DETAILS</FormLabel>
        <FormLabel>Active?</FormLabel>
        <Switch
          id='active'
          size='md'
          isChecked={activeFlag}
          onChange={(e) => {
            setActiveFlag(e.target.checked);
          }}
        />
        <FormLabel ml='2rem'>Blocked?</FormLabel>
        <Switch
          id='block'
          size='md'
          isChecked={blockFlag}
          //{...register('blockFlag')}
          onChange={(e) => {
            setBlockFlag(e.target.checked);
          }}
        />
      </FormControl>
      <Box w='1870px' p={2}>
        <Flex>
          <FormControl variant='floating' isRequired>
            <Input
              id='firstname'
              type='text'
              w='21.5rem'
              size='xs'
              height='45px'
              borderRadius='5'
              {...register('firstName')}
            />
            <FormLabel style={{ paddingRight: '8px' }}>First Name</FormLabel>
          </FormControl>

          <FormControl variant='floating' isRequired>
            <Input
              id='lastname'
              type='text'
              w='21.5rem'
              size='xs'
              height='45px'
              borderRadius='5'
              {...register('lastName')}
            />
            <FormLabel style={{ paddingRight: '8px' }}>Last Name</FormLabel>
          </FormControl>

          <FormControl variant='floating' isRequired>
            <Input
              type='email'
              w='21.5rem'
              size='xs'
              height='45px'
              borderRadius='5'
              {...register('email')}
            />
            <FormLabel style={{ paddingRight: '8px' }}>Email</FormLabel>
          </FormControl>

          <FormControl variant='floating' isRequired>
            <Input
              type='text'
              w='21.5rem'
              size='xs'
              height='45px'
              borderRadius='5'
              {...register('phone')}
            />
            <FormLabel style={{ paddingRight: '8px' }}>Phone</FormLabel>
          </FormControl>
          <FormControl>
            <Button ml='30px' variant='mojoPrimary' onClick={(e) => myReset()}>
              <CloseIcon />
            </Button>
          </FormControl>
        </Flex>
      </Box>
      <Box w='1900px' p={2} mb='100px'>
        <Flex>
          <FormControl variant='floating' isRequired>
            <Input
              type='text'
              w='21.5rem'
              size='xs'
              height='45px'
              borderRadius='5'
              isReadOnly={true}
              defaultValue={props.entity.name}
            />
            <FormLabel style={{ paddingRight: '8px' }}>
              {props.entity.type}
            </FormLabel>
          </FormControl>
          <FormControl variant='floating' mr='25px' w='94%' isRequired>
            <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
              Role
            </FormLabel>
            <Select                 
              classNamePrefix='chakra-react-select'
              value={defaultRoleValue}           
              size='sm'
              {...register('userRole')}
              onChange={(e) => handleRoleChange(e)}
              options={props.roles.map((r) => {
                return { value: r.Id, label: r.name };
              })}
            />
          </FormControl>
          <FormControl
            variant='floating'
            isRequired
            isInvalid={isError}
            onChange={handlePass1Change}
          >
            <Input
              value={pass1}
              id='pass1'
              type='password'
              w='21.5rem'
              size='xs'
              height='45px'
              borderRadius='5'
              {...register('password')}
              isDisabled={user.UserId !== ''}
            />
            <FormLabel style={{ paddingRight: '8px' }}>Password</FormLabel>
            {isError && (
              <FormErrorMessage>Passwords do not match.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            variant='floating'
            isRequired
            onChange={handlePass2Change}
            mr='30px'
          >
            <Input
              value={pass2}
              id='pass2'
              type='password'
              w='21.5rem'
              size='xs'
              height='45px'
              borderRadius='5'
              {...register('passwordCopy')}
              isDisabled={user.UserId !== ''}
            />
            <FormLabel style={{ paddingRight: '8px' }}>
              Re-type Password
            </FormLabel>
          </FormControl>
          <FormControl>
            <Flex justify='left'>
              <Button
                leftIcon={user.UserId === '' ? <AddIcon /> : <EditIcon />}
                type='submit'
                isDisabled={false}
                variant='mojoPrimary'
                size='sm'
                isLoading={isLoading}
              >
                {user.UserId === '' ? 'ADD NEW USER' : 'UPDATE USER'}
              </Button>
            </Flex>
          </FormControl>
        </Flex>
      </Box>
    </form>
  );
}
