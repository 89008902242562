import { AddIcon } from "@chakra-ui/icons";
import { Button, FormControl, useToast, Card, useColorModeValue, FormLabel, Flex, Box } from "@chakra-ui/react";
import { useMojoEffect } from "api/useMojoEffect";
import { FloatingLabelInput } from "components/FloatingLabelInput";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./AddEditLinksForm.scss";

export type AddEditLinksFormProps = {
    vendorId: string;
    refetch: () => void;
};

export function AddEditLinksForm({
    vendorId,
    refetch,
}: AddEditLinksFormProps) {
    const { run: runCreate } = useMojoEffect("/api/v1/VendorLinks", "post");

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm();


    //const values = {} as Parameters<typeof runCreate>[0];

    const toast = useToast();

    useEffect(() => {
        register("VendorLinkId");
    }, [register]);

    const bg = useColorModeValue("white.100", "black.100");
    const color = useColorModeValue("black.700", "white.100");

    return (
        <Card p={18} bg={bg} width="100%">
        <form
                onSubmit={handleSubmit(async (data) => {
                 if (
                   vendorId === undefined ||
                   vendorId === 'new' ||
                   vendorId === '' ||
                   vendorId === null
                 ) {
                   toast({
                     title: 'Add new partner first',
                     status: 'error',
                   });
                   return;
                 }
                const [createdData, error] = await runCreate({
                    ...data,
                    VendorId: vendorId,
                    url: data.url,
                    name: 'VendorLink Name'
                });
                if (error === null) {
                    await refetch();
                    reset();
                    toast({
                        title: "VendorLink created",
                        status: "success",
                    });
                } else {
                    toast({
                        title: "VendorLink creation failed",
                        status: "error",
                    });
                }
                return createdData;
            })}
        >
            <Flex flexDirection="column" width="100%" gap="5px">
            <FormControl isInvalid={!!errors.url}>
                <FormControl className="form-upper form">
                            <FormLabel>ADD NEW LINK</FormLabel>
                </FormControl>
                    <Box width="100%" maxWidth="700px">
                            <FloatingLabelInput
                                placeholder="Url"
                                {...register("url")}
                                width="100%"
                                
                            />
                    
                    </Box>
                        <div className="bt-add">
                        <Flex justify="flex-end">
                            <Button
                                //isLoading={isRunningCreate}
                                variant="mojoPrimary"
                                type="submit"
                            >
                                <AddIcon />
                            </Button>
                        </Flex>
                        </div>
                    
            </FormControl>
            </Flex>
        </form>
        </Card>
    );
}
