import { AddIcon } from "@chakra-ui/icons";
import { 
    Button, 
    FormControl, 
    useToast, 
    Card,
    useColorModeValue,
    Flex,
    FormLabel
 } from "@chakra-ui/react";
import { components } from "api/typesgen";
import { useMojoEffect } from "api/useMojoEffect";
import { useMojoFetch } from "api/useMojoFetch";
import { FloatingLabelInput } from "components/FloatingLabelInput";
import { Select, toNumber, useSelect } from "components/Select";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

export type AddEditVendorFormProps = {
    tenantId: string;
    refetch: () => void;
    clientVendors: components['schemas']['TenantVendor'][];
}

export function AddEditVendorForm({ tenantId, refetch, clientVendors }: AddEditVendorFormProps) {
    const [createdData, setCreatedData] = useState();
    const {
        run: runCreate,
    } = useMojoEffect('/api/v1/TenantVendors/create', 'post');

    const { data: vendorsData, error: vendorsError } = useMojoFetch('/api/v1/Vendors/all', 'get');
    const vendorOptions = useMemo(() =>
        vendorsData ?
        vendorsData.filter(({ VendorId }) => !clientVendors.some((cv) => cv.VendorId === VendorId)).map(({ VendorId, name }) => ({ label: name, value: VendorId })) :
        [],
    [clientVendors, vendorsData]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
      } = useForm();

    //const values = {} as Parameters<typeof createdData>;
    const { registerSelect } = useSelect<NonNullable<components['schemas']['TenantVendor']>>({
        values: createdData,
        setValue,
    });

    const toast = useToast();

    useEffect(() => {
        register('VendorId');
    }, [register]);     

    const bg = useColorModeValue("white.100", "black.100");
    const color = useColorModeValue("black.700", "white.100");

    return (
        <Card p={18} bg={bg} width="100%">
        <form
            onSubmit={handleSubmit(
                async (data) => {
                    const [myCreatedData, error] = await runCreate({
                        ...data,
                        TenantId: tenantId,
                        VendorId: data.VendorId,
                    });
                    if (error === null) {
                        await refetch();
                        reset();
                        toast({
                            title: 'Vendor created',
                            status: 'success',
                        });
                        setCreatedData(myCreatedData);
                    } else {
                        toast({
                            title: 'Vendor creation failed',
                            status: 'error',
                        });
                    }
                    return myCreatedData;
                }
            )}
        >
            <Flex flexDirection="column" width="100%" gap="5px">
                <FormControl isInvalid={!!errors.email}>       
                    <FormLabel>ADD NEW VENDOR</FormLabel>
                        

                        <div className="form">
                            <div className="input-group">
                                <FloatingLabelInput
                                    placeholder="Vendor email"
                                    {...register('email')}
                                />
                            </div>
                            <div className="input-group">
                                <FloatingLabelInput
                                    placeholder="Public Key"
                                    {...register('publicKey')}
                                />
                            </div>
                            <div className="input-group">
                                <FloatingLabelInput
                                    placeholder="URL"
                                    {...register('url')}
                                />
                            </div>
                            <div className="input-group min-width-20">
                                <Select
                                    className="min-width-20"
                                    placeholder="Type"
                                    {...registerSelect('VendorId', {
                                        transform: t => t,
                                        options: vendorOptions,
                                        isMulti: false,
                                        required: true,
                                    })}
                                />
                            </div>                        
                            <div className="bt-add">
                                <Button //isLoading={isRunningCreate} 
                                variant="mojoPrimary" type="submit"><AddIcon /></Button>
                            </div>
                        </div>
                </FormControl>
            </Flex>
        </form>
        </Card>
    );
}