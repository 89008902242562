import { Spinner, useToast } from '@chakra-ui/react';
import { UsersDataTable } from '../UsersDataTable';
import { useParams } from 'react-router-dom';
import { useMojoFetch } from 'api/useMojoFetch';
import { useMojoEffect } from 'api/useMojoEffect';
import { useCallback, useEffect, useState } from 'react';
import AddEditUserForm2 from '../AddEditUserForm/AddEditUserForm2';

function ClientUsers() {
  const { tenantId } = useParams();
  const { error, data, refetch, isLoading } = useMojoFetch(
    `/api/v1/users/tenants/${tenantId}`,
    'get'
  );
  const { runWithId: runDelete } = useMojoEffect(
    `/api/v1/Users/`,
    'delete'
  );
  const { runWithId: runUpdate } = useMojoEffect(
    `/api/v1/Users/`,
    'put'
  );
  const toast = useToast();
  const [myData, setMyData] = useState([]);
  const { data: clientRolesData } = useMojoFetch('/api/v1/Roles/tenant', 'get');

  useEffect(() => {
    
    const result = [] as any;
    data.forEach((d) => {
      const { roles, ...user } = d;
      const final_user = { ...user, TenantRoleId: '', TenantId: '' };
      const node = roles.Clients.find((r) => r.entity_id === tenantId);
      final_user.TenantRoleId = node.role_id;
      final_user.TenantId = tenantId;
      result.push(final_user);
    });
    setMyData(result);
  }, [data, tenantId]);

  const deleteUser = useCallback(
    async (user) => {
      if (!tenantId) {
        return;
      }
      const [deleteResult, error] = await runDelete(user.UserId);
      if (error === null) {
        toast({
          title: 'User deleted',
          status: 'success',
        });
        refetch();
      } else {
        toast({
          title: 'Failed to delete user',
          status: 'error',
        });
      }
    },
    [tenantId, runDelete, toast, refetch]
  );

  const updateUser = async (idx: number, user) => {
    if (!data) {
      return;
    }
    const fullUser = {
      ...data[idx], ...user,
    };
    if (user.TenantRoleId !== undefined) {
      const node = fullUser.roles.Clients.find((x) => x.entity_id === tenantId);
      const role = clientRolesData.find((x) => x.Id === user.TenantRoleId);
      node.role_id = role.Id;
      node.name = role.name;
    }    
    let finalUser = fullUser;
    if (fullUser.TenantRoleId !== undefined) {
        const {TenantRoleId, ...f_user} = fullUser;
        finalUser = f_user;
    }
    const [updateResult, error] = await runUpdate(fullUser.UserId, finalUser);
    if (error === null) {
      toast({
        title: 'User updated',
        status: 'success',
      });
      refetch();
    } else {
      toast({
        title: 'Failed to update user',
        status: 'error',
      });
    }
  };

  return (
    <>
      <AddEditUserForm2
        tenantId={tenantId || ''}
        refetch={refetch}
        rolesData={clientRolesData}
      />
      {isLoading && (<Spinner />)}
      {!isLoading && (
        <UsersDataTable
          data={myData}
          rolesData={clientRolesData}
          error={error}
          deleteUser={deleteUser}
          updateUser={updateUser}
        />)}
    </>
  );
}

export default ClientUsers;
