import { NavLink } from "react-router-dom";
import { List, ListItem, Tooltip, IconButton, Text, HStack, Box } from "@chakra-ui/react";


export function SidenavItems({ navItems, isOpen }) {

    const sidebarItemInSemiMode = (
        { icon: Icon, ...item },
        index: number
    ) => (
        <ListItem className="sidebar-item" key={index}>
            <Tooltip label={item.label} placement="right">
                <HStack className="sidebar-item-stack">
                    <Box className="sidebar-item-inner-wrap">
                        <IconButton
                            p={1}
                            key={index}
                            size={'sm'}
                            as={NavLink}
                            _hover={{ color: 'white.100' }}
                            _focus={{ bg: "blue.100" }}
                            _activeLink={{ color: "mojo.200" }}
                            bg="transparent"
                            aria-label={item.label}
                            borderRadius="xl"
                            icon={<Icon size={'30px'} />}
                            color={'brown.500'}
                            to={item.to}
                        ></IconButton>
                    </Box>

                    {isOpen && <Box><NavLink className="sidebar-item-link" to={item.to}><Text className="sidebar-item-text" size={'lg'} color={'white.100'}>{item.label}</Text></NavLink></Box>}

                </HStack>
            </Tooltip>
        </ListItem>
    )
    return (
        <List className="sidebar-items" h={'100%'}>
            {navItems.map((item, index) => sidebarItemInSemiMode(item, index))}
        </List>
    );
}

export default SidenavItems;