import { DataTableSkeleton } from 'components/DataTable/Skeleton';
import './AgenciesTable.scss';
import { Box, Button, Flex, useToast } from '@chakra-ui/react';
import { DataTable } from 'components/DataTable/DataTable';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { ButtonDanger } from 'components/ButtonDanger/ButtonDanger';
import { useEffect, useState } from 'react';
import SelectFilter from 'components/DataTable/Filters/SelectFilter';
import { useMojoEffect } from 'api/useMojoEffect';

const columns = [
  {
    Header: 'AGENCY',
    accessor: 'name',
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Agency',
          className: 'fix-select-2x-larger',
        }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
  {
    Header: 'ORGANIZATIONS',
    accessor: 'providers',
  },
];

export default function AgenciesTable(props) {
  const toast = useToast();
  const [tableData, changeTable] = useState([] as any);
  const { runWithId: delAgency } = useMojoEffect(`/api/v1/agencies/`, 'delete');

  useEffect(() => {
    processTable(props.data);
  }, [props]);

  const processTable = (data) => {
    if (data !== undefined) {
    var result = [] as any;
    data.forEach((d) => {
      let concatOrgs = '';
      d?.providers.forEach((o) => {
        concatOrgs += o.name + ', ';
      });      
      result.push({
        id: d.id,
        name: d.name,
        providers: concatOrgs.substring(0,concatOrgs.length-2),
      });
    });
    changeTable(result);
  }
  else {
    changeTable([]);
  }
  };

  async function deleteAgency(agencyId: string) {
    const [body, error] = await delAgency(agencyId);

    if (error === null) {
      toast({
        title: 'Agency deleted',
        status: 'success',
      });      
    } else {
      toast({
        title: error.message,
        status: 'error',
      });
    }

    if (error === null) {
      var tableData2: any = [];
      tableData.forEach((f) => {
        if (f.id !== agencyId) {
          tableData2.push(f);
        }
      });
      changeTable(tableData2);
    }
  }

  return (
    <div className='datatable-main datatable-controls'>
      <Box w='60%'>
        {tableData ? (
          <DataTable data={tableData} columns={columns} showFilter chooseRows>
            {{
              rowFooter: (row: any) => (
                <Flex gap={1}>
                  <Button variant='mojoPrimaryGhost' onClick={() => {}}>
                    <EditIcon onClick={() => props.editAgency(row.id)} />
                  </Button>
                  <ButtonDanger onClick={() => deleteAgency(row.id)}>
                    <DeleteIcon />
                  </ButtonDanger>
                  {/*<IconButton
                    mt='8px'
                    colorScheme='blue'
                    size='xs'
                    aria-label='add users'
                    icon={<HiMiniUsers />}
                    onClick={() => props.addEditUsers(row.id)}
                  />*/}
                </Flex>
              ),
            }}
          </DataTable>
        ) : (
          <DataTableSkeleton columns={columns}>
            {{ rowFooter: () => <></> }}
          </DataTableSkeleton>
        )}
      </Box>
    </div>
  );
}
