import {
  Stat,
  StatLabel,
  StatNumber,
  CardBody,
  Card,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  CardHeader,
  Box,
  Flex
} from "@chakra-ui/react";

function NumberStat({ label, number }: { label: string, number: number }) {
  return (
    <Card minWidth={'20ch'}>
      <CardBody>
        <Stat>
          <StatLabel>{label}</StatLabel>
          <StatNumber>{number}</StatNumber>
        </Stat>
      </CardBody>
    </Card>
  )
}

function LastActions({ label, columns, data }: { label: string, columns: string[], data: any[] }) {
  return (
    <Card>
      <CardHeader>{label}</CardHeader>
      <CardBody>
        <TableContainer>
          <Table size='sm'>
            <Thead>
              <Tr>
                {columns.map(column => <Th key={column}>{column}</Th>)}
              </Tr>
            </Thead>
            <Tbody>
              {data.map(item => (
                <Tr key={item.id}>
                  {columns.map(column => (
                    <Td>{item[column]}</Td>
                  ))}
                </Tr>  
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  )
}

export function Home() {
  const data = [
    { id: '012', action: 'Delete user', user: 'AMG Mojo', client: 'Honda Austin',  performed: '1 hour ago' },
    { id: '345', action: 'Change permissions', user: 'AMG Mojo', client: 'Toyota Miami', performed: '1 day ago' },
  ];
  return (
    <Flex alignItems={'center'} direction={'column'} maxWidth={'900px'} padding={'20'} gap={'20'} alignSelf={'center'} >
      <Heading>MOJO home</Heading>
      <Flex gap={'10'} flexFlow={'row wrap'} justifyContent={'space-between'}>
        <NumberStat label="# of clients" number={43} />
        <NumberStat label="# of contacts" number={32} />
        <NumberStat label="# of groups" number={32} />
        <NumberStat label="# of integrations" number={45} />
        <NumberStat label="# of users" number={75} />
        <NumberStat label="# of vendors" number={12} />
      </Flex>
      <Flex gap={'10'}>
        <LastActions label="Last actions" columns={['action', 'user', 'client', 'performed']} data={data} />
      </Flex>
    </Flex>
  )
}