
import { Box, Center, Flex, Image, Text, Heading, HStack, Card, CardHeader, CardBody, Icon, Link, useColorModeValue } from '@chakra-ui/react';
import './AdminPage.scss';
import { NavLink } from "react-router-dom";
import { SlOrganization } from "react-icons/sl";
import { HiOutlineUserGroup } from "react-icons/hi";
import { TbClock } from "react-icons/tb";
import { Divider } from 'antd';
import { LiaHandshake } from "react-icons/lia";
import { BsPeople } from "react-icons/bs";



function AdminPage() {

    // ask about card color, I think the theme color is too dark so keeping shade lighter (abby)
    // const cardBg = useColorModeValue('white.100', '#282828');

    return (
        <Box className='admin-page--wrapper'>
            <Flex className="admin-page--header">
                <Flex className="admin-header--left">
                <Heading className="admin-heading">ADMIN</Heading>
                </Flex>
            </Flex>
            <Flex className="admin-page--body">
                <Flex
                    className="admin-nav--links"
                    direction="column"
                    align="center"
                    justify="flex-start"
                    p={2}
                    mb="15px"
                    as="nav"
                >
                    <NavLink to="/agencies" className="admin-card--link">
                        <Card className="admin-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
                            <CardHeader>
                                <Flex className="card-heading">Agencies</Flex>
                            </CardHeader>
                            <Divider />
                            <CardBody>
                                <Flex justify="center" align="center" height="100%">
                                    <Icon as={TbClock} boxSize={20} />
                                </Flex>
                            </CardBody>
                        </Card>
                    </NavLink>

                    <NavLink to="/groups" className="admin-card--link">
                        <Card className="admin-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
                            <CardHeader>
                                <Flex className="card-heading">Groups</Flex>
                            </CardHeader>
                            <Divider />
                            <CardBody>
                                <Flex justify="center" align="center" height="100%">
                                    <Icon as={HiOutlineUserGroup} boxSize={20} />
                                </Flex>
                            </CardBody>
                        </Card>
                    </NavLink>

                    <NavLink to="/organizations" className="admin-card--link">
                        <Card className="admin-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
                            <CardHeader>
                                <Flex className="card-heading">Organizations</Flex>
                            </CardHeader>
                            <Divider />
                            <CardBody>
                                <Flex justify="center" align="center" height="100%">
                                    <Icon as={SlOrganization} boxSize={20} />
                                </Flex>
                            </CardBody>
                        </Card>
                    </NavLink>

                    <NavLink to="/partners" className="admin-card--link">
                        <Card className="admin-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
                            <CardHeader>
                                <Flex className="card-heading">Partners</Flex>
                            </CardHeader>
                            <Divider />
                            <CardBody>
                                <Flex justify="center" align="center" height="100%">
                                    <Icon as={LiaHandshake} boxSize={20} />
                                </Flex>
                            </CardBody>
                        </Card>
                    </NavLink>

                    <NavLink to="/users" className="admin-card--link">
                        <Card className="admin-card" variant="outline" _hover={{ borderColor: 'mojo.200' }}>
                            <CardHeader>
                                <Flex className="card-heading">Users</Flex>
                            </CardHeader>
                            <Divider />
                            <CardBody>
                                <Flex justify="center" align="center" height="100%">
                                    <Icon as={BsPeople} boxSize={20} />
                                </Flex>
                            </CardBody>
                        </Card>
                    </NavLink>
                </Flex>
            </Flex>
        </Box>
    );
}

export default AdminPage;