import { Card, CardBody, IconButton, Link } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useMojoFetch } from 'api/useMojoFetch';
import './Presentations.scss';
import { CopyIcon, DeleteIcon,} from '@chakra-ui/icons';
import { SimpleUploader } from 'components/SimpleUploader/SimpleUploader';
import { useMojoEffect } from 'api/useMojoEffect';

function VendorPresentations() {
  const { partnerId } = useParams();
  const { error, data, refetch } = useMojoFetch(
    `/api/v1/VendorFiles/all/${partnerId}`,
    'get'
  );  

const { runWithId: deletePresentation } = useMojoEffect(
  `/api/v1/VendorFiles/${partnerId}`,
  'DELETE'
);

  async function removePresentation(filename) {    
    const [result, error] = await deletePresentation(
      `/${filename}`
    );
    await refetch();
  }

  return (
    <>
      <SimpleUploader
        entityId={partnerId}
        maxFiles={4}
        file_type='presentation'
        refetch = {refetch}
      />
      <div></div>
      <h5>FILES</h5>
      <div className='presentations-files-list'>
        {data?.urls?.map((x, i) => (
          <Card direction='row' overflow='hidden' variant='elevated' size='sm'>
            <CardBody>
              <div className='items-left'>
                <CopyIcon />
                <Link href={Object.values(x)[0] as string} isExternal size='sm'>
                  {Object.keys(x)[0]}
                </Link>
              </div>
              <div className='icons-right'>
                <IconButton
                  icon={<DeleteIcon />}
                  aria-label='Delete file'
                  onClick={() => { removePresentation(Object.keys(x)[0]) }}
                />
              </div>
            </CardBody>
          </Card>
        ))}
      </div>
    </>
  );
}

export default VendorPresentations;
