import {PayloadAction, createSlice} from "@reduxjs/toolkit";

interface UserState {
	tenantIDs: string[];
}

const initialState: UserState = {
	tenantIDs: [],
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<UserState>) => {
			state.tenantIDs=action.payload.tenantIDs;
		},
	},
});

export const {setUser} = userSlice.actions;

export default userSlice;
