import { Select } from "antd";
import { useMojoFetch } from "api/useMojoFetch";

export default function PartnerSelect(props) {
      const { data: vendors, isLoading: vLoading } = useMojoFetch(
        '/api/v1/Vendors/all',
        'get'
      );
    let v = vendors.find((v) => v.name === props.value);
    if (v === undefined) {
        v = vendors.find((v) => v.VendorId === props.value);
  }
  const defaultValue = v !== undefined ? v.VendorId : null;
    return (
      <Select placeholder='Select...' value={defaultValue} onChange={props.onChange}>
        {vendors.map((v) => (
          <option key={v.VendorId} value={v.VendorId}>
            {v.name}
          </option>
        ))}
      </Select>
    );
}

/*
console.log(v);
        inputNode = (
          <Select value={v.VendorId} onChange={handlePartnerChange}>
            {vendors.map((v) => (
              <option key={v.VendorId} value={v.VendorId}>
                {v.name}
              </option>
            ))}
          </Select>
        );
*/