import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button, Heading, Box, Flex } from '@chakra-ui/react';
import './GoalsUpload.scss';
import { Uploader } from 'components/Uploader/Uploader';

const file_header = [
  'Location',
  'Type',
  'Dept',
  'Notes',
  'Year',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
  'Average',
  'Total',
];

const columns: any[] = [
  {
    title: 'Location',
    dataIndex: 'Location',
    key: 'location',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Location === null || b.Location === null) return 0;
      return (a.Location ?? '').localeCompare(b.Location ?? '');
    },
  },
  {
    title: 'Type',
    dataIndex: 'Type',
    key: 'type',
    fixed: 'left',
    width: 100,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Type === null || b.Type === null) return 0;
      return (a.Type ?? '').localeCompare(b.Type ?? '');
    },
  },
  {
    title: 'Dept',
    dataIndex: 'Dept',
    key: 'department',
    fixed: 'left',
    width: 100,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Department === null || b.Department === null) return 0;
      return (a.Department ?? '').localeCompare(b.Department ?? '');
    },
  },
  {
    title: 'Notes',
    dataIndex: 'Notes',
    key: 'notes',
    fixed: 'left',
    width: 100,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.notes === null || b.notes === null) return 0;
      return (a.notes ?? '').localeCompare(b.notes ?? '');
    },
  },
  {
    title: 'Year',
    dataIndex: 'Year',
    key: 'year',
    width: 100,
    sorter: (a, b) => {
      if (a.Year === null || b.Year === null) return 0;
      return (a.Year ?? '').localeCompare(b.Year ?? '');
    },
  },
  {
    title: 'Jan',
    dataIndex: 'Jan',
    key: 'january',
    width: 100,
  },
  {
    title: 'Feb',
    dataIndex: 'Feb',
    key: 'february',
    width: 100,
  },
  {
    title: 'Mar',
    dataIndex: 'Mar',
    key: 'march',
    width: 100,
  },
  {
    title: 'Apr',
    dataIndex: 'Apr',
    key: 'april',
    width: 100,
  },
  {
    title: 'May',
    dataIndex: 'May',
    key: 'may',
    width: 100,
  },
  {
    title: 'June',
    dataIndex: 'June',
    key: 'june',
    width: 100,
  },
  {
    title: 'July',
    dataIndex: 'July',
    key: 'july',
    width: 100,
  },
  {
    title: 'Aug',
    dataIndex: 'Aug',
    key: 'august',
    width: 100,
  },
  {
    title: 'Sept',
    dataIndex: 'Sept',
    key: 'september',
    width: 100,
  },
  {
    title: 'Oct',
    dataIndex: 'Oct',
    key: 'october',
    width: 100,
  },
  {
    title: 'Nov',
    dataIndex: 'Nov',
    key: 'november',
    width: 100,
  },
  {
    title: 'Dec',
    dataIndex: 'Dec',
    key: 'december',
    width: 100,
  },
  {
    title: 'Average',
    dataIndex: 'Average',
    key: 'avg',
    width: 100,
  },
  {
    title: 'Total',
    dataIndex: 'Total',
    key: 'total',
    width: 100,
  },
  // {
  //     title: ' ',
  //     key: 'controls',
  //     fixed: 'right',
  //     render: () => <span className="table-controls">Edit</span>
  // }
];

export function GoalsUploadForm({
  doneUploading,
  location_id,
  location_name,
  goalTypes,
  departments,
  setSelectedYear,
}) {
  return (
    <>
      <Box className='goals-upload--form'>
        <Flex className='goals-upload--header'>
          <Button
            className='goals-upload--back-btn'
            leftIcon={<ChevronLeftIcon height={6} width='auto' color='cyan' />}
            variant='mojoDefault'
            onClick={doneUploading}
          >
            GOALS
          </Button>
          <Heading className='goals-upload--heading'>UPLOAD GOAL FILES</Heading>
        </Flex>
        <Uploader
          file_header={file_header}
          columns={columns}
          maxFiles={4}
          file_type={'goals'}
          location_id={location_id}
          location_name={location_name}
          vendors={[]}
          departments={departments}
          channels={[]}
          types={goalTypes}
          media={[]}
          objectives={[]}
          audiences={[]}
          setSelectedYear={setSelectedYear}
        />
      </Box>
    </>
  );
}
