import { useState, useEffect, useRef } from 'react';
import { Box, IconButton, useColorModeValue } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import './DateFilter.scss';

function DateFilter({ startDate, endDate, handleDateChange }) {
    const [localStartDate, setLocalStartDate] = useState(startDate);
    const [localEndDate, setLocalEndDate] = useState(endDate);
    const [isOpen, setIsOpen] = useState(false);
    const datepickerRef = useRef<HTMLDivElement>(null);


    const handleChange = (range) => {
        const [start_date, end_date] = range;
        setLocalStartDate(start_date);
        setLocalEndDate(end_date);
        handleDateChange(range);
    };

    const handleClickDatepickerIcon = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            datepickerRef.current &&
            !datepickerRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const filterBg = useColorModeValue('white', '#282828');

    return (
        <Box className='datepicker-container' ref={datepickerRef} style={{ backgroundColor: filterBg }}>
            <DatePicker
                open={isOpen}
                className='datepicker'
                selected={localStartDate}
                onChange={handleChange}
                startDate={localStartDate}
                endDate={localEndDate}
                selectsRange
                // isClearable
                //placeholderText='10/1/2024 - 12/1/2024' // just for demo, assign default range with functionality
                customInput={
                    <input />
                }
                onInputClick={handleClickDatepickerIcon}
            />
            <IconButton
                className='datepicker-icon'
                icon={<FaCalendarAlt color='var(--color-gray-500)' />}
                onClick={handleClickDatepickerIcon}
                aria-label='Select date'
                variant='no-border'
                h='1.25rem'
                w='1.25rem'
            />
        </Box>
    );
}

export default DateFilter;
