import { Center, Image } from '@chakra-ui/react';
import { useColorModeValue, HStack, Flex, Select, Box } from '@chakra-ui/react';
import logo from 'logo-teal.png';
import UserMenu from 'components/UserMenu';
import NotificationsButton from 'components/NotificationsButton';
import './topbar.scss';
import { useMojoFetch } from 'api/useMojoFetch';
import { useAppDispatch } from 'store/hooks';
import { selectGroupId, setGroups } from 'store/reducers/appSlice';
import { ChangeEvent, useEffect, useState } from 'react';
import { store } from 'store/store';
import { connect } from 'react-redux';
import { useMojoEffect } from 'api/useMojoEffect';

function TopBar(props) {
  const dispatch = useAppDispatch();
  const { run: getGroups } = useMojoEffect('/api/v1/groups/All', 'get');

  useEffect(() => {
    const doAll = async () => {
      const [data, error] = await getGroups();
      dispatch(setGroups(data));
    }
    doAll();
  }, []);

  const [defaultValue, setDefaultValue] = useState(
    store.getState().app.selectedGroupId
  );

  const bg = useColorModeValue('white.100', '#282828');
  const fontColor = useColorModeValue('gray.800', 'white');

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    console.log(event.target.value);
    setDefaultValue(event.target.value);
    dispatch(selectGroupId(event.target.value));
  };

  return (
    <Box className='mojo-top-bar' bg={bg}>
      <Flex className='mojo-top-bar-content'>
        <HStack className='mojo-top-bar-left' spacing={10}>
          <Box className='mojo-logo-wrapper'>
            <Image className='branding-logo' p={2} src={logo} alt='Mojo logo' />
          </Box>
        </HStack>
        <HStack className='mojo-top-bar-right' spacing={10}>
          <Select
            value={defaultValue}
            className='organization-select'
            bg={bg}
            placeholder='Group'
            _placeholder={{ color: fontColor, opacity: 1 }}
            onChange={(e) => handleChange(e)}
          >
            <option key='All' value='All'>
              All
            </option>
            {props.groups.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </Select>
          )
          <Flex className='top-bar-right-actions'>
            {/* <ToggleColorModeButton /> */}
            <NotificationsButton />
            <UserMenu />
          </Flex>
        </HStack>
      </Flex>
    </Box>
  );
}

const select = (state) => {
  return { groups: state.app.Groups ?? [] };
};
export default connect(select)(TopBar);
