import { Box, Center, Flex, Image, useToast } from "@chakra-ui/react";
import { useMojoFetch } from "api/useMojoFetch";
import { useCallback } from "react";
import { VendorListDataTable } from "../VendorsListDataTable";
import './VendorsList.scss';
import { useMojoEffect } from "api/useMojoEffect";
import logo from '../../../logo-teal.png';

export function VendorsList() {
    const { error, data, refetch, isLoading } = useMojoFetch('/api/v1/Vendors/all', 'get');
    const { runWithId:deleteV  } = useMojoEffect('/api/v1/Vendors/', 'delete'); 
    
    const toast = useToast();
    const deleteVendor = useCallback(
      async (vId: string) => {
        const [data, error] = await deleteV(vId);
        if (error === null) {
          toast({
            title: 'Vendor deleted',
            status: 'success',
          });
          await refetch();
        } else {
          toast({
            title: 'Failed to delete vendor',
            status: 'error',
          });
        }
      },
      [refetch, deleteV, toast]
    );
    
      if (isLoading) {
        return (
          <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
            <Flex justify='center' direction='column'>
              <Image src={logo} alt='Mojo Platform' />
              <Box className='loader'></Box>
              <Center>Loading...</Center>
            </Flex>
          </Center>
        );
      }
      
      if (error.message !== '') {         
        return <Center>{error.message}</Center>;
      }

      return (
        <div className="vendor-list">
          <VendorListDataTable
            data={data}
            error={error}
            deleteVendor={deleteVendor}
          />
        </div>
      );    
}