import { Select } from "antd";
import { useMojoFetch } from "api/useMojoFetch";

export default function GoalTypeSelect(props) {
  const { data, isLoading: dLoading } = useMojoFetch(
    '/api/v1/goaltypes/all/',
    'get'
  );
  let v = data.find((v) => v.description === props.value);
  if (v === undefined) {
    v = data.find((v) => v.GoalTypeId === props.value);
  }
  const defaultValue = v !== undefined ? v.GoalTypeId : null;
  return (
    <Select
      placeholder='Select...'
      value={defaultValue}
      onChange={props.onChange}
    >
      {data.map((v) => (
        <option key={v.GoalTypeId} value={v.GoalTypeId}>
          {v.description}
        </option>
      ))}
    </Select>
  );
}
