import { useToast, Flex, Center, Box, Image, Text, Heading } from "@chakra-ui/react";
import { useMojoFetch } from "api/useMojoFetch";
import './ProvidersList.scss';
import AddEditProviderForm from "../AddEditProviderForm/AddEditProviderForm";
import { useNavigate } from "hooks/useNavigate";
import ProvidersDataTable from "../ProvidersDataTable/ProvidersDataTable";
import { useState } from "react";
import logo from "../../../logo-teal.png";

function ProvidersList() {
  const { error, data, refetch, isLoading } = useMojoFetch(`/api/v1/organizations`, 'get');
  const [provider, setProvider] = useState({id:'', name:''});
  const [key, setKey] = useState(1);
  const navigate = useNavigate();

const toast = useToast();
if (error.message !== '') {
  toast({
    title: error.message,
    status: 'error',
  });  
}


const editProvider = (providerId:string) => { 
  const provider = data.find(d => d.id === providerId);
  setProvider(provider);
 setKey(key+1);
}

const addEditUsers = (providerId:string) => {
  const provider = data.find(d => d.id === providerId);
  navigate(`/users/provider/${provider.name}/${providerId}`);
}

const myRefetch= async () => {
  setProvider({id:'', name:''});
  await refetch();
}

const reset= () => {
  setProvider({id:'', name:''});
}

if (isLoading) {
  return (
    <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
      <Flex justify='center' direction='column'>
        <Image src={logo} alt='Mojo Platform' />
        <Box className='loader'></Box>
        <Center>Loading...</Center>
      </Flex>
    </Center>
  );
}

if (error.message !== '') {         
  return <Center>{error.message}</Center>;
}

  return (
    <>
    <Flex className="provider-page--header">
          <Flex className="provider-header--left">
            <Heading className="provider-heading">ORGANIZATIONS LIST</Heading>
          </Flex>
    </Flex>
    <div className="datatable-header">    
        <AddEditProviderForm key={key}
        provider = {provider}
        refetch={myRefetch}
        reset = {reset}
      />            
      </div>
    <div className="providers-list">
      <ProvidersDataTable 
        data={data} 
        editProvider = { editProvider } 
        addEditUsers = { addEditUsers }
      />
    </div>
    </>
  );
}

export default ProvidersList;
