import { useEffect, useState } from "react";
import { Avatar, Box, Card, CardBody, Center, Stack } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams, useParams } from "react-router-dom";
import { useMojoFetch } from "api/useMojoFetch";
import { userInfo } from "os";

const auth0RoleKey = "https://mojo-dev.nearix.com//roles";

export const UserProfile = () => {
  const [mojoRole, setMojoRole] = useState();
  const [userProfile, setUserProfile] = useState({} as any);
  const [searchParams, setSearchParams] = useSearchParams();
  const { userId } = useParams();
  const isCurrentUser = searchParams.get("currentUser");
  const profile = useMojoFetch(`/api/v1/Users/email/${userId}`, "get");

  const { user, isAuthenticated, getIdTokenClaims, getAccessTokenSilently } =
    useAuth0();

  useEffect(() => {
    if (isAuthenticated && isCurrentUser) {
      const getToken = async () => {
        let token = await getAccessTokenSilently();
        let decoded = jwtDecode(token);
        if (decoded[auth0RoleKey]?.System) {
          const mojoRoleObj = decoded[auth0RoleKey]?.System.find((item) => {
            return item.name;
          });
        setMojoRole(mojoRoleObj?.name);
            
        }
        return;
      };
      getToken().catch(console.error);
    }


  }, []);

    useEffect(() => {
        const profileData = profile.data;
      setUserProfile({ ...profileData, ...user });
    }, [profile?.data])
    
  return (
    <Card w={"30%"} minW={470}>
      <CardBody p={10}>
        {" "}
        <Center>
          {" "}
          <Stack>
            <Center>
              <Avatar src={userProfile?.picture} size={"lg"}></Avatar>
            </Center>

            <Box>
              Name: {userProfile?.given_name || userProfile?.firstName}{" "}
              {userProfile?.family_name || userProfile?.lastName}
            </Box>
            <Box>Username: {userProfile?.name}</Box>
            {mojoRole === "Super Admin" && (
              <>
                <Box>User ID: {userProfile?.UserId}</Box>
                <Box>Auth0 ID: {userProfile?.auth0Id}</Box>
                <Box>Mojo Role: {mojoRole}</Box>
              </>
            )}
            <Box>Email: {userProfile?.email}</Box>
            <Box>Company: {userProfile?.company}</Box>
            <Box>Title: {userProfile?.title}</Box>

            <Box>Membership:</Box>
          </Stack>
        </Center>
      </CardBody>
    </Card>
  );
};
