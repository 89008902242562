import { Box, Center, Flex, Image } from '@chakra-ui/react';
import logo from '../../logo-teal.png';
import { useMojoFetch } from 'api/useMojoFetch';
import UserListDataTable2 from './UserListDataTable2';
import UserFormCommon from './UserFormCommon';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useMojoEffect } from 'api/useMojoEffect';

export default function UserList2(props) {
  const [myUser, setUser] = useState(undefined);
  const [key, setKey] = useState(1);
  const { name, id } = useParams();
  const location = useLocation();
  let myEntity='';
  if ((location.pathname === '/users')) {
    myEntity = 'user';
  } else {
    const subPaths = location.pathname.split('/');
    myEntity = subPaths[2];
  }
  const [entity, setEntity] = useState(myEntity);
  const {
    data: roles,
    error: rolesError,
    isLoading: rolesLoading,
  } = useMojoFetch(`/api/v1/roles/${entity}`, 'GET');
  const {
    data: entities,
    error: entitiesError,
    isLoading: entitiesLoading,
  } = useMojoFetch(`/api/v1/${props.url}`, 'GET');
  const {
    data: users,
    error: usersError,
    isLoading: usersLoading,
    refetch,
  } = useMojoFetch(`/api/v1/Users/`, 'GET');

  const { runWithId: runDelete } = useMojoEffect(`/api/v1/Users/`, 'DELETE');  

  const addEditUser = (userId: string) => {
    const user = users.find((u) => u.UserId === userId);
    setUser(user);
    setKey(key + 1);    
  };

  const deleteUser = async (userId: string) => {
    await runDelete(userId);
    await refetch();
  };

  const myRefetch = async () => {
    setUser(undefined);    
    await refetch();
  };


    if (usersLoading || rolesLoading || entitiesLoading) {
      return (
        <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
          <Flex justify='center' direction='column'>
            <Image src={logo} alt='Mojo Platform' />
            <Box className='loader'></Box>
            <Center>Loading...</Center>
          </Flex>
        </Center>
      );
    }
    var error: any;
    error = '';
    if (usersError) {
      error = usersError;
    } else if (rolesError) {
      error = rolesError;
    } else if (entitiesError) {
      error = entitiesError;
    }

    if (error.message !== '') {         
      return <Center>{error.message}</Center>;
    }

  return (
    <>     
      <UserFormCommon
        key={key}
        user={myUser}
        label={props.label}
        entity = {{ type: props.url, id: id, name: name }}        
        roles={roles}
        refetch={myRefetch}
      />
      <div className='client-list'>      
        <UserListDataTable2
          data={users}
          addEditUser={addEditUser}
          deleteUser={deleteUser}
        />
      </div>
    </>
  );
}
