import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  useToast,
} from '@chakra-ui/react';
import { useMojoEffect } from 'api/useMojoEffect';
import { FloatingLabelInput } from 'components/FloatingLabelInput';
import { Toggle } from 'components/Toggle';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export type AddEditContactFormProps = {
  vendorId: string | undefined | null;
  refetch: () => void;
};

export default function AddEditContactForm({
  vendorId,
  refetch,
}: AddEditContactFormProps) {
  const { run: runCreate } = useMojoEffect(
    '/api/v1/VendorContacts/create',
    'post'
  );
  const [isRunningCreate, setCreate] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setValue,
  } = useForm();

  const toast = useToast();

  const setIsFormDirty = (dirty: boolean) => {};

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        if (
          vendorId === undefined ||
          vendorId === 'new' ||
          vendorId === '' ||
          vendorId === null
        ) {
          toast({
            title: 'Add new partner first',
            status: 'error',
          });
          return;
        }
        setCreate(true);
        const [createdData, error] = await runCreate({
          ...data,
          VendorId: vendorId,         
          status: true,
        });
        if (error === null) {
          await refetch();
          reset();
          toast({
            title: 'Contact created',
            status: 'success',
          });
        } else {
          toast({
            title: 'Contact creation failed',
            status: 'error',
          });
        }
        setCreate(false);
        return createdData;
      })}
    >
      <div className='form-upper-wrapper'>
        <div className='form-upper form'>
          <div className='form-upper-title'>ADD NEW CONTACT</div>
        </div>

        <div className='form'>
          <div className='input-group'>
            <FormControl variant='floating' isRequired>
              <Input {...register('firstName')} />
              <FormLabel style={{ paddingRight: '8px' }}>First Name</FormLabel>
            </FormControl>
          </div>
          <div className='input-group'>
            <FormControl variant='floating' isRequired>
              <Input {...register('lastName')} />
              <FormLabel style={{ paddingRight: '8px' }}>Last Name</FormLabel>
            </FormControl>
          </div>
          <div className='input-group'>
            <FormControl variant='floating'>
              <Input type='email' {...register('email')} />
              <FormLabel style={{ paddingRight: '8px' }}>Email</FormLabel>
            </FormControl>
          </div>
          <div className='input-group'>
            <FormControl variant='floating'>
              <Input {...register('phone')} />
              <FormLabel style={{ paddingRight: '8px' }}>Phone</FormLabel>
            </FormControl>
          </div>
          <div className='input-group min-width-20'>
            <FormControl variant='floating'>
              <Input {...register('title')} />
              <FormLabel style={{ paddingRight: '8px' }}>Title</FormLabel>
            </FormControl>
          </div>
          {/*<Flex alignSelf='center'>
            <FormLabel htmlFor='status' mb='0'>
              Active?
            </FormLabel>
            <Switch {...register('status')} />
          </Flex>*/}
          <div className='bt-add'>
            <Button
              isLoading={isRunningCreate}
              variant='mojoPrimary'
              type='submit'
            >
              <AddIcon />
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
