import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button, Heading, Box, Flex } from '@chakra-ui/react';
import './BudgetsUpload.scss';
import { Uploader } from 'components/Uploader/Uploader';

const file_header = [
  'Location',
  'Objective',
  'Type',
  'Audience',
  'Dept',
  'Channel',
  'Medium',
  'Partner',
  'Notes',
  'COOP Eligible',
  'COOP Percentage',
  'Year',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
  'Average',
  'Total',
];

const columns: any[] = [
  {
    title: 'Location',
    dataIndex: 'Location',
    key: 'location',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Location === null || b.Location === null) return 0;
      return (a.Location ?? '').localeCompare(b.Location ?? '');
    },
  },
  {
    title: 'Dept',
    dataIndex: 'Dept',
    key: 'department',
    fixed: 'left',
    width: 100,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Department === null || b.Department === null) return 0;
      return (a.Department ?? '').localeCompare(b.Department ?? '');
    },
  },
  {
    title: 'Partner',
    dataIndex: 'Partner',
    key: 'partner',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Partner === null || b.Partner === null) return 0;
      return (a.Partner ?? '').localeCompare(b.Partner ?? '');
    },
  },
  {
    title: 'Channel',
    dataIndex: 'Channel',
    key: 'channel',
    fixed: 'left',
    width: 125,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Channel === null || b.Channel === null) return 0;
      return (a.Channel ?? '').localeCompare(b.Channel ?? '');
    },
  },
  {
    title: 'Type',
    dataIndex: 'Type',
    key: 'type',
    fixed: 'left',
    width: 100,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Type === null || b.Type === null) return 0;
      return (a.Type ?? '').localeCompare(b.Type ?? '');
    },
  },
  {
    title: 'Medium',
    dataIndex: 'Medium',
    key: 'medium',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Medium === null || b.Medium === null) return 0;
      return (a.Medium ?? '').localeCompare(b.Medium ?? '');
    },
  },
  {
    title: 'Objective',
    dataIndex: 'Objective',
    key: 'objective',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Objective === null || b.Objective === null) return 0;
      return (a.Objective ?? '').localeCompare(b.Objective ?? '');
    },
  },
  {
    title: 'Audience',
    dataIndex: 'Audience',
    key: 'audience',
    fixed: 'left',
    width: 125,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Audience === null || b.Audience === null) return 0;
      return (a.Audience ?? '').localeCompare(b.Audience ?? '');
    },
  },
  {
    title: 'Notes',
    dataIndex: 'Notes',
    key: 'notes',
    fixed: 'left',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.Notes === null || b.Notes === null) return 0;
      return (a.Notes ?? '').localeCompare(b.Notes ?? '');
    },
  },
  {
    title: 'COOP Eligible',
    dataIndex: 'COOP Eligible',
    key: 'coop_eligible',
    fixed: 'left',
    width: 125,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a['COOP Eligible'] === null || b['COOP Eligible'] === null) return 0;
      return (a['COOP Eligible'] ?? '').localeCompare(b['COOP Eligible'] ?? '');
    },
  },
  {
    title: 'COOP Percentage',
    dataIndex: 'COOP Percentage',
    key: 'coop_percentage',
    fixed: 'left',
    width: 100,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a['COOP Percentage'] === null || b['COOP Percentage'] === null)
        return 0;
      return (a['COOP Percentage'] ?? 0) - (b['COOP Percentage'] ?? 0);
    },
  },
  {
    title: 'Year',
    dataIndex: 'Year',
    key: 'year',
    width: 100,
    sorter: (a, b) => {
      if (a.Year === null || b.Year === null) return 0;
      return (a.Year ?? '').localeCompare(b.Year ?? '');
    },
  },
  {
    title: 'Jan',
    dataIndex: 'Jan',
    key: 'january',
    width: 100,
  },
  {
    title: 'Feb',
    dataIndex: 'Feb',
    key: 'february',
    width: 100,
  },
  {
    title: 'Mar',
    dataIndex: 'Mar',
    key: 'march',
    width: 100,
  },
  {
    title: 'Apr',
    dataIndex: 'Apr',
    key: 'april',
    width: 100,
  },
  {
    title: 'May',
    dataIndex: 'May',
    key: 'may',
    width: 100,
  },
  {
    title: 'June',
    dataIndex: 'June',
    key: 'june',
    width: 100,
  },
  {
    title: 'July',
    dataIndex: 'July',
    key: 'july',
    width: 100,
  },
  {
    title: 'Aug',
    dataIndex: 'Aug',
    key: 'august',
    width: 100,
  },
  {
    title: 'Sept',
    dataIndex: 'Sept',
    key: 'september',
    width: 100,
  },
  {
    title: 'Oct',
    dataIndex: 'Oct',
    key: 'october',
    width: 100,
  },
  {
    title: 'Nov',
    dataIndex: 'Nov',
    key: 'november',
    width: 100,
  },
  {
    title: 'Dec',
    dataIndex: 'Dec',
    key: 'december',
    width: 100,
  },
  {
    title: 'Average',
    dataIndex: 'Average',
    key: 'average',
    width: 100,
  },
  {
    title: 'Total',
    dataIndex: 'Total',
    key: 'total',
    width: 100,
  },
];

export function BudgetsUploadForm({
  doneUploading,
  location_id,
  location_name,
  vendors,
  departments,
  channels,
  channelTypes,
  media,
  audiences,
  objectives,
  setSelectedYear,
}) {
  return (
    <>
      <Box className='budget-upload--form'>
        <Flex className='budget-upload--header'>
          <Button
            className='budget-upload--back-btn'
            leftIcon={<ChevronLeftIcon height={6} width='auto' color='cyan' />}
            variant='mojoDefault'
            onClick={doneUploading}
          >
            BUDGETS
          </Button>
          <Heading className='budget-upload--heading'>
            UPLOAD BUDGET FILES
          </Heading>
        </Flex>
        <Uploader
          file_header={file_header}
          columns={columns}
          maxFiles={4}
          file_type={'budgets'}
          location_id={location_id}
          location_name={location_name}
          vendors={vendors}
          departments={departments}
          channels={channels}
          types={channelTypes}
          media={media}
          objectives={objectives}
          audiences={audiences}
          setSelectedYear={setSelectedYear}
        />
      </Box>
    </>
  );
}
