import React, { useState } from 'react';
import { DatePicker, Popover } from 'antd';
import dayjs from "dayjs"
import type { Dayjs } from "dayjs"
import type { DatePickerProps } from 'antd';
import { Button } from '@chakra-ui/react';

interface YearSelectorProps {
    title: string;
    setSelectedYear: (year: string | null) => void;
    className?: string;
}

function YearSelector({
    title,
    setSelectedYear,
    className
}: YearSelectorProps) {
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

    const currentYear = dayjs().year();
    const minYear = currentYear - 5;
    const maxYear = currentYear + 5;

    const onChange: DatePickerProps['onChange'] = (
        date: Dayjs | null,
        dateString: string | string[]
    ) => {
        if (date) {
            setSelectedYear(date.year().toString());
            setPopoverOpen(false);
        } else {
            setSelectedYear(null);
        }
    };

    const disabledDate = (current: dayjs.Dayjs | null) => {
        if (!current) return false;
        const year = current.year();
        return year < minYear || year > maxYear;
    };

    const picker = (
        <DatePicker
            onChange={onChange}
            picker='year'
            defaultValue={dayjs().year(currentYear)}
            disabledDate={disabledDate}
        />
    );

    return (
        <Popover
            content={picker}
            trigger='click'
            open={popoverOpen}
            onOpenChange={setPopoverOpen}
        >
            <Button
                className={className}
                backgroundColor={'mojo.200'}
                color={'white.100'}
                borderRadius='7px'
                fontSize={'sm14'}
                fontFamily={'gothamMedium'}
                padding='10px'
                ml='10px'
            >
                {title}
            </Button>
        </Popover>
    );
};

export default YearSelector