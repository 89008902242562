import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  button: {
    fontSize: 'xs',
    fontWeight: 'medium',
    bg: 'white',
    color: 'black',
    px: '2',
    py: '1',
    m: '0',
    borderRadius: 'md',
    border: '1px solid',
    borderColor: 'gray.300',
    _hover: {
      bg: 'gray.200',
      color: 'black',
    },
  },
  list: {
    p: '0',
    m: '0',
    fontSize: 'xs',
    minWidth: '0',
  },
  item: {
    px: '2',
    py: '1',
    _hover: {
      bg: 'gray.300',
    },
    _focus: {
      bg: 'gray.300',
    },
  },
});

export const Menu = defineMultiStyleConfig({
  baseStyle,
});