import { Alert, AlertDescription, AlertIcon, AlertTitle, Flex, useColorModeValue } from "@chakra-ui/react";



export function Maintainance() {
  const bg = useColorModeValue("white.100", "black.300");
  return (
    <Flex justify='center' alignItems={'center'} width='100%' height={'100%'} direction={'column'} bg={bg}>
        <Alert
          status='info'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          height='200px'
          width={'fit-content'}
        >
          <AlertIcon boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Section under maintainance
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            This section is currently under a maintainance. Please, revisit it in one of the next releases.
          </AlertDescription>
        </Alert>
    </Flex>
  )
}