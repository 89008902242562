import { useToast } from "@chakra-ui/react";
import { ContactsDataTable } from "../ContactsDataTable";
import AddEditContactForm from "../AddEditContactForm";
import { useMojoFetch } from "api/useMojoFetch";
import { useParams } from "react-router-dom";
import { useMojoEffect } from "api/useMojoEffect";

function VendorContacts() {
    const { partnerId } = useParams();
    const { error, data, refetch } = useMojoFetch(
      `/api/v1/VendorContacts/all/${partnerId}`,
      'get'
    );
    const { runWithId: runDelete } = useMojoEffect(
      `/api/v1/VendorContacts/`,
      'delete'
    );
    const { runWithId: runUpdate } = useMojoEffect(
        "/api/v1/VendorContacts/",
        "PUT"
    );
    
    const toast = useToast();

    const deleteContact = async (contact) => {
        console.log(contact);
         const [body, error] = await runDelete(contact.VendorContactId);
        if (error === null) {
            toast({
                title: "Contact deleted",
                status: "success",
            });
           await refetch();
        } else {
            toast({
                title: "Failed to delete contact",
                status: "error",
            });
        }
    };

    const updateContact = async (
        idx: number,
        contact: any
    ) => {
        if (!data) {
            return;
        }
        const fullContact = {
            ...data[idx],
            ...contact,
        };      
        const [body, error] = await runUpdate(
            fullContact.VendorContactId,
            fullContact);
        if (error === null) {
            toast({
                title: "Contact updated",
                status: "success",
            });
            await refetch();
        } else {
            toast({
                title: "Failed to update contact",
                status: "error",
            });
        }
    };
   



    return (
      <>
        <AddEditContactForm vendorId={partnerId} refetch={refetch} />
        <ContactsDataTable
          data={data}
          error={error}
          deleteContact={deleteContact}
          updateContact={updateContact}
        />
      </>
    );
}

export default VendorContacts;
