import { Link, Center, Image, HStack, Text, IconButton, Divider, Flex, Box, Stack, useColorModeValue } from "@chakra-ui/react";
import './Footer.css';

function Footer() {
    const bg = useColorModeValue('white.100', 'black.400')
    return (
        <Flex className="footer">
            <Center>                
                <Text>
                    Automotive Marketing Gurus &copy; {new Date().getFullYear()}
                </Text>                
            </Center>
        </Flex>
    );
}

export default Footer;
