import { AddIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  useToast,
  Input,
  FormLabel,
  Flex,
} from '@chakra-ui/react';
import { useMojoEffect } from 'api/useMojoEffect';
import { useState } from 'react';
import { useForm } from 'react-hook-form';




export default function AddEditProviderForm(props) {
  const { register, handleSubmit, reset } = useForm();
  const {run: create} = useMojoEffect('/api/v1/organizations/', 'post');
  const {run: update} = useMojoEffect(`/api/v1/organizations/${props.provider.id}`, 'put');
  const [isLoading, setLoading] = useState(false);

  const toast = useToast();

  const myReset = () => {
    reset({name: ''});
    props.reset();
  }

  async function submitForm(data) {
    setLoading(true);
    var createProviderDto = {
      ...data,
    };
    
    if (props.provider.id === '') {
      const [body, error] = await create(createProviderDto);
      if (error === null) {
        toast({
          title: `Organization created`,
          status: 'success',
        });
        await props.refetch();
      } else  {
        toast({
          title: error.message,
          status: 'error',
        });
      }
    }
    else {
      const [body, error] = await update(createProviderDto);
      if (error === null) {
        toast({
          title: `Organization updated`,
          status: 'success',
        });
        await props.refetch();
      } else  {
        toast({
          title: error.message,
          status: 'error',
        });
      }
    }
    setLoading(false);
    myReset();
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Box className='form-upper-wrapper' mt='50px'>
        <Box className='form-upper form'>
          <Box className='form-upper-title'>ADD / EDIT ORGANIZATION</Box>
        </Box>

        <Flex className='form'>
          <FormControl variant='floating' isRequired>
            <Input
              width='300pt'
              mb='30px'
              {...register('name')}
              defaultValue={props.provider.name}
            />
            <FormLabel style={{ paddingRight: '8px' }}>Name</FormLabel>
          </FormControl>
          <Button variant='mojoPrimary' type='submit' isLoading={isLoading}>
            {props.provider.id === '' ? <AddIcon /> : <EditIcon />}
          </Button>
          <Button variant='mojoPrimary' onClick={(e) => myReset()}>
            <CloseIcon />
          </Button>
        </Flex>
      </Box>
    </form>
  );
}
