import { components } from 'api/typesgen';
import { Column } from 'react-table';
import { DataTableSkeleton } from 'components/DataTable/Skeleton';
import './ProvidersDataTable.scss';
import { Box, Button, Flex, IconButton, useToast, Text } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { DataTable } from 'components/DataTable/DataTable';
import { ButtonDanger } from 'components/ButtonDanger/ButtonDanger';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { HiMiniUsers } from 'react-icons/hi2';
import SelectFilter from 'components/DataTable/Filters/SelectFilter';
import { useNavigate } from 'react-router';
import { useMojoEffect } from 'api/useMojoEffect';

export type ProviderListDataTableProps = {
  data?: { id: string; name: string }[];
  error?: any;
  deleteProvider: (id: string) => void;
  //id?: string;
};

const columns: (Column<components['schemas']['Provider']> & {
  label?: (contact: components['schemas']['Provider']) => string;
})[] = [
  {
    Header: 'ORGANIZATION NAME',
    accessor: 'name',
    Filter: (props) => (
      <SelectFilter
        column={{
          ...props.column,
          filterPlaceholder: 'Organization',
        }}
      />
    ),
    filter: 'rankedMatchSorter',
  },
];

export default function ProvidersDataTable(props) {
  const toast = useToast();
  const [tableData, changeTable] = useState(props.data);
  const {runWithId: deleteTheProvider} = useMojoEffect('/api/v1/organizations/', 'delete');

  useEffect(() => {
    changeTable(props.data);
  }, [props]);

  async function deleteProvider(providerId: string) {    
    const [body, error] = await deleteTheProvider(providerId);
    if (error === null) {
      toast({
        title: 'Provider deleted',
        status: 'success',
      })
    } else {
      toast({
        title: error.message,
        status: 'error',
      })
    }

    if (error === null) {
      var tableData2: any = [];
      tableData.forEach((f) => {
        if (f.id !== providerId) {
          tableData2.push(f);
        }
      });
      changeTable(tableData2);
    }
  }

  return (
    <div className='datatable-main datatable-controls'>
      <Box w='60%'>
        {props.data ? (
          <DataTable data={tableData} columns={columns} showFilter chooseRows>
            {{
              rowFooter: (row: any) => (
                <Flex gap={1}>
                  <Button variant='mojoPrimaryGhost' onClick={() => {}}>
                    <EditIcon onClick={() => props.editProvider(row.id)} />
                  </Button>
                  <ButtonDanger onClick={() => deleteProvider(row.id)}>
                    <DeleteIcon />
                  </ButtonDanger> 
                  {/*<IconButton
                    onClick={() => props.addEditUsers(row.id) }
                    mt='8px'
                    colorScheme='blue'
                    size='xs'
                    aria-label='add users'
                    icon={<HiMiniUsers />}
                  />*/}                
                </Flex>
              ),
            }}
          </DataTable>
        ) : (
          <DataTableSkeleton columns={columns}>
            {{ rowFooter: () => <></> }}
          </DataTableSkeleton>
        )}
      </Box>
    </div>
  );
}
