import { Select } from 'antd';
import { useMojoFetch } from 'api/useMojoFetch';

export default function DepartmentSelect(props) {
  const { data: departments, isLoading: dLoading } = useMojoFetch(
    '/api/v1/departments',
    'get'
  );
  let v = departments.find((v) => v.name === props.value);
  if (v === undefined) {
    v = departments.find((v) => v.DepartmentId === props.value);
  }
  const defaultValue = v !== undefined ? v.DepartmentId : null;
  return (
    <Select
      placeholder='Select...'
      value={defaultValue}
      onChange={props.onChange}
    >
      {departments.map((v) => (
        <option key={v.DepartmentId} value={v.DepartmentId}>
          {v.name}
        </option>
      ))}
    </Select>
  );
}
