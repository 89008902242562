// store/configureStore.js

import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import appReducer from "./reducers/appSlice";
import integrationsReducer from "./reducers/integrationsSlice";
import userReducer from './reducers/userSlice';

const persistConfig = {
  key: "root",
  storage,
  // ...other config options
};

const rootReducer = combineReducers({
  app: appReducer.reducer,
  integrations: integrationsReducer.reducer,
  user: userReducer.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store); // Add this line

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
