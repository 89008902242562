import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    bg: "white",
    bgColor: "white",
    borderColor: "#d5d5d5",
    borderWidth: "2px",
    borderRadius: "var(--chakra-radii-md)",
    fontWeight: "500",
    fontStyle: "normal",
    fontsize: "var(--chakra-fontSizes-xs)",
    paddingInlineEnd: "var(--chakra-space-8)",
    width: "100%",
    minWidth: "0px",
    outline: "transparent solid 2px",
    outlineOffset: "2px",
    position: "relative",
    appearance: "none",
    transitionProperty: "var(--chakra-transition-property-common)",
    transitionDuration: "var(--chakra-transition-duration-normal)",
    paddingBottom: "1px",
    height: " var(--chakra-sizes-9)",
    lineHeight: "var(--chakra-lineHeights-normal)",
    paddingInlineStart: "var(--chakra-space-3)",
    border: "1px solid",
  },
});

const sm = defineStyle({
  fontSize: "0.85rem",
});

const md = defineStyle({
  fontSize: "1.0rem",
  height: 10,
});

const sizes = {
  md: definePartsStyle({ field: md, addon: md }),
  sm: definePartsStyle({ field: sm, addon: sm }),
};

export const Select = defineMultiStyleConfig({ baseStyle, sizes });
