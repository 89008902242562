import { components } from 'api/typesgen';
import { Select } from 'components/Select';
import { InlineEditableDataTable } from 'components/DataTable/InlineEditableDataTable';
import { DataTableSkeleton } from 'components/DataTable/Skeleton';
import { useCallback } from 'react';
import { Column } from 'react-table';

type Entity = {
  id: string;
  name: string;
};

type ChannelsTableProps<T = components["schemas"]["CreatePartnerChannelDto"]> = {
  channels: Entity[];
  media: Entity[];
  channelTypes: Entity[];
  objectives: Entity[];
  audiences: Entity[];
  data: any[];
  deleteChannel: (channel: T) => Promise<void>;
  updateChannel: (idx: number, channel: Partial<T>) => Promise<void>;
};

const tableHeader = <></>;

export default function ChannelsTable({
  channels,
  media,
  channelTypes,
  objectives,
  audiences,
  data,
  updateChannel,
  deleteChannel,
}: ChannelsTableProps) {
  const EditableChannelCell = ({
    value,
    row: { index: rowIdx },
    column: { id: cellKey, accessor },
    updateRowData,
  }) => {      
    const options = channels
      ? channels.map(({ id, name }) => ({
          label: name,
          value: id,
          key: id,
        }))
      : [];

    const onChange = useCallback(
      (selectedValue: unknown) => {
        if (updateRowData) {
          updateRowData(rowIdx, cellKey, selectedValue);
        }
      },
      [rowIdx, cellKey, updateRowData]
    );
   
    const id = channels.find((c) => c.name === value)?.id;
    return (
      <Select value={id} onChange={onChange} options={options} key={id} />
    );
  };

  const EditableMediumCell = ({
    value,
    row: { index: rowIdx },
    column: { id: cellKey, accessor },
    updateRowData,
  }) => {
    const options = media
      ? media.map(({ id, name }) => ({
          label: name,
          value: id,
          key: id,
        }))
      : [];

    const onChange = useCallback(
      (selectedValue: unknown) => {
        if (updateRowData) {
          updateRowData(rowIdx, cellKey, selectedValue);
        }
      },
      [rowIdx, cellKey, updateRowData]
    );

    const id = media.find((m) => m.name === value)?.id;
    return <Select value={id} onChange={onChange} options={options} key={id} />;
  };

  const EditableTypeCell = ({
    value,
    row: { index: rowIdx },
    column: { id: cellKey, accessor },
    updateRowData,
  }) => {
    const options = channelTypes
      ? channelTypes.map(({ id, name }) => ({
          label: name,
          value: id,
          key: id,
        }))
      : [];

    const onChange = useCallback(
      (selectedValue: unknown) => {
        if (updateRowData) {
          updateRowData(rowIdx, cellKey, selectedValue);
        }
      },
      [rowIdx, cellKey, updateRowData]
    );

     const id = channelTypes.find((c) => c.name === value)?.id;
     return (
       <Select value={id} onChange={onChange} options={options} key={id} />
     );
  };

  const EditableObjectiveCell = ({
    value,
    row: { index: rowIdx },
    column: { id: cellKey, accessor },
    updateRowData,
  }) => {
    const options = objectives
      ? objectives.map(({ id, name }) => ({
          label: name,
          value: id,
          key: id,
        }))
      : [];

    const onChange = useCallback(
      (selectedValue: unknown) => {
        if (updateRowData) {
          updateRowData(rowIdx, cellKey, selectedValue);
        }
      },
      [rowIdx, cellKey, updateRowData]
    );

     const id = objectives.find((c) => c.name === value)?.id;
     return (
       <Select value={id} onChange={onChange} options={options} key={id} />
     );
  };

 const EditableAudienceCell = ({
   value,
   row: { index: rowIdx },
   column: { id: cellKey, accessor },
   updateRowData,
 }) => {
   const options = audiences
     ? audiences.map(({ id, name }) => ({
         label: name,
         value: id,
         key: id,
       }))
     : [];

   const onChange = useCallback(
     (selectedValue: unknown) => {
       if (updateRowData) {
         updateRowData(rowIdx, cellKey, selectedValue);
       }
     },
     [rowIdx, cellKey, updateRowData]
   );

     const id = audiences.find((a) => a.name === value)?.id;
     return (
       <Select value={id} onChange={onChange} options={options} key={id} />
     );
 };


  const columns: (Column<components['schemas']['CreatePartnerChannelDto']> & {
    disabled?: boolean;
    label?: (
      contact: components['schemas']['CreatePartnerChannelDto']
    ) => string;
  })[] = [
    {
      Header: 'CHANNEL',
      accessor: 'channelNameId',
    },

    {
      Header: 'MEDIUM',
      accessor: 'mediumNameId',
    },

    {
      Header: 'TYPE',
      accessor: 'channelTypeNameId',
    },
    {
      Header: 'OBJECTIVE',
      accessor: 'objectiveNameId',     
    },
    {
      Header: 'AUDIENCE',
      accessor: 'audienceNameId',      
    },
  ];

  const tableMarkup = data ? (
    <InlineEditableDataTable
      data={data}
      columns={columns}
      updateRow={updateChannel}
      deleteRow={deleteChannel}
      cells={{
        channelNameId: EditableChannelCell,
        mediumNameId: EditableMediumCell,
        channelTypeNameId: EditableTypeCell,
        objectiveNameId: EditableObjectiveCell,
        audienceNameId: EditableAudienceCell,
      }}
    >
      {{ tableHeader }}
    </InlineEditableDataTable>
  ) : (
    <DataTableSkeleton columns={columns}>
      {{ rowFooter: () => <></> }}
    </DataTableSkeleton>
  );

  return (
    <div className='datatable datatable-user-list'>
      <div className='datatable-controls screen'>{tableMarkup}</div>
    </div>
  );
}